import React, { useState } from "react";
import { Switch,Flex } from 'antd';

function SwitchComponent(props) {
    const {value,label,onChange} = props;
    const [switchValue,setSwitchValue] = useState(value);

    function handleChangeValue(checked) {
        setSwitchValue(checked);
        onChange(checked);
    }

    return (
        <Flex align="center" className="detail-field">
            <strong className="field-key"><span>{label}</span><span>:</span></strong>
            <Switch className="toggle-field" checkedChildren="True" unCheckedChildren="False" defaultChecked={switchValue} onChange={handleChangeValue}/>
        </Flex>
    )
}

export default SwitchComponent;