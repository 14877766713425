import React,{useState,useEffect,useRef} from "react";
import { Table, Flex, Button, Modal, Input, Space } from 'antd';
import {FilterOutlined,ClearOutlined,SearchOutlined} from "@ant-design/icons";
import { useTranslation } from 'react-i18next'; // Import useTranslation

function FilterComponent(props) {
    const { t, i18n } = useTranslation('filters', { useSuspense: false }); // Initialize useTranslation with 'filters' namespace
    const {data,applyFilter,filteredItems,getFilterItemsList,resetFilter=false} = props;
    const [columnsData, setColumnsData] = useState([]);
    const [selectedColumns, setSelectedColumns] = useState([]);
    const [selectedFilterValues, setSelectedFilterValues] = useState([]);
    const [filterValuesList, setFilterValuesList] = useState([]);
    const [isFilterModalOpen, setIsFilterModalOpen] = useState(false);
    const [currentSelectedRow, setCurrentSelectedRow] = useState(null);
    const searchInput = useRef(null);
    const [filteredInfo, setFilteredInfo] = useState({});

    useEffect(() => {
        setColumnsData(data.map(col => ({ key: col.key, label: col.label })));
        setCurrentSelectedRow(data[0].key);
        if(resetFilter) handleClearFilter(data[0].key)
        else setFilterValuesList(getFilterItemsList(data[0].key))
    },[data])

    function renderMainTable(text, record) {
        const selectedFiltersCount = filteredItems?.current[record.key]?.length;
        return selectedFiltersCount > 0 ?
                    <Flex justify="space-between" align="center"><div>{text}</div><div>({selectedFiltersCount})</div></Flex> : <div>{text}</div>
    }

    const mainTableColumns = [
        {
            title: t('filterBy'),
            dataIndex: 'label',
            key: 'key',
            render: (text, record) => renderMainTable(text,record)
        }
    ];

    const handleSearch = (selectedKeys,close) => {
        setFilteredInfo({key: selectedKeys});
        close();
    };

    const handleReset = (close) => {
        setFilteredInfo({});
        close();
    };

    const getColumnSearchProps = (dataIndex) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, close }) => {
            return (
            <div
                style={{
                padding: 8,
                }}
                onKeyDown={(e) => e.stopPropagation()}
            >
                <Input
                ref={searchInput}
                placeholder={`Search ${dataIndex}`}
                value={selectedKeys[0]}
                onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                onPressEnter={() => handleSearch(selectedKeys,close)}
                style={{
                    marginBottom: 8,
                    display: 'block',
                }}
                />
                <Space>
                <Button
                    type="primary"
                    onClick={() => handleSearch(selectedKeys,close)}
                    icon={<SearchOutlined />}
                    size="small"
                    style={{
                    width: 90,
                    }}
                >
                    {t('search')}
                </Button>
                <Button
                    onClick={() => handleReset(close)}
                    size="small"
                    style={{
                    width: 90,
                    }}
                >
                    {t('reset')}
                </Button>
                <Button
                    type="link"
                    size="small"
                    onClick={() => {
                    close();
                    }}
                >
                    {t('close')}
                </Button>
                </Space>
            </div>
            )
        },
        filterIcon: (filtered) => (
          <SearchOutlined
            style={{
              color: filtered ? '#1677ff' : undefined,
            }}
          />
        ),
        onFilter: (value, record) => 
          record['label'].toString().toLowerCase().includes(value.toLowerCase()),
        filterDropdownProps: {
          onOpenChange(open) {
            if (open) {
              setTimeout(() => searchInput.current?.select(), 100);
            }
          },
        },
        filteredValue: filteredInfo?.key || null
    });

    const secondaryTableColumns = [
        {
            title: t('selectAny'),
            dataIndex: 'label',
            key: 'key',
            ...getColumnSearchProps(currentSelectedRow),
        }
    ]

    function handleRowSelection(record) {
        const isSelected = selectedFilterValues.includes(record.key);
        const newSecondarySelectedRows = isSelected ?
            selectedFilterValues.filter((key) => key!== record.key) :
            [...selectedFilterValues, record.key];
        setSelectedFilterValues(newSecondarySelectedRows);
        if(!filteredItems.current[record.relatedKey]) {
            filteredItems.current[record.relatedKey] = [record.key]
        }
        else {
            if (isSelected) {
                filteredItems.current[record.relatedKey] = filteredItems.current[record.relatedKey].filter(
                    (key) => key !== record.key
                );
            } 
            else {
                filteredItems.current[record.relatedKey].push(record.key);
            }
        }
        
        const newMainSelectedRows =  filteredItems.current[record.relatedKey]?.length > 0 ?
            selectedColumns.includes(record.relatedKey) ? selectedColumns :
            [...selectedColumns, record.relatedKey] :
            selectedColumns.filter((key) => key!== record.relatedKey)
        
        setSelectedColumns(newMainSelectedRows);
    }

    function handleApplyFilter() {
        applyFilter();
        setFilteredInfo({});
        setIsFilterModalOpen(false);
    }

    function closeFilter() {
        setFilteredInfo({});
        setIsFilterModalOpen(false)
    }

    function handleClearFilter(selectedItem) {
        filteredItems.current = {};
        setSelectedFilterValues([]);
        setSelectedColumns([]);
        const filteredList = getFilterItemsList(selectedItem);
        setFilterValuesList(filteredList);
    }

    return (
        <>
            <Button className={isFilterModalOpen && 'btn-active'} icon={<FilterOutlined />} onClick={() => setIsFilterModalOpen(true)}>{t('filter')}</Button>
            <Modal 
                className="filter-modal" 
                width={807} 
                closable={false} 
                title={t('filter')} 
                open={isFilterModalOpen} 
                onOk={handleApplyFilter} 
                onCancel={() => setIsFilterModalOpen(false)}
                footer={[
                    <Flex key="footer" justify="space-between" align="center">
                        <Button onClick={() => handleClearFilter(currentSelectedRow)} icon={<ClearOutlined />} danger className="filter-btn clear-btn">{t('clearAll')}</Button>
                        <Flex gap={10} align="center">
                            <Button className="filter-btn" onClick={closeFilter}>
                                {t('cancel')}
                            </Button>
                            <Button className="filter-btn" type="primary" onClick={handleApplyFilter}>
                                {t('ok')}
                            </Button>
                        </Flex>
                    </Flex>
                  ]}
            >
                <Flex>
                    <Table
                        size="small"
                        className="filter-table"
                        columns={mainTableColumns}
                        dataSource={columnsData}
                        pagination={false}
                        rowSelection={{
                            type: 'checkbox',
                            selectedRowKeys: selectedColumns,
                            getCheckboxProps: (record) => ({
                                disabled: true
                            }),
                            columnWidth: 40,
                            hideSelectAll: true
                        }}
                        rowClassName={(record) =>
                            record.key === currentSelectedRow ? "row-selected" : ""
                        }
                        onRow={(record) => ({
                            onClick: () => {
                                const filterItems = getFilterItemsList(record.key);
                                setFilterValuesList(filterItems)
                                setCurrentSelectedRow(record.key)
                                setFilteredInfo({})
                            }
                        })}
                        rowKey="key"
                        scroll={{ y:'430px' }}
                    />
                    <Table
                        size="small"
                        className="filter-table"
                        columns={secondaryTableColumns}
                        dataSource={filterValuesList.sort((a, b) => String(a.label).localeCompare(String(b.label)))}
                        pagination={false}
                        rowKey="key"
                        scroll={{ y:'430px' }}
                        rowSelection={{
                            type: 'checkbox',
                            selectedRowKeys: selectedFilterValues,
                            columnWidth: 40,
                            hideSelectAll: true,
                            onSelect: (record, selected, selectedRows) => handleRowSelection(record)
                        }}
                        onRow={(record) => ({ onClick: () => handleRowSelection(record)})}
                    />
                </Flex> 
            </Modal>
        </>
    )
}

export default FilterComponent;