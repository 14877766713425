import React, { useEffect, useState } from 'react';
import './login.scss';
import { Flex, Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import apiClient from '../../ApiClient';

export default function VerifyEmailComponent() {
    const [isVerify, setLoader] = useState(true);
    const [isError, setError] = useState(false);
    const [message, setMessage] = useState(`Hang tight! We're verifying your email right now. Please wait just a moment!`);
    const currentUrl = window.location.href;

    // Create a URL object to parse the query parameters
    const urlParams = new URLSearchParams(new URL(currentUrl).search);

    // Extract the token and email
    const token = urlParams.get('token');
    const email = urlParams.get('email');
    useEffect(() => {
            const verifyEmail = async () => {
                try {
                    const response = await apiClient.post('auth/verify-email', {
                        token,
                        email,
                    });
    
                    if (response.data.status === 200) {
                        setError(false);
                        setMessage("Your email has been verified successfully.");
                    } else {
                        setError(true);
                        setMessage("Oops! Email verification failed. Please reach out to your administrator for assistance.");
                    }
    
                } catch (error) {
                    setError(true);
                    setMessage("Oops! Email verification failed. Please reach out to your administrator for assistance.");
                } finally {
                    setLoader(false);
                }
            };
    
            verifyEmail();
    }, [token, email]);

    return (
        <Flex justify="space-around" className='login-container'>
            <Flex className="form-container" vertical>
                <p className='login-msg' style={{ 'textAlign': 'center' }}>{message}</p>
                {isVerify ? (
                    <Spin
                        indicator={
                            <LoadingOutlined
                                style={{
                                    fontSize: 48,
                                }}
                                spin
                            />
                        }
                    />
                )
                    : <> {isError ? <></> :
                        <p className='login-msg' style={{ 'textAlign': 'center' }}>Please click here to <Link to={'/login'}>Login</Link></p>
                    }
                    </>}
            </Flex>
        </Flex>
    );
}