import apiClient from "../../ApiClient";

export const FETCH_SITEDATA_REQUEST = 'FETCH_SITEDATA_REQUEST';
export const FETCH_SITEDATA_SUCCESS = 'FETCH_SITEDATA_SUCCESS';
export const FETCH_SITEDATA_FAILURE = 'FETCH_SITEDATA_FAILURE';
export const SET_CURRENT_SITE = 'SET_CURRENT_SITE';

export const CREATE_SITE_REQUEST = 'CREATE_SITE_REQUEST';
export const CREATE_SITE_SUCCESS = 'CREATE_SITE_SUCCESS';
export const CREATE_SITE_FAILURE = 'CREATE_SITE_FAILURE';

export const EDIT_SITEDATA_REQUEST = 'EDIT_SITEDATA_REQUEST';
export const EDIT_SITEDATA_SUCCESS = 'EDIT_SITEDATA_SUCCESS';
export const EDIT_SITEDATA_FAILURE = 'EDIT_SITEDATA_FAILURE';
export const TOGGLE_CREATE_SITE_FLOW = 'TOGGLE_CREATE_SITE_FLOW';
export const SITE_UPDATED = 'SITE_UPDATED';

export const createSite = (siteData) => async (dispatch) => {
    dispatch({ type: CREATE_SITE_REQUEST });
    try {
      const response = await apiClient.post('/Site', siteData);
      dispatch({ type: CREATE_SITE_SUCCESS, payload: {site: response.data.data } });
      return {status:true, data:response.data};
    } catch (error) {
      dispatch({ type: CREATE_SITE_FAILURE, payload: error.message });
      return {status:false, error:error.response};
    }
};

export const getSites = () => async (dispatch) => {
    dispatch({ type: FETCH_SITEDATA_REQUEST });
    try {
      const response = await apiClient.get('/Site/list');
      dispatch({ type: FETCH_SITEDATA_SUCCESS, payload: response.data.data });
      return {status:true, data: response.data.data};
    } catch (error) {
      dispatch({ type: FETCH_SITEDATA_FAILURE, payload: error.message });
      return {status:false, error:error.response};
    }
};

export const getSiteData = (siteCode) => async () => {
    try {
      const response = await apiClient.get(`/Site/getSiteByCode/${siteCode}`);
      return {status:true, data: response.data.data};
    } catch (error) {
      return {status:false, error: error.response};
    }
};

export const selectSite = async (siteId) => {
  try {
    const response = await apiClient.post(`/user/select/${siteId}`, null);
    return {status:true, data: response.data.token, user: response.data.user};
  } catch (error) {
    return {status:false, error: error.response};
  }
};

export const updateSite = (siteData,id) => async (dispatch) => {
    dispatch({ type: EDIT_SITEDATA_REQUEST });
    try {
      const response = await apiClient.put(`/Site/${id}`, siteData);
      const companyLogo = siteData.companyLogo.replace('data:image/png;base64,','');
      const updatedSiteData = {...siteData, companyLogo:companyLogo};
      dispatch({ type: EDIT_SITEDATA_SUCCESS, payload: updatedSiteData });
      return {status:true, data: companyLogo, message:response.data.message};
    } catch (error) {
      dispatch({ type: EDIT_SITEDATA_FAILURE, payload: error.message });
      return {status:false, error:error.response};
    }
};