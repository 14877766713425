import React, { useState, useEffect } from "react";
import { Layout, Flex, Avatar } from 'antd';
import './header.scss';
import {
    SearchOutlined,
    QuestionCircleOutlined,
    GlobalOutlined
} from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import defaultPic from '../../../assets/profile.png';
import { useSelector, useDispatch } from "react-redux";
import { getProfileImage } from "../../../redux/actions/UserAction";
import NotificationBell from './NotificationBell';
import DateComponent from "../../common/DateComponent";
const { Header } = Layout;

function MainHeader(props) {
    const { t, i18n } = useTranslation('main-header');
    const [language, setLanguage] = useState(i18n.language);
    const user = useSelector((state) => state.user.user);
    const currentSite = useSelector((state) => state.site.currentSite); // Get currentSite from Redux state
    const dispatch = useDispatch();
    const [profileImage, setProfileImage] = useState(null);
    const [loading, setLoading] = useState(true);
    const [drawerVisible, setDrawerVisible] = useState(false);
   

    function toggleLanguage() {
        const newLanguage = language === 'en' ? 'es' : 'en';
        i18n.changeLanguage(newLanguage);
        setLanguage(newLanguage);
    }

    function toggleNotifications() {
        setDrawerVisible(!drawerVisible);
    }

    useEffect(() => {
        setLanguage(i18n.language);
    }, [i18n.language]);

    useEffect(() => {
        if (user && user.language) {
            const langCode = user.language === 'english' ? 'en' : user.language === 'french' ? 'fr' : 'es';
            i18n.changeLanguage(langCode);
            setLanguage(langCode);
        }
    }, [user, i18n]);

    useEffect(() => {
        const loadProfileImage = async () => {
            setLoading(true);
            const result = await dispatch(getProfileImage());
            if (result.status) {
                setProfileImage(result.data.image);
            }
            setLoading(false);
        };
        loadProfileImage();
    }, [user, dispatch]);

    return (
        <Header className='top-header'>
            {
                currentSite ? (
                    <Flex className="side-header" justify="center" align="center">
                        <img className="company-logo" src={`data:image/png;base64,${currentSite.companyLogo}`} alt="company-logo"/>
                    </Flex>
                ) : (<></>)
            }
            <Flex className="header-items" gap={'small'} align="center" justify="end">
                <SearchOutlined className="header-icon"/>
                <QuestionCircleOutlined className="header-icon icon-top"/>
                <NotificationBell  />
                <div className="profile-info">
                    <Avatar src={loading || !profileImage ? defaultPic : `data:image/png;base64,${profileImage}`} style={{ backgroundColor: '#87d068' }} />
                    <span className="profile-name">{user ? `${user.firstName} ${user.lastName}` : "UNKNOWN"}</span>
                </div>
                <div className="language-toggle" onClick={toggleLanguage}>
                    <GlobalOutlined className="header-icon" />
                    <span className="language-label">{language.toUpperCase()}</span>
                </div>
            </Flex>
        </Header>
    )
}

export default MainHeader;