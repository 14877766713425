import React, { useState } from "react";
import { Select,Flex } from 'antd';

function SelectComponent(props) {
    const {value,label,onChange,options} = props;
    const [selectedOption,setSelectedOption] = useState(value);

    function handleChangeValue(newValue) {
        setSelectedOption(newValue);
        onChange(newValue);
    }

    return (
        <Flex align="center" className="detail-field">
            <strong className="field-key"><span>{label}</span><span>:</span></strong>
            <Select
                className="field-value select-container"
                defaultValue={selectedOption}
                onChange={handleChangeValue}
                options={options}
                suffixIcon={null}
            />
        </Flex>
    )

}

export default SelectComponent;