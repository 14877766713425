import {
    BarChart,
    Bar,
    CartesianGrid,
    XAxis,
    YAxis,
    Tooltip,
    ResponsiveContainer
} from "recharts";

function StackedChartComponent(props) {
    const { data, properties, formatDate } = props;
    let  seriesColumns = [];
    //const [valueFields, setValueFields] = useState(Object.keys(data[0]).filter(field => field !== properties.Axes.x.column && field !== properties.Axes.y.column));

    function transformData(data, dimensionKey, categoryKey, valueKey) {
        let seriesValues = [];
        
        const transformedData = data.reduce((acc, item) => {
          const dimension = item[dimensionKey];
          const target_id = item[categoryKey];
          const sum = item[valueKey];
      
          // Find or create an entry for each unique dimension
          let entry = acc.find(i => i.dimension === dimension);
          if (!entry) {
            entry = { dimension };
            acc.push(entry);
          }
      
          // Collect unique target IDs (categories)
          if (!seriesValues.includes(target_id)) {
            seriesValues.push(target_id);
          }
      
          // Set the category as key with value as the data
          entry[target_id] = sum;
          return acc;
        }, []);
      
        return { transformedData, seriesValues };
      }

      const { transformedData, seriesValues } = transformData(data, properties.Axes.x.column, properties.Axes.series.column, properties.Axes.y.column);
      seriesColumns = seriesValues;
    
      // Utility function to get colors
const getColor = (index) => {
    const colors = ["#8884d8", "#82ca9d", "#ffc658", "#ff7300"];
    return colors[index % colors.length];
  };
    
    return (
        data && (
            <ResponsiveContainer width="100%" height="100%">
                <BarChart
                    width={500}
                    height={300}
                    data={transformedData}
                    margin={{ top: 10, right: 30, left: 20, bottom: 10 }}
                >
                    <CartesianGrid strokeDasharray="3 3" strokeOpacity={0.1} />
                    <XAxis dataKey="dimension" tickFormatter={properties.Axes.x.isDateTime && formatDate} />
                    <YAxis />
                    <Tooltip /> 
                    {seriesColumns.map((id, index) => (
                        <Bar key={id} dataKey={id} stackId="a" fill={getColor(index)} />
                    ))}
                    {/* <Bar dataKey={properties.Axes.y.column} fill={properties.Color} isAnimationActive={false} /> */}
                </BarChart>
            </ResponsiveContainer>
        )
    )

}

export default StackedChartComponent;