import {produce} from 'immer';
import { SET_PERMISSIONS, SET_USER, USER_LOGOUT, USER_NOTIFICATION_COMPLETE, USER_NOTIFICATION_RECEIVED } from '../actions/UserAction';

const initialState = {
    user: null,
    permissions: [],
    newNotifications: [], // Changed from newNotificationId to newNotifications
}

const UserReducer = (state = initialState, action) => {
    return produce(state, draft => {
        switch (action.type) {
            case SET_USER:
                draft.user = action.payload;
                break;
            case SET_PERMISSIONS:
                draft.permissions = action.payload;
                break;
            case USER_NOTIFICATION_RECEIVED:
                draft.newNotifications.push(action.payload); // Push new notification to the array
                break;
            case USER_NOTIFICATION_COMPLETE:
                draft.newNotifications = draft.newNotifications.filter(notification => !action.payload.includes(notification.id)); // Remove only processed notifications
                break;
            case USER_LOGOUT:
                draft.user = null;
                draft.permissions = [];
                draft.newNotifications = [];
                break;
            default:
                break;
        }
    });
}

export default UserReducer;