import React, { useState } from 'react';
import './signup.scss';
import { Form, Input, Button, Flex, Tooltip, message } from 'antd';
import { UserOutlined, MailOutlined, LockOutlined } from '@ant-design/icons';
import PasswordValidator from './PasswordValidator';
import { PhoneInput } from 'react-international-phone';
import 'react-international-phone/style.css';
import PasswordStrengthBar from 'react-password-strength-bar';
import apiClient from '../../ApiClient';
import { useNavigate } from 'react-router-dom';

export default function SignUpComponent({onCompleteSignup}) {
    const [form] = Form.useForm();
    const [phone, setPhone] = useState('');
    const [password, setPassword] = useState('');
    const [passwordScore, setPasswordScore] = useState(0);
    const scoreFeedback = ['Weak', 'Weak', 'Fair', 'Good', 'Strong'];
    const [criteria, setCriteria] = useState({
        length: false,
        lowercase: false,
        uppercase: false,
        number: false,
        specialChar: false,
    });
    const navigate = useNavigate();
    const [loader,setLoader] = useState(false);


    function handlePasswordChange(e) {
        const password = e.target.value;
        setPassword(password);

        const hasLowercase = /[a-z]/.test(password);
        const hasUppercase = /[A-Z]/.test(password);
        const hasNumber = /\d/.test(password);
        const hasSpecialChar = /[!@#$%^&*()_+[\]{};':"\\|,.<>?]/.test(password);
        const isLongEnough = password.length >= 8;

        setCriteria({
            length: isLongEnough,
            lowercase: hasLowercase,
            uppercase: hasUppercase,
            number: hasNumber,
            specialChar: hasSpecialChar,
        });
    }

    async function onFinish(values) {
        const { confirm, ...formData } = values;
        try {
            setLoader(true);
            const response = await apiClient.post('/auth/register', formData);
            if (response.data && response.data.status === 200) {
                message.success('Sign up successful!');
                onCompleteSignup();

            } else {
                message.error(response.data && response.data.message ? response.data.message : 'Sign up failed. Please try again.');
            }
        } catch (error) {
            message.error('An error occurred during signup.');
            console.error('Error:', error);
        } finally {
            setLoader(false);
        }
    };

    return (
        <div className='signup-container'>
            <Flex className="form-container" vertical>
                <Form size='large' form={form} name="signup" onFinish={onFinish} scrollToFirstError className='signup-form'>
                    <Form.Item
                        className='form-field'
                        name="firstName"
                        rules={[{ required: true, message: 'Please enter your Firstname!' }]}
                    >
                        <Input prefix={<UserOutlined />} placeholder="Firstname" />
                    </Form.Item>
                    <Form.Item
                        className='form-field'
                        name="lastName"
                        rules={[{ required: true, message: 'Please enter your Lastname!' }]}
                    >
                        <Input prefix={<UserOutlined />} placeholder="Lastname" />
                    </Form.Item>
                    <Form.Item
                        className='form-field'
                        name="email"
                        rules={[
                            {
                                type: 'email',
                                message: 'Please enter a valid Email!',
                            },
                            {
                                required: true,
                                message: 'Please enter your Email!',
                            },
                        ]}
                    >
                        <Input prefix={<MailOutlined />} placeholder="Email" />
                    </Form.Item>
                    <Form.Item
                        className='form-field'
                        name="phoneNumber"
                        rules={[
                            { required: true, message: 'Please enter your phone number!' },
                        ]}
                    >
                        <PhoneInput
                            defaultCountry="us"
                            value={phone}
                            onChange={(phone) => setPhone(phone)}
                            className='phone-input'
                        />
                    </Form.Item>
                    <Form.Item
                        name="password"
                        className='password-field'
                        rules={[
                            { required: true, message: 'Please enter the Password!' },
                            {
                                validator: (_, value) => {
                                    const isValid = /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[!@#$%^&*()_+[\]{};':"\\|,.<>?]).{8,}$/.test(value);
                                    if (isValid) {
                                        return Promise.resolve();
                                    }
                                    return Promise.reject();
                                },
                            },
                        ]}
                        hasFeedback
                        validateTrigger="onChange"
                    >
                        <Input.Password
                            prefix={<LockOutlined />}
                            placeholder="Password"
                            onChange={handlePasswordChange}
                            addonAfter={<PasswordValidator criteria={criteria} />}
                        />
                    </Form.Item>
                    <Tooltip title={scoreFeedback[passwordScore]}>
                        <div className="strength-bar">
                            <PasswordStrengthBar
                                password={password}
                                scoreWords={[]}
                                shortScoreWord=""
                                onChangeScore={(score) => setPasswordScore(score)}
                            />
                        </div>
                    </Tooltip>
                    <Form.Item
                        className='form-field'
                        name="confirm"
                        dependencies={['password']}
                        hasFeedback
                        rules={[
                            {
                                required: true,
                                message: 'Please confirm your password!',
                            },
                            ({ getFieldValue }) => ({
                                validator(_, value) {
                                    if (!value || getFieldValue('password') === value) {
                                        return Promise.resolve();
                                    }
                                    return Promise.reject(new Error('Password does not match!'));
                                },
                            }),
                        ]}
                    >
                        <Input.Password
                            prefix={<LockOutlined />}
                            placeholder="Confirm Password"
                        />
                    </Form.Item>
                    <Button className='submit-btn' loading={loader} type="primary" htmlType="submit" size='large'>Sign Up</Button>
                </Form>
            </Flex>
        </div>
    );
}