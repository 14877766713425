import React, { useState, useEffect } from 'react';
import { PlusOutlined } from '@ant-design/icons';
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import { Form, Input, Card, Button, Image, Upload, message } from 'antd';
import ImgCrop from 'antd-img-crop';
import { createSite, getSiteData, SET_CURRENT_SITE, SITE_UPDATED, TOGGLE_CREATE_SITE_FLOW, updateSite } from '../../redux/actions/SiteActions';
import './sitedetails.scss';
import { SET_PERMISSIONS } from '../../redux/actions/UserAction';
import { updateAuthToken } from '../../utils/authUtils';
const { TextArea } = Input;

function Settings() {
    const {siteCode} = useParams();
    const [form] = Form.useForm();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();
    const [showPreview, setShowPreview] = useState(false);
    const [previewImage, setPreviewImage] = useState('');
    const createSiteFlow = useSelector((state) => state.site.createSiteFlow);
    const [fileList, setFileList] = useState([]);
    const [isSiteEdit,setIsSiteEdit] = useState(false);
    const [siteDetail,setSiteDetail] = useState(null);

    useEffect(() => {
        siteCode ? navigate(location.pathname) : navigate('/')
    },[location.pathname,siteCode,navigate])

    useEffect(() => {
        async function fetchSiteDetails() {
            const response = await dispatch(getSiteData(siteCode));
            form.setFieldsValue(response.data);
            setSiteDetail(response.data);
            setIsSiteEdit(true);

            if (response.data && response.data.companyLogo) {
                setFileList([
                    {
                        uid: response.data.id,
                        name: 'company-logo.png',
                        status: 'done',
                        url: `data:image/png;base64,${response.data.companyLogo}`
                    }
                ]);
            }
        }
        if(siteCode) fetchSiteDetails()

    },[dispatch,siteCode,form])

    async function onFinish(values) {
        const imageFile = values.companyLogo;
        
        if(typeof imageFile === 'string') {
            values.companyLogo = `data:image/png;base64,${imageFile}`;
        }
        else {
            const base64Image = await getBase64(imageFile.file);
            values.companyLogo = base64Image;
        }

        if(isSiteEdit) {
            values.id = siteDetail.id
            const response = await dispatch(updateSite(values,siteDetail.id));
            if(response.status) {
                dispatch({ type: SITE_UPDATED, payload: {id: siteDetail.id, companyLogo: response.data} });
                message.success("Site updated Successfully");
            }
        }
        else {
            const response = await dispatch(createSite(values));
            if(response.status) {
                const site = response.data.data;
                const updateTokenResponse = await updateAuthToken(site.id);
                dispatch({ type: SET_PERMISSIONS, payload: updateTokenResponse.user.profilePermission });
                dispatch({ type: SET_CURRENT_SITE, payload: site });
                navigate(`/${site.siteCode}/scheduler/runs`)
            }
        }
    };

    const getBase64 = (file) =>
        new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result);
            reader.onerror = (error) => reject(error);
        });

    const handlePreview = async (file) => {
        if (!file.url && !file.preview) {
          file.preview = await getBase64(file.originFileObj);
        }
        setPreviewImage(file.url || file.preview);
        setShowPreview(true);
    };

    const handleChange = ({ fileList: newFileList }) => setFileList(newFileList);

    const handleCrop = (file) => {
        const reader = new FileReader();
        reader.onload = () => {
            const base64String = reader.result.replace(/^data:image\/[a-z]+;base64,/, ''); // Remove the prefix
            setFileList([{
                uid: '-1',
                name: file.name,
                status: 'done',
                url: `data:image/jpeg;base64,${base64String}`, // Add the prefix back for display
            }]);
            form.setFieldsValue({ companyLogo: base64String }); // Set the cropped image in form values without the prefix
        };
        reader.readAsDataURL(file);
        return false; // Prevent upload
    };

    return (
        <Card className="site-detail-card">
            <h1 className="header">Site Information</h1>
            <Form form={form} name="siteDetails" onFinish={onFinish} scrollToFirstError className='site-form'>
                <div className='row-container'>
                    <Form.Item
                        className="form-item site-info"
                        name="siteName"
                        rules={[{ required: true, message: 'Please enter Site name!' }]}
                    >
                        <Input placeholder="Name of the Site" />
                    </Form.Item>
                    <Form.Item
                        className="form-item"
                        name="companyName"
                        rules={[{ required: true, message: 'Please enter Company name!' }]}
                    >
                        <Input placeholder="Full Name of the Company" />
                    </Form.Item>
                </div>
                <Form.Item
                    name="shortCode"
                    rules={[{ required: true, message: 'Please enter a Short Code!' }]}
                >
                    <Input maxLength={3} placeholder="Short Code - Max 3 letters" />
                </Form.Item>
                <Form.Item
                    name="address"
                >
                    <TextArea rows={2} placeholder="Full Site Address" />
                </Form.Item>
                <div className='row-container'>
                    <Form.Item
                        className="form-item site-info"
                        name="city"
                    >
                        <Input placeholder="City Name" />
                    </Form.Item>
                    <Form.Item
                        className="form-item"
                        name="country"
                    >
                        <Input placeholder="Country Name" />
                    </Form.Item>
                </div>
                <Form.Item className='site-logo' name="companyLogo" rules={[{ required: true, message: 'Please upload a company logo!' }]}>
                    <ImgCrop rotate aspect={5 / 2}>
                        <Upload
                            beforeUpload={handleCrop}
                            listType="picture-card"
                            fileList={fileList}
                            onPreview={handlePreview}
                            onChange={handleChange}
                            maxCount={1}
                            showUploadList={{
                                showRemoveIcon: true,
                            }}
                            className="image-upload"
                        >
                            {fileList.length >= 1 ? null : (
                                <button
                                    style={{
                                    border: 0,
                                    background: 'none',
                                    }}
                                    type="button"
                                >
                                    <PlusOutlined />
                                    <div style={{ marginTop: 8 }}>Upload</div>
                                </button>
                            )}
                        </Upload>
                    </ImgCrop>
                </Form.Item>
                {previewImage && (
                        <Image
                        wrapperStyle={{
                            display: 'none',
                        }}
                        preview={{
                            visible: showPreview,
                            onVisibleChange: (visible) => setShowPreview(visible),
                            afterOpenChange: (visible) => !visible && setPreviewImage(''),
                        }}
                        src={previewImage}
                        />)}
                        
                <Form.Item className='submit-btn-container'>
                    <Button className="submit-btn" type="primary" htmlType="submit" block>{isSiteEdit ? `Save` : `Create Site`}</Button>
                </Form.Item>
            </Form>
        </Card>
    )
}

export default Settings;