import apiClient from "../../SchedulerApiClient";

export const FETCH_RUN_REQUEST = 'FETCH_RUN_REQUEST';
export const FETCH_RUN_SUCCESS = 'FETCH_RUN_SUCCESS';
export const FETCH_RUN_FAILURE = 'FETCH_RUN_FAILURE';
export const RUN_NOTIFICATION_RECEIVED = "RUN_NOTIFICATION_RECEIVED";
export const FETCH_RUN_BY_ID_REQUEST = 'FETCH_RUN_BY_ID_REQUEST';
export const FETCH_RUN_BY_ID_SUCCESS = 'FETCH_RUN_BY_ID_SUCCESS';
export const FETCH_RUN_BY_ID_FAILURE = 'FETCH_RUN_BY_ID_FAILURE';

export const RUN_NOTIFICATION_COMPLETE = "RUN_NOTIFICATION_COMPLETE";
export const COMPARE_RUN_NOTIFICATION_RECEIVED = "COMPARE_RUN_NOTIFICATION_RECEIVED";
export const RUN_COMPARISON_COMPLETED = "RUN_COMPARISON_COMPLETED";

export const getSchedulerRuns = () => async (dispatch) => {
    dispatch({ type: FETCH_RUN_REQUEST });
    try {
      const response = await apiClient.get('/scheduler/runs');
      dispatch({ type: FETCH_RUN_SUCCESS, payload: response.data });
      return {status:true, data: response.data};
    } catch (error) {
      dispatch({ type: FETCH_RUN_FAILURE, payload: error.message });
      return {status:false, error:error.response};
    }
};

export const getRunById = (runId) => async (dispatch) => {
  dispatch({ type: FETCH_RUN_BY_ID_REQUEST });
  try {
    const response = await apiClient.get(`/scheduler/get_run/${runId}`);
    dispatch({ type: FETCH_RUN_BY_ID_SUCCESS, payload: response.data });
    return {status:true, data: response.data};
  } catch (error) {
    dispatch({ type: FETCH_RUN_BY_ID_FAILURE, payload: error.message });
    return {status:false, error:error.response};
  }
};

export const createNewRun = (runName, file) => async (dispatch) => {
  try {
    const formData = new FormData();
    formData.append('run_name', runName);
    if (file) {
      formData.append('excel_file', file);
    }
    const response = await apiClient.post(`/scheduler/create_run`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
    return {status:true, data: response.data};
  } catch (error) {
    return {status:false, error:error.response};
  }
}

export const getRunData = (runId) => async () => {
    try {
      const response = await apiClient.get(`/scheduler/get-run-detail/${runId}`);
      return {status:true, data: response.data};
    } catch (error) {
      return {status:false, error: error.response};
    }
};

export const saveRunData = (runData) => async () => {
  try {
    const response = await apiClient.put('/scheduler/update_run/', runData);
    return {status:true, data: response.data};
  } catch (error) {
    return {status:false, error: error.response};
  }
};

export const executeRun = (runParams) => async () => {
  try {
    const response = await apiClient.post('/scheduler/submit/', {parameters: runParams});
    return {status:true, data: response.data};
  } catch (error) {
    return {status:false};
  }
};

export const cloneRun = (runId, runName) => async () => {
  try {
    const requestObj = { run_id: runId, run_name: runName };
    const response = await apiClient.post(`/scheduler/clone/`, requestObj);
    return {status:true, data: response.data};
  } catch (error) {
    return {status:false};
  }
};

export const deleteRun = (runIdList) => async () => {
  try {
    const response = await apiClient.delete(`/scheduler/delete_run`, {data: {"run_id_list": runIdList}});
    return {status:true, data: response.data};
  } catch (error) {
    return {status:false};
  }
};

export const getSettingsForRun  = async (run_id) => {
  try {
    const response = await apiClient.get(`/scheduler/run_summary_settings/${run_id}`);
    return {status:true, data: response.data};
  } catch (error) {
    return {status:false};
  }
}

export const saveSettingsForRun  = async (run_id, settings) => {
  try {
    const response = await apiClient.post(`/scheduler/run_summary_settings/`, {run_id: run_id, settings: settings});
    return {status:true, data: response.data};
  } catch (error) {
    return {status:false};
  }
}

export const analyticalData = async (runId,reportType) => {
  try {
    const payload = {"report_type": reportType, "run_id": parseInt(runId)};
    const response = await apiClient.post(`/scheduler/get_report`, payload);
    return {status:true, data: response.data};
  } catch (error) {
    return {status:false};
  }
};

export const compareRun = async (baseRunId,compareRunId) => {
  try {
    const payload = {parameters: {'run_id1': baseRunId, 'run_id2': compareRunId}};
    const response = await apiClient.post(`/scheduler/submit/`, payload);
    return {status:true, data: response.data};
  } catch (error) {
    return {status:false};
  }
};

export const exportRunData = (runId) => async () => {
  try {
    const response = await apiClient.get(`/scheduler/export/${runId}`, {
      responseType: 'blob', // This is crucial to handle binary data
  });
    return {status:true, data: response.data};
  } catch (error) {
    return {status:false, error: error.response};
  }
};

export const exportCollectionData = (runId, collectionName) => async () => {
  try {
    const response = await apiClient.get(`/scheduler/export/${runId}`, {
      params: { collection_name: collectionName },
      responseType: 'blob', // This is crucial to handle binary data
    });
    return {status:true, data: response.data};
  } catch (error) {
    return {status:false, error: error.response};
  }
};

export const getComparisionDetail = async (baseRunId,compareRunId) => {
  try {
    const payload = {'run_id1': baseRunId, 'run_id2': compareRunId};
    const response = await apiClient.post(`/scheduler/run_comparison`, payload);
    return {status:true, data: response.data};
  } catch (error) {
    return {status:false};
  }
};

export const importRunData = (run_id, file) => async (dispatch) => {
    try {
        const formData = new FormData();
        formData.append('excel_file', file);

        const response = await apiClient.post(`/scheduler/import/${run_id}`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        });

        if (response.status === 200) {
            return { status: true, data: response.data };
        } else {
            return { status: false };
        }
    } catch (error) {
        return { status: false };
    }
};

export const performETL = (run_id, file, fileKey, parameter) => async (dispatch) => {
  try {
      const formData = new FormData();
      formData.append(fileKey, file);
      formData.append('planning_period', parameter)

      const response = await apiClient.post(`/scheduler/import_table/${run_id}`, formData, {
          headers: {
              'Content-Type': 'multipart/form-data',
          },
      });

      if (response.status === 200) {
          return { status: true, data: response.data };
      } else {
          return { status: false };
      }
  } catch (error) {
      return { status: false };
  }
};