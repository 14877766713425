import React from "react";
import tableMappings from "../../scheduler/scheduler_table_settings.json";
import {Flex,Row,Col,Divider} from 'antd';
import EditableField from "../../common/EditableField";
import SliderComponent from "../../common/SliderComponent";
import DateComponent from "../../common/DateComponent";
import SelectComponent from "../../common/SelectComponent";
import SwitchComponent from "../../common/SwitchComponent";

function RunDetailGridView(props) {
    const {currentRunDetail,runDetailKey = null,allowEditing = false} = props;
    let runDetailMappings;

    if(runDetailKey) {
        runDetailMappings = tableMappings.run_details.find(field => field.name === runDetailKey)?.properties;
    }
    else {
        runDetailMappings = tableMappings.run_details.filter(item => !item.properties)
    }

    const handleParameterChange = (runParamObj, updatedParameterValue, runParam=null) => {
        runParam ? currentRunDetail[runParamObj][runParam] = updatedParameterValue : currentRunDetail[runParamObj] = updatedParameterValue;
    };

    function getFieldType(field,value) {
        if(field.isDateField) {
            return 'date'
        }
        else {
            return typeof value === 'boolean' ? 'boolean' : typeof value === 'number' ? 'number' : 'string';
        }
    }

    function renderParameterValue(fieldType,value,field) {
        if(value === null) {
            return field.dataType === 'boolean' ? "false" : field.dataType === 'integer' ? "0" : '';
        }
        return fieldType === 'date' ? (<DateComponent dateValue={value}/>) : fieldType === 'number' ? (<span>{Math.floor(value * 10) / 10}</span>) : (<span>{String(value)}</span>);
    }

    return (
        <Row gutter={[8, 8]} className="detail-view">
            {runDetailMappings.filter(item => item.visibility === 'Show').map((field, index) => {
                const value = currentRunDetail[field.name] === undefined ? null : currentRunDetail[field.name];
                
                if(typeof value !== 'object' || value === null) {
                    let currentValue = value;
                    if(allowEditing && value === null) {
                        currentRunDetail[field.name] = field.dataType === 'boolean' ? false : field.dataType === 'integer' ? 0 : null;
                        currentValue = currentRunDetail[field.name];
                    }
                    const fieldType = getFieldType(field,currentValue);
                    return (
                        <Col span={6} key={index} className="detail-cell">
                            <Flex className="detail-field" align="center">
                                {allowEditing ? (
                                    field.controlType === 'range' ? (
                                        <SliderComponent
                                            value={currentValue}
                                            label={field.label}
                                            onChange={(newValue) => handleParameterChange(field.name, newValue)}
                                        />
                                    ) : field.controlType === 'switch' ? (
                                        <SwitchComponent
                                            value={currentValue}
                                            label={field.label}
                                            onChange={(newValue) => handleParameterChange(field.name, newValue)}
                                        />
                                    ) : field.controlType === 'select' ? (
                                        <SelectComponent
                                            value={currentValue}
                                            label={field.label}
                                            onChange={(newValue) => handleParameterChange(field.name, newValue)}
                                            options={field.options}
                                        />
                                    ) : (
                                        <EditableField
                                            value={currentValue === null ? null : String(currentValue)}
                                            label={field.label}
                                            type={fieldType}
                                            onChange={(newValue) => handleParameterChange(field.name, newValue)}
                                        />
                                    )
                                ) : (
                                    <>
                                        <strong className="field-key"><span>{field.label}</span><span>:</span></strong>
                                        <div className="field-value"><div className="input-value">{renderParameterValue(fieldType,currentValue,field)}</div></div>
                                    </>
                                )}
                            </Flex>
                        </Col>
                    );
                }

                else {
                    return (
                        <Col span={24} key={index}>
                            <Divider />
                            <h3>{field.label}</h3>
                            <Row gutter={[8, 8]}>
                                {field.properties.filter(item => item.visibility === 'Show').map((runParam, runParamIndex) => {
                                    const nestedValue = value[runParam.name] === undefined ? null : value[runParam.name];
                                    let currentNestedValue = nestedValue;
                                    if(allowEditing && nestedValue === null) {
                                        currentRunDetail[field.name][runParam.name] = runParam.dataType === 'boolean' ? false : runParam.dataType === 'integer' ? 0 : null;
                                        currentNestedValue = currentRunDetail[field.name][runParam.name];
                                    }
                                    const currentFieldType = getFieldType(runParam,currentNestedValue);
                                    return (
                                        <Col span={8} key={runParamIndex} className="detail-cell">
                                            <Flex className="detail-field" align="center">
                                                {allowEditing ? (
                                                    runParam.controlType === 'range' ? (
                                                        <SliderComponent
                                                            value={currentNestedValue}
                                                            label={runParam.label}
                                                            onChange={(newValue) => handleParameterChange(field.name, newValue, runParam.name)}
                                                        />
                                                    ) : runParam.controlType === 'switch' ? (
                                                        <SwitchComponent
                                                            value={currentNestedValue}
                                                            label={runParam.label}
                                                            onChange={(newValue) => handleParameterChange(field.name, newValue, runParam.name)}
                                                        />
                                                    ) : runParam.controlType === 'select' ? (
                                                        <SelectComponent
                                                            value={currentNestedValue}
                                                            label={runParam.label}
                                                            onChange={(newValue) => handleParameterChange(field.name, newValue, runParam.name)}
                                                            options={runParam.options}
                                                            type={runParam.dataType || "string"}
                                                        />
                                                    ) : (
                                                        <EditableField
                                                            value={currentNestedValue === null ? null : String(currentNestedValue)}
                                                            label={runParam.label}
                                                            type={currentFieldType}
                                                            onChange={(newValue) => handleParameterChange(field.name, newValue, runParam.name)}
                                                        />
                                                    )
                                                ) : (
                                                    <>
                                                        <strong className="field-key"><span>{runParam.label}</span><span>:</span></strong>
                                                        <div className="field-value"><div className="input-value">{renderParameterValue(currentFieldType,currentNestedValue,runParam)}</div></div>
                                                    </>
                                                )}
                                            </Flex>
                                        </Col>
                                    )
                                })}
                            </Row>
                        </Col>
                    );
                }

            })}
        </Row>
    )
}

export default RunDetailGridView;