function formatDateToCustomFormat(dateString) {
    dateString += 'Z';
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
  
    return `${year}-${month}-${day} ${hours}:${minutes}`;
}

function getPreviousTaskDetails(details) {
    const previousTaskDetails = details.split(',').map(part => part.trim());
    const [previousDemand,previousJob,previousTask] = previousTaskDetails.slice(0, 3);
    const resource = details.match(/\(\['([^']+)'\]/);
    const previousResource = resource ? resource[1] : null;
    return {previousDemand, previousJob, previousTask, previousResource};
}

function getTaskBarContent(highlightBy,productName,resourceName,taskName,demand,route,demandQuantity) {
    return highlightBy === 'product' ? productName : highlightBy === 'resource' ? resourceName : highlightBy === 'task' ? taskName : highlightBy === 'demandId' ? demand :  highlightBy ==='route' ? route : highlightBy === 'demandQuantity' ? demandQuantity : productName;
}

export const transformDataToGanttFormat = (inputTableData,data,viewBy,highlightBy,showLinks,handleSelectViewBy) => {
    const taskData = [];
    const links = [];
    const uniqueTasks = {};

    data.forEach((task, index) => {
        const { task_id, task_start_time, task_end_time, resource_id, job_id, demand_id, product_id, route_id, customer_id, site_id, demand_quantity } = task;
        const resourceName = inputTableData.resource.find(resource => resource.resource_id === resource_id)?.resource_name || resource_id
        const taskName = inputTableData.task.find(task => task.task_id === task_id)?.task_name || task_id
        const productName = inputTableData.product.find(product => product.product_id === product_id)?.product_shortname || product_id
        const currentViewByParams = handleSelectViewBy(resource_id,task_id,job_id,product_id,demand_id,route_id)
        const currentTaskKey = currentViewByParams.viewId

        if(viewBy === "resourceTask") {
            if (!uniqueTasks[currentTaskKey]) {
                uniqueTasks[currentTaskKey] = {
                    id: currentViewByParams.parentId,
                    text: getTaskBarContent(highlightBy,productName,resourceName,taskName,demand_id,route_id,demand_quantity),
                    open: true,
                    parent: 0,
                    render: "split",
                    task_name: taskName,
                    resource_name: resourceName,
                    job_id: job_id,
                    resource_id: resource_id,
                    task_id: task_id,
                    product_id: product_id,
                    product_name: productName,
                    demand_id: demand_id,
                    route_id: route_id,
                    customer_id: customer_id,
                    site_id: site_id,
                    hide: true
                };
                taskData.push(uniqueTasks[currentTaskKey]);
            }

            if (!uniqueTasks[`task-${resource_id}-${task_id}`]) {
                uniqueTasks[`task-${resource_id}-${task_id}`] = {
                    id: `task-${resource_id}-${task_id}`,
                    text: getTaskBarContent(highlightBy,productName,resourceName,taskName,demand_id,route_id,demand_quantity),
                    open: true,
                    parent: currentViewByParams.parentId,
                    render: "split",
                    task_name: taskName,
                    resource_name: taskName,
                    job_id: job_id,
                    resource_id: resource_id,
                    task_id: task_id,
                    product_id: product_id,
                    product_name: productName,
                    demand_id: demand_id,
                    route_id: route_id,
                    customer_id: customer_id,
                    site_id: site_id,
                    hide: true
                };
                taskData.push(uniqueTasks[`task-${resource_id}-${task_id}`]);
            }

            taskData.push({
                id: `task-${demand_id}-${job_id}-${resource_id}-${task_id}`,
                text: getTaskBarContent(highlightBy,productName,resourceName,taskName,demand_id,route_id,demand_quantity),
                start_date: formatDateToCustomFormat(task_start_time),
                end_date: formatDateToCustomFormat(task_end_time),
                parent: `task-${resource_id}-${task_id}`,
                task_name: taskName,
                resource_name: resourceName,
                product_name: productName,
                job_id: job_id,
                resource_id: resource_id,
                task_id: task_id,
                product_id: product_id,
                demand_id: demand_id,
                route_id: route_id,
                customer_id: customer_id,
                site_id: site_id,
                demand_quantity: demand_quantity,
                is_sub_task: true
            });
        }
        else if (viewBy === "routeResource") {
            if (!uniqueTasks[currentTaskKey]) {
                uniqueTasks[currentTaskKey] = {
                    id: currentViewByParams.parentId,
                    text: getTaskBarContent(highlightBy,productName,resourceName,taskName,demand_id,route_id,demand_quantity),
                    open: true,
                    parent: 0,
                    render: "split",
                    task_name: taskName,
                    resource_name: route_id,
                    job_id: job_id,
                    resource_id: resource_id,
                    task_id: task_id,
                    product_id: product_id,
                    product_name: productName,
                    demand_id: demand_id,
                    route_id: route_id,
                    customer_id: customer_id,
                    site_id: site_id,
                    hide: true
                };
                taskData.push(uniqueTasks[currentTaskKey]);
            }


            if (!uniqueTasks[`resource-${route_id}-${resource_id}`]) {
                uniqueTasks[`resource-${route_id}-${resource_id}`] = {
                    id: `resource-${route_id}-${resource_id}`,
                    text: getTaskBarContent(highlightBy,productName,resourceName,taskName,demand_id,route_id,demand_quantity),
                    open: true,
                    parent: currentViewByParams.parentId,
                    render: "split",
                    task_name: taskName,
                    resource_name: resourceName,
                    job_id: job_id,
                    resource_id: resource_id,
                    task_id: task_id,
                    product_id: product_id,
                    product_name: productName,
                    demand_id: demand_id,
                    route_id: route_id,
                    customer_id: customer_id,
                    site_id: site_id,
                    hide: true
                };
                taskData.push(uniqueTasks[`resource-${route_id}-${resource_id}`]);
            }

            taskData.push({
                id: `task-${demand_id}-${job_id}-${resource_id}-${task_id}`,
                text: getTaskBarContent(highlightBy,productName,resourceName,taskName,demand_id,route_id,demand_quantity),
                start_date: formatDateToCustomFormat(task_start_time),
                end_date: formatDateToCustomFormat(task_end_time),
                parent: `resource-${route_id}-${resource_id}`,
                task_name: taskName,
                resource_name: resourceName,
                product_name: productName,
                job_id: job_id,
                resource_id: resource_id,
                task_id: task_id,
                product_id: product_id,
                demand_id: demand_id,
                route_id: route_id,
                customer_id: customer_id,
                site_id: site_id,
                demand_quantity: demand_quantity,
                is_sub_task: true
            });
        }
        else {
            if (!uniqueTasks[currentTaskKey]) {
                uniqueTasks[currentTaskKey] = {
                    id: currentViewByParams.parentId,
                    text: getTaskBarContent(highlightBy,productName,resourceName,taskName,demand_id,route_id,demand_quantity),
                    open: true,
                    parent: 0,
                    render: "split",
                    task_name: taskName,
                    resource_name: resourceName,
                    job_id: job_id,
                    resource_id: resource_id,
                    task_id: task_id,
                    product_id: product_id,
                    product_name: productName,
                    demand_id: demand_id,
                    route_id: route_id,
                    customer_id: customer_id,
                    site_id: site_id
                };
                taskData.push(uniqueTasks[currentTaskKey]);
            }

            taskData.push({
                id: `task-${demand_id}-${job_id}-${resource_id}-${task_id}`,
                text: getTaskBarContent(highlightBy,productName,resourceName,taskName,demand_id,route_id,demand_quantity),
                start_date: formatDateToCustomFormat(task_start_time),
                end_date: formatDateToCustomFormat(task_end_time),
                parent: currentViewByParams.parentId,
                task_name: taskName,
                resource_name: resourceName,
                product_name: productName,
                job_id: job_id,
                resource_id: resource_id,
                task_id: task_id,
                product_id: product_id,
                demand_id: demand_id,
                route_id: route_id,
                customer_id: customer_id,
                site_id: site_id,
                demand_quantity: demand_quantity,
            });
        }

        if(task.previous_task_assignment_tuples && showLinks) {
            const {previousDemand,previousJob,previousTask,previousResource} = getPreviousTaskDetails(task.previous_task_assignment_tuples)
            links.push({id: index, source: `task-${previousDemand}-${previousJob}-${previousResource}-${previousTask}`, target: `task-${demand_id}-${job_id}-${resource_id}-${task_id}`, type: "0"})
        }
    });

    return { tasks: taskData, links: links };
};