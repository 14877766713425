import React, { useState } from "react";
import {Tabs} from 'antd';
import LoginComponent from "./login";
import SignUpComponent from "../sign-up/signup";

function AuthComponent() {

    const [activeTab, setActiveTab] = useState('login');

    function onCompleteSignup() {
        setActiveTab('login');
    }

    const tabItems = [
        {
            label: 'Login',
            key: 'login',
            children: <LoginComponent/>
        },
        {
            label: 'Sign Up',
            key: 'signup',
            children: <SignUpComponent onCompleteSignup={onCompleteSignup} />
        }
    ]

    return (
        <Tabs
            activeKey={activeTab}
            defaultActiveKey="login"
            size="small"
            items={tabItems}
            onChange={(key) => setActiveTab(key)}
        />
    )
}

export default AuthComponent;