import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getSites, selectSite, SET_CURRENT_SITE } from '../../redux/actions/SiteActions';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { LoadingOutlined } from '@ant-design/icons';
import { Flex, Spin } from 'antd';
import authToken from '../../AuthToken';
import { updateAuthToken } from '../../utils/authUtils';
import { SET_PERMISSIONS } from '../../redux/actions/UserAction';

const FetchUserSites = ({ children }) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { siteCode } = useParams();
    const location = useLocation();
    const sites = useSelector((state) => state.site.sites);
    const isSiteDataFetched = useSelector((state) => state.site.isFetched);
    const currentSite = useSelector((state) => state.site.currentSite);
    const [loading, setLoading] = useState(true);
    const createSiteFlow = useSelector((state) => state.site.createSiteFlow);

    useEffect(() => {
        async function fetchSites() {
            await dispatch(getSites());
            setLoading(false);
        }
        fetchSites();
    }, [dispatch]);

    const updateSiteAndUser = async (site) => {
        const response = await updateAuthToken(site.id);
        dispatch({ type: SET_PERMISSIONS, payload: response.user.profilePermission });
        dispatch({ type: SET_CURRENT_SITE, payload: site });
    };

    const handleSiteSelection = async () => {
        const siteCode = localStorage.getItem('site-code');
        if (siteCode) {
            const currentSite = sites.find(site => site.siteCode === siteCode);
            if (currentSite) {
                await updateSiteAndUser(currentSite);
                return;
            }
        } else if (sites.length === 1 && !createSiteFlow) {
            const site = sites[0];
            await updateSiteAndUser(site);

            if(location.pathname !== "/") {
                navigate(location.pathname);
                return;
            }

            navigate(`${site.siteCode}/scheduler/runs`);
            return;
        }

        if (!createSiteFlow || sites.length > 1) {
            navigate('/choose-site');
        }
    };

    useEffect(() => {
        const handleNavigation = () => {
            if (sites.length === 0 || createSiteFlow) {
                navigate('/settings');
            } else if (!currentSite) {
                handleSiteSelection();
            } else {
                if ((siteCode || currentSite) && location.pathname !== "/") {
                    navigate(location.pathname);
                } else if (currentSite) {
                    navigate(`${currentSite.siteCode}/scheduler/runs`);
                }
            }
        };

        if (isSiteDataFetched) {
            handleNavigation();
        }
    }, [isSiteDataFetched, sites, navigate, currentSite, createSiteFlow, location.pathname, siteCode]);

    if (loading) {
        return (
            <Flex align="center" gap="middle" justify='center' className='choose-site-loader-section'>
                <Spin indicator={<LoadingOutlined style={{ fontSize: 48 }} spin />} />
            </Flex>
        );
    }

    return <>{children}</>;
};

export default FetchUserSites;