import React from 'react';
import { Routes, Route } from 'react-router-dom';
import AcceptInviteComponent from './components/auth/accept-invite';
import ChooseSiteComponent from './components/auth/choose-site';
import VerifyEmailComponent from './components/auth/verify-email';
import FetchUserSites from './components/fetch-user-sites/FetchUserSites';
import HomeComponent from './components/home/home';
import CompareRun from './components/scheduler/CompareRun';
import FindRuns from './components/scheduler/FindRuns';
import RunDetails from './components/scheduler/RunDetails';
import Settings from './components/sitedetails/SiteDetails';
import ManageUsers from './components/user/manage-user';
import {AuthGuard, PermissionGuard} from './AuthGuard';
import ProfileComponent from './components/profile/profile';
import ManageTeams from './components/teams/manage-teams';
import ChangePasswordComponent from './components/change-password/change-password';
import PasswordResetComponent from './components/auth/password-reset';
import AuthComponent from './components/auth/Auth';
import UserOnboard from './components/auth/UserOnboard';

const RoutesConfig = () => {
    return (
        <Routes>
            <Route element={<UserOnboard />}>
                <Route path='login' element={<AuthComponent />} />
                <Route path='accept-invite' element={<AcceptInviteComponent />} />
                <Route path='reset-password' element={<PasswordResetComponent />} />
                <Route path='verify-email' element={<VerifyEmailComponent />} />
            </Route>
            <Route path='/' element={<AuthGuard><FetchUserSites><HomeComponent /></FetchUserSites></AuthGuard>}>
                <Route path=':siteCode/settings/sitedetails' element={<PermissionGuard requiredPermission="Site"><Settings /></PermissionGuard>} />
                <Route path=':siteCode/settings/users' element={<PermissionGuard requiredPermission="User"><ManageUsers /></PermissionGuard>} />
                <Route path=':siteCode/settings/teams' element={<PermissionGuard requiredPermission="Team"><ManageTeams /></PermissionGuard>} />
                <Route path='settings' element={<Settings />} />
                <Route path=':siteCode/scheduler/runs' element={<PermissionGuard requiredPermission="Scheduling"><FindRuns /></PermissionGuard>} />
                <Route path=':siteCode/scheduler/comparerun' element={<PermissionGuard requiredPermission="Scheduling"><CompareRun /></PermissionGuard>} />
                <Route path=':siteCode/scheduler/rundetails/:runId' element={<PermissionGuard requiredPermission="Scheduling"><RunDetails /></PermissionGuard>} />
                <Route path='/change-password' element={<ChangePasswordComponent />} caseSensitive={true} />
                <Route path='/profile' element={<ProfileComponent />} caseSensitive={true} />
            </Route>
            <Route path='/choose-site' element={<AuthGuard><ChooseSiteComponent /></AuthGuard>} />
        </Routes>
    );
};

export default RoutesConfig;