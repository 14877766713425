import React, { useState, useEffect } from 'react';
import './login.scss';
import { UserOutlined, LoadingOutlined, LockOutlined } from '@ant-design/icons';
import { Button, Form, Input, Flex, Spin, Tooltip, message } from 'antd';
import { Link, useNavigate } from 'react-router-dom';
import apiClient from '../../ApiClient';
import PasswordValidator from '../sign-up/PasswordValidator';
import PasswordStrengthBar from 'react-password-strength-bar';

export default function PasswordResetComponent() {
    const navigate = useNavigate();
    const [loader, setLoader] = useState(false);
    const [isVerifying, setIsVerifying] = useState(true);
    const [isError, setIsError] = useState(false);
    const [showPasswordForm, setShowPasswordForm] = useState(false);
    const [verificationMessage, setVerificationMessage] = useState("Verifying your request. Please wait...");
    const [showEmailForm, setShowEmailForm] = useState(false);

    const urlParams = new URLSearchParams(window.location.search);
    const token = urlParams.get('token');
    const email = urlParams.get('email');

    useEffect(() => {
        const verifyToken = async () => {
            try {
                const response = await apiClient.post('/auth/verify-reset-token', { email, token });
                if (response.data && response.status === 200) {
                    setIsError(false);
                    setVerificationMessage("Please set your new password.");
                    setShowPasswordForm(true);
                } else {
                    handleError("Password validation failed. Please try again.");
                }
            } catch (error) {
                handleError("Password validation failed. Please try again.");
            } finally {
                setIsVerifying(false);
            }
        };

        if (token && email) {
            verifyToken();
        } else {
            setShowEmailForm(true);
            setVerificationMessage("Please enter your email to receive a password reset link.");
            setIsVerifying(false);
        }
    }, [email, token]);

    const handleError = (message) => {
        setIsError(true);
        setVerificationMessage(message);
    };

    const handleSubmit = async (url, values, successMessage) => {
        try {
            setLoader(true);
            const response = await apiClient.post(url, values);
            if (response.data && response.data.status === 200) {
                message.success(successMessage);
                navigate("/login");
            } else {
                handleError("Reset password failed. Please try again.");
            }
        } catch (error) {
            handleError("Reset password failed. Please try again.");
            console.error('Error:', error);
        } finally {
            setLoader(false);
        }
    };

    const onFinish = (values) => handleSubmit('/auth/reset-password', { email, token, ...values }, 'Password reset successfully.');
    const onEmailSubmit = (values) => handleSubmit('/auth/request-reset-password', values.email, 'Password reset link sent successfully.');

    const [password, setPassword] = useState('');
    const [form] = Form.useForm();
    const [passwordScore, setPasswordScore] = useState(0);
    const scoreFeedback = ['Weak', 'Weak', 'Fair', 'Good', 'Strong'];
    const [criteria, setCriteria] = useState({
        length: false,
        lowercase: false,
        uppercase: false,
        number: false,
        specialChar: false,
    });

    const handlePasswordChange = (e) => {
        const password = e.target.value;
        setPassword(password);

        setCriteria({
            length: password.length >= 8,
            lowercase: /[a-z]/.test(password),
            uppercase: /[A-Z]/.test(password),
            number: /\d/.test(password),
            specialChar: /[!@#$%^&*()_+[\]{};':"\\|,.<>?]/.test(password),
        });
    };

    const initialPasswordValues = { password: '', confirmPassword: '' };

    const EmailForm = () => (
        <Form
            size='large'
            name="email-reset"
            className="login-form"
            initialValues={{ remember: true }}
            onFinish={onEmailSubmit}
        >
            <Form.Item
                name="email"
                rules={[
                    { type: 'email', message: 'Please enter a valid Email!' },
                    { required: true, message: 'Please enter your Email!' }
                ]}
            >
                <Input prefix={<UserOutlined />} placeholder="Email" />
            </Form.Item>
            <Form.Item className='action-btn'>
                <Button size='large' loading={loader} block type="primary" htmlType="submit">
                    Send Password Reset Link
                </Button>
                <Flex justify='center' className='register-btn' align='center'>
                    <p className='label'>Remember your password?</p>
                    <Link className='signup-url' to="/login">Log in</Link>
                </Flex>
            </Form.Item>
        </Form>
    );

    return (
        <Flex justify="space-around" className='login-container'>
            <Flex align="center" className="form-container" vertical>
                <p className='login-msg'>{verificationMessage}</p>
                {isVerifying ? (
                    <Spin indicator={<LoadingOutlined style={{ fontSize: 48 }} spin />} />
                ) : (
                    !isError ? (
                        showPasswordForm ? (
                            <Form
                                size='large'
                                name="password-reset"
                                className="login-form"
                                initialValues={initialPasswordValues}
                                form={form}
                                onFinish={onFinish}
                            >
                                <Form.Item
                                    name="password"
                                    className='password-field'
                                    rules={[
                                        { required: true, message: 'Please enter the Password!' },
                                        {
                                            validator: (_, value) => {
                                                const isValid = /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[!@#$%^&*()_+[\]{};':"\\|,.<>?]).{8,}$/.test(value);
                                                return isValid ? Promise.resolve() : Promise.reject();
                                            },
                                        },
                                    ]}
                                    hasFeedback
                                    validateTrigger="onChange"
                                >
                                    <Input.Password
                                        prefix={<LockOutlined />}
                                        placeholder="Password"
                                        onChange={handlePasswordChange}
                                        addonAfter={<PasswordValidator criteria={criteria} />}
                                    />
                                </Form.Item>
                                <Tooltip title={scoreFeedback[passwordScore]}>
                                    <div className="strength-bar">
                                        <PasswordStrengthBar
                                            password={password}
                                            scoreWords={[]}
                                            shortScoreWord=""
                                            onChangeScore={(score) => setPasswordScore(score)}
                                        />
                                    </div>
                                </Tooltip>
                                <Form.Item
                                    name="confirmPassword"
                                    dependencies={['password']}
                                    hasFeedback
                                    rules={[
                                        { required: true, message: 'Please confirm your new password!' },
                                        ({ getFieldValue }) => ({
                                            validator(_, value) {
                                                return !value || getFieldValue('password') === value ? Promise.resolve() : Promise.reject('The passwords do not match!');
                                            },
                                        }),
                                    ]}
                                >
                                    <Input.Password
                                        prefix={<LockOutlined />}
                                        placeholder="Confirm Password"
                                    />
                                </Form.Item>
                                <Form.Item className='action-btn'>
                                    <Button loading={loader} block type="primary" htmlType="submit" size='large'>Reset Password</Button>
                                </Form.Item>
                            </Form>
                        ) : showEmailForm && <EmailForm />
                    ) : (
                        <Flex justify='center' className='register-btn' align='center'>
                            <p className='label'>Remember your password?</p>
                            <Link className='signup-url' to="/login">Log in</Link>
                        </Flex>
                    )
                )}
            </Flex>
        </Flex>
    );
}