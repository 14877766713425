import React, {useState,useEffect} from 'react';
import './home.scss';
import SideNav from '../layout/sidenav/SideNav';
import { Layout } from 'antd';
import MainHeader from '../layout/header/MainHeader';
import { Outlet,useNavigate,useLocation, useParams } from 'react-router-dom';
import { useSelector } from "react-redux";
import NotificationComponent from "../notification/notification";

const { Content } = Layout;
const HomeComponent = () => {

  const currentSite = useSelector((state) => state.site.currentSite);

  return (
    <Layout>
      <MainHeader />
      <Layout>
        <NotificationComponent />
        <SideNav disabled={currentSite} currentSite={currentSite}/>
        <Layout className='main-layout'>
          <Content className='main-content'>
            <div className='main-page'>
              <Outlet />
            </div>
          </Content>
        </Layout>
      </Layout>
    </Layout>
  );
};
export default HomeComponent;