import React from "react";
import { Drawer, Space, Card, Button, Empty, Flex } from 'antd';
import { DeleteOutlined, CloseOutlined } from '@ant-design/icons';
import { motion, AnimatePresence } from 'framer-motion';
import DateComponent from "../../common/DateComponent"; // Import DateComponent
import { useDispatch } from 'react-redux'; // Import useDispatch
import { clearNotification, clearNotifications } from "../../../redux/actions/UserAction";

function NotificationDrawer({ visible, onClose, notifications, setNotifications, setUnReadCount }) {
  const dispatch = useDispatch(); // Initialize dispatch

  async function clearAllNotifications() {
    const results = await dispatch(clearNotifications());
    if (results.status) {
      setNotifications([]);
      setUnReadCount(0);
    }
  }

  function removeItem(arr, item) {
    const index = arr.indexOf(item);
    if (index > -1) arr.splice(index, 1);
  }

  async function handleClearNotification(notification) {
    const result = await dispatch(clearNotification(notification.id));
    if (result.status) {
      setNotifications((prevNotifications) => {
        const newNotifications = prevNotifications.filter(n => n.id !== notification.id);
        return newNotifications;
      });
    }
  }

  const getStatusColor = (status) => {
    switch (status) {
      case 'success':
        return '#52c41a'; // Ant Design green
      case 'error':
        return '#ff4d4f'; // Ant Design red
      case 'info':
        return '#1890ff'; // Ant Design blue
      case 'warning':
        return '#faad14'; // Ant Design orange
      case 'danger':
        return '#ff4d4f'; // Ant Design red (same as error)
      default:
        return '#d9d9d9'; // Ant Design gray
    }
  };

  const notificationContent = (
    notifications.length === 0 ? <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="No notifications" />
      :
      <ul className="notification-list">
        <AnimatePresence mode="popLayout">
          {notifications.map((notification, index) =>
            <motion.li
              key={notification.id}
              layout
              initial={{ scale: 0.8, opacity: 0 }}
              animate={{ scale: 1, opacity: 1 }}
              exit={{ scale: 0.8, opacity: 0, transition: { delay: 0.15 } }}
              transition={{ type: "spring", delay: index * 0.15 }}
              className="notification-item"
              style={{
                borderLeft: `4px solid ${getStatusColor(notification.status)}`
              }}
            >
              <div className="notification-card">
                <Card
                  style={{ marginBottom: '1px', backgroundColor: !notification.read ? '#e6f4ff' : 'white' }}
                onClick={() => setNotifications((prevNotifications) => {
                  const newNotifications = [...prevNotifications];
                  const notificationIndex = newNotifications.indexOf(notification);
                  if (notificationIndex > -1) {
                    newNotifications[notificationIndex] = { ...notification, read: true };
                  }
                  return newNotifications;
                })}
                >
                  <Card.Meta
                    title={
                      <Flex justify="space-between" align="center">
                        <Flex vertical>
                          <span className="notification-date">
                            <DateComponent dateValue={notification.date} /> {/* Use DateComponent */}
                          </span>
                          <span className="notification-title">{notification.title}</span>
                        </Flex>
                        <CloseOutlined
                          key="delete"
                          size={12}
                          className="notification-delete-icon"
                          onClick={() => handleClearNotification(notification)}
                        />
                      </Flex>
                    }
                    description={<p className="notification-content">{notification.description}</p>}
                  />
                </Card>
              </div>
            </motion.li>
          )}
        </AnimatePresence>
      </ul>
  );

  const getContainer = () => {
    return document.querySelector('.main-layout');
  }

  return (
    <Drawer
      open={visible}
      getContainer={getContainer} // Specify the container element
      className="notification-drawer"
      rootClassName="notification-drawer-root"
      title={
        <Flex justify="space-between" align="center">
          <span>Notifications</span>
        </Flex>
      }
      extra={
        <Space>
          {notifications.length > 0 ? (
            <Button color="danger" variant="outlined" icon={<DeleteOutlined />} onClick={clearAllNotifications}>
              Clear All
            </Button>
          ) : null}
        </Space>
      }
      placement="right"
      onClose={onClose}
      width={300}
    >
      {notificationContent}
    </Drawer>
  );
}

export default NotificationDrawer;