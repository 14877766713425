import React, { useState, useEffect, useMemo } from "react";
import {Table,Flex} from 'antd';
import './inputtables.scss';
import tableMappings from "./scheduler_table_settings.json";
import DateComponent from "../common/DateComponent";

function OutputTables(props) {
    const {tables} = props;
    const outputTableMappings = tableMappings.output_tables
    const dataSource = useMemo(() =>
        Object.keys(tables).map((key) => ({ key })),
        [tables]
    );

    const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    const [selectedTable, setSelectedTable] = useState([]);

    useEffect(() => {
        if (dataSource.length > 0) {
          const defaultTableSelected = dataSource[0];
          setSelectedRowKeys([defaultTableSelected.key]);
          setSelectedTable(tables[defaultTableSelected.key]);
        }
    }, [dataSource, tables]);

    const onRowSelectionChange = (selectedRowKeys) => {
        setSelectedRowKeys(selectedRowKeys);
        const selectedRow = selectedRowKeys[0];
        const table = tables[selectedRow];
        setSelectedTable(table);
    };

    const outputTableColumns = [
        {
          title: 'Tables',
          dataIndex: 'label',
          key: 'key',
        },
    ];

    const outputTableData = dataSource.map((item) => {
        const tableMap = outputTableMappings[item.key];
        return {
          key: item.key,
          label: tableMap ? tableMap.label : item.key
        };
    });

    const dataColumns = selectedRowKeys[0] ? outputTableMappings[selectedRowKeys[0]].fields
    .filter(field => field.visibility === "Show")
    .map(field => ({
        title: field.label || field.name,
        dataIndex: field.name,
        key: field.name,
        render: (text) => field.isDateField ? (<DateComponent dateValue={text}/>) : (<span>{text}</span>)
    })) : [];

    return (
        <Flex className="run-detail-container">
            <Table
                className="input-tables list-detail-table run-detail-table"
                columns={outputTableColumns}
                dataSource={outputTableData}
                pagination={false}
                rowSelection={{
                    type: 'radio',
                    onChange: onRowSelectionChange,
                    selectedRowKeys: selectedRowKeys,
                    columnWidth: 48,
                }}
                onRow={(record) => ({
                onClick: () => {
                    onRowSelectionChange([record.key]);
                },
                })}
                rowKey="key"
                scroll={{ y:'550px' }}
            />
            <Flex vertical className="table-detail-container">
                <Table
                    className="output-data-table detail-table run-detail-table"
                    dataSource={selectedTable}
                    columns={dataColumns}
                    pagination={false}
                    rowKey="_id"
                    scroll={{
                        x: 2500,
                        y: 550,
                    }}
                    virtual
                />
            </Flex>
        </Flex>
    )

}

export default OutputTables;