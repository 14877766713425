import {
    BarChart,
    Bar,
    CartesianGrid,
    XAxis,
    YAxis,
    Tooltip,
    ResponsiveContainer
} from "recharts";

function BarChartComponent(props) {
    const { data, properties, formatDate, showAxes=true } = props;
    return (
        data && (
            <ResponsiveContainer width="100%" height="100%" className={!showAxes && `chart-container`}>
                <BarChart
                    data={data}
                    margin={!showAxes ? { top: 10, right: 40, left: 0, bottom: 40 } : { top: 10, right: 10, left: 10, bottom: 10 }}
                >
                    <CartesianGrid strokeDasharray="3 3" strokeOpacity={0.1} />
                    <XAxis hide={!showAxes}  dataKey={properties.Axes.x.column} tickFormatter={properties.Axes.x.isDateTime && formatDate} />
                    <YAxis hide={!showAxes} dataKey={properties.Axes.y.column} />
                    <Tooltip /> 
                    <Bar dataKey={properties.Axes.y.column} fill={properties.Color} isAnimationActive={false} />
                </BarChart>
            </ResponsiveContainer>
        )
    )

}

export default BarChartComponent;