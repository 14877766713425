import React, {useState,useEffect} from "react";
import dayjs from 'dayjs';
import { DatePicker } from 'antd';
import { convertDateToLocalTime } from "../../util";
import { useDateFormat } from "./DateComponent";

function DateTimePicker(props) {
    const {dateValue,onChangeValue} = props;
    const [selectedDate, setSelectedDate] = useState(null);
    const { dateFormat, timeFormat } = useDateFormat();

    useEffect(() => {
        setSelectedDate(dayjs(convertDateToLocalTime(dateValue)))
    }, [dateValue]);

    const handleDateChange = (date,dateStr) => {
        if (date) {
            const utcDate = dayjs(date).toISOString().replace('Z', '');
            setSelectedDate(date);
            onChangeValue(utcDate);
        } else {
            setSelectedDate(null);
        }
    };

    const use12Hours = timeFormat === '12-hour';

    return (
        selectedDate &&
        (
            <DatePicker
                className="date-time-picker"
                defaultValue={selectedDate}
                onChange={handleDateChange}
                showTime={{ format: use12Hours ? 'hh:mm A' : 'HH:mm', use12Hours }}
                format={`${dateFormat} ${use12Hours ? 'hh:mm A' : 'HH:mm'}`}
                placeholder="Select Date and Time"
                suffixIcon={undefined}
                allowClear={false}
            />
        )
    )

}

export default DateTimePicker;