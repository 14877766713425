import React,{useState} from "react";
import { Slider, InputNumber, Flex } from 'antd';

function SliderComponent(props) {
    const {value,label,onChange} = props;
    const [sliderValue, setSliderValue] = useState(value);

    function handleChangeValue(newValue) {
        setSliderValue(newValue);
        onChange(newValue);
    }
    
    return (
        <div className="slider-container">
            <Flex className="flex-slider">
                <strong className="field-key"><span>{label}</span></strong>
                <InputNumber
                    className="slider-number-field"
                    min={0}
                    max={1000}
                    value={sliderValue}
                    onChange={handleChangeValue}
                />
            </Flex>
            <Slider className="slider" min={0} max={1000} value={sliderValue} tooltip={{ open: false }} onChange={handleChangeValue}/>
        </div>
    )
}

export default SliderComponent;