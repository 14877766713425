export const highlightColors = [
    '#FF7875',
    '#FF9C6E',
    '#FFC069',
    '#FFD666',
    '#FFF566',
    '#D3F261',
    '#95DE64',
    '#5CDBD3',
    '#69C0FF',
    '#85A5FF',
    '#B37FEB',
    '#FF85C0'
]