import React, { useState } from 'react';
import { Form, Input, Button, message, Card, Tooltip, Flex } from 'antd';
import PasswordStrengthBar from 'react-password-strength-bar';
import PasswordValidator from '../sign-up/PasswordValidator';
import { useDispatch } from 'react-redux';
import { changePassword } from '../../redux/actions/UserAction';
import { useTranslation } from 'react-i18next';
import './change-password.scss';

const ChangePasswordComponent = () => {
    const { t } = useTranslation("changepassword", { useSuspense: false });
    const [isLoading, setIsLoading] = useState(false);
    const [password, setPassword] = useState('');
    const [passwordScore, setPasswordScore] = useState(0);
    const scoreFeedback = ['Weak', 'Weak', 'Fair', 'Good', 'Strong'];
    const [criteria, setCriteria] = useState({
        length: false,
        lowercase: false,
        uppercase: false,
        number: false,
        specialChar: false,
    });
    const dispatch = useDispatch();
    const [form] = Form.useForm();

    const handlePasswordChange = (e) => {
        const password = e.target.value;
        setPassword(password);

        const hasLowercase = /[a-z]/.test(password);
        const hasUppercase = /[A-Z]/.test(password);
        const hasNumber = /\d/.test(password);
        const hasSpecialChar = /[!@#$%^&*()_+[\]{};':"\\|,.<>?]/.test(password);
        const isLongEnough = password.length >= 8;

        setCriteria({
            length: isLongEnough,
            lowercase: hasLowercase,
            uppercase: hasUppercase,
            number: hasNumber,
            specialChar: hasSpecialChar,
        });
    };

    const handleSubmit = async (values) => {
        setIsLoading(true);
        const result = await dispatch(changePassword(values));
        if (result.status) {
            message.success(t('passwordChangedSuccess'));
            form.resetFields();
            setPassword('');
            setPasswordScore(0);
            setCriteria({
                length: false,
                lowercase: false,
                uppercase: false,
                number: false,
                specialChar: false,
            });
        } else {
            const errorMessage = result.data.message || t('passwordChangedFailure');
            const errorDetails = result.data.errors?.map(err => err.description).join(', ') || '';
            message.error(`${errorMessage} ${errorDetails}`);
        }
        setIsLoading(false);
    };

    return (
        <Card title={t('title')}>
            <Form style={{width: '50%', margin: 'auto'}} form={form} layout="vertical" onFinish={handleSubmit} className="change-password-form">
                <Form.Item
                    label={t('oldPassword')}
                    name="oldPassword"
                    rules={[{ required: true, message: t('oldPasswordRequired') }]}
                >
                    <Input.Password placeholder={t('oldPassword')} />
                </Form.Item>
                <Form.Item
                    label={t('newPassword')}
                    name="newPassword"
                    className='password-field'
                    rules={[
                        { required: true, message: t('newPasswordRequired') },
                        {
                            validator: (_, value) => {
                                const isValid = /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[!@#$%^&*()_+[\]{};':"\\|,.<>?]).{8,}$/.test(value);
                                if (isValid) {
                                    return Promise.resolve();
                                }
                                return Promise.reject(new Error(t('passwordMismatch')));
                            },
                        },
                    ]}
                    hasFeedback
                    validateTrigger="onChange"
                >
                    <Input.Password
                        placeholder={t('newPassword')}
                        onChange={handlePasswordChange}
                        addonAfter={<PasswordValidator criteria={criteria} />}
                    />
                </Form.Item>
                <Tooltip title={scoreFeedback[passwordScore]}>
                    <div className="strength-bar">
                        <PasswordStrengthBar
                            password={password}
                            scoreWords={[]}
                            shortScoreWord=""
                            onChangeScore={(score) => setPasswordScore(score)}
                        />
                    </div>
                </Tooltip>
                <Form.Item
                    label={t('confirmPassword')}
                    name="confirmPassword"
                    dependencies={['newPassword']}
                    hasFeedback
                    rules={[
                        { required: true, message: t('confirmPasswordRequired') },
                        ({ getFieldValue }) => ({
                            validator(_, value) {
                                if (!value || getFieldValue('newPassword') === value) {
                                    return Promise.resolve();
                                }
                                return Promise.reject(new Error(t('passwordMismatch')));
                            },
                        }),
                    ]}
                >
                    <Input.Password
                        placeholder={t('confirmPassword')}
                    />
                </Form.Item>
                <Form.Item>
                    <Flex justify='center'>
                        <Button type="primary" htmlType="submit" loading={isLoading}>
                            {t('changePassword')}
                        </Button>
                    </Flex>
                </Form.Item>
            </Form>
        </Card>
    );
};

export default ChangePasswordComponent;