import React, { useState, useEffect } from "react";
import { Input, Dropdown } from 'antd';
import DateTimePicker from "./DateTimePicker";

function EditableField({ value, onChange, type, label }) {
    const [enableEdit, setEnableEdit] = useState(false);
    const [inputValue, setInputValue] = useState(value);
  
    const handleInputChange = (e) => {
      setInputValue(e.target.value);
    };

    function saveDateField(dateValue) {
      onChange(dateValue);
    }

    function convertDataType(field) {
      let newValue;
      if (type === 'number') {
        newValue = Number(field);
      } else if (type === 'boolean') {
        newValue = field === 'true';
      } else {
        newValue = field;
      }
      return newValue;
    }
  
    const handleSave = () => {
      setEnableEdit(false);
      const newValue = convertDataType(inputValue);
      onChange(newValue);
    };
  
    const handleEditClick = (e) => {
      e.stopPropagation();
      if (!enableEdit) {
        setEnableEdit(true);
      }
    };
  
    return (
      <>
      <strong className="field-key"><span>{label}</span><span>:</span></strong>
        <div className="field-value">
          {enableEdit ? (
              
              <Input
                  className="input-field"
                  value={inputValue}
                  onChange={handleInputChange}
                  onBlur={handleSave}
                  onPressEnter={handleSave}
                  autoFocus
              />
              
          ) : (
              type === 'date' ? (
                <DateTimePicker dateValue={value} onChangeValue={saveDateField}/>  
              ) : (
                <div className="input-value" onClick={handleEditClick}>{inputValue !== null ? inputValue : ''}</div>
              )
          )}
        </div>
      </>
    );
}

export default EditableField;