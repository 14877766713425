
import React from 'react';
import { useSelector } from 'react-redux';
import { formatDate } from '../../util';

export const useDateFormat = () => {
    const user = useSelector(state => state.user.user);
    return {
        dateFormat: user?.dateFormat || 'DD-MMM', // Default format
        timeFormat: user?.timeFormat || '12-hour', // Default time format
    };
};

const DateComponent = ({ dateValue }) => {
    const { dateFormat, timeFormat } = useDateFormat();
    
    return (
        <span>{formatDate(dateValue, dateFormat, timeFormat)}</span>
    );
};

export default DateComponent;