import React, { useState, useEffect, useMemo, useRef } from "react";
import { Table, Input, Flex, Dropdown, Button, message } from 'antd';
import { useParams } from "react-router-dom";
import {PlusOutlined, DeleteOutlined, ImportOutlined, ExportOutlined} from "@ant-design/icons";
import './inputtables.scss';
import tableMappings from "./scheduler_table_settings.json";
import DateTimePicker from "../common/DateTimePicker";
import { useDispatch } from 'react-redux';
import { exportCollectionData, importRunData, performETL } from '../../redux/actions/SchedulerRunActions';
import { downloadFile } from '../../utils/fileDownloadUtil';
import FilterComponent from "../common/FilterComponent";

const EditableCell = ({
  title,
  editable,
  children,
  dataIndex,
  isDateField,
  record,
  handleSave,
  type,
  handleDeleteRow,
  handleCloneRowAbove,
  handleCloneRowBelow,
  handleAddEmptyRow,
  ...restProps
}) => {
  const [isEditMode, setIsEditMode] = useState(false); // holds cell editable status
  const [value, setValue] = useState(record[dataIndex]); // holds the edited value of a cell
  const tdRef = useRef(null);
  const booleanFieldList = [{label: "true", key: 'true', onClick: () => handleChangeField('true')}, {label: "false", key: 'false', onClick: () => handleChangeField('false')}]

  useEffect(() => {
    const cellValue = record[dataIndex];
    
    if (!isEditMode) {
      setValue(cellValue);
    }
  }, [record, dataIndex, isEditMode]);

  const save = () => {
    setIsEditMode(false);
    let newValue = typeof(value) !== 'string' ? value : convertDataType(value);
    // if (value && type === 'number') {
    //   newValue = Number(value);
    // } else if (value && type === 'boolean') {
    //   newValue = value === 'true';
    // } else {
    //   newValue = value;
    // }
    handleSave({ ...record, [dataIndex]: newValue });
  };

  const convertDataType = (value) => {

      // Trim the input to remove unnecessary whitespaces
      value = value.trim();

      // Check for number
      if (!isNaN(value) && value !== "") {
          return Number(value);
      }
      // Check for boolean true/false
      else if (value.toLowerCase() === 'true') {
          return true;
      }
      else if (value.toLowerCase() === 'false') {
          return false;
      }
      // Check for valid date string
      else if (!isNaN(Date.parse(value))) {
          return value.toString();
      }
  
      // Default to string if no other match
      return value;
  }

  const onInputChange = (e) => {
    setValue(e.target.value);
  };

  const handleClick = (e) => {
    e.stopPropagation();
    if (!isEditMode) {
      setIsEditMode(true);
    }
  };

  const items = [
    {
      label: "Add New Row Above",
      key: "addRowAbove",
      icon: <PlusOutlined/>,
      onClick: () => {
        handleAddEmptyRow(record._id,'ABOVE');
      }
    },
    {
      label: "Clone Row Above",
      key: "cloneRowAbove",
      icon: <PlusOutlined/>,
      onClick: () => {
        handleCloneRowAbove(record._id);
      }
    },
    {
      label: "Clone Row Below",
      key: "cloneRowBelow",
      icon: <PlusOutlined/>,
      onClick: () => {
        handleCloneRowBelow(record._id);
      }
    },
    {
      label: "Add New Row Below",
      key: "addRowBelow",
      icon: <PlusOutlined/>,
      onClick: () => {
        handleAddEmptyRow(record._id,'BELOW');
      }
    },
    {
      label: "Delete Row",
      key: "deleteRow",
      icon: <DeleteOutlined/>,
      danger: true,
      onClick: () => {
        handleDeleteRow(record._id);
      },
    }
  ]

  function saveDateField(dateValue) {
    handleSave({ ...record, [dataIndex]: dateValue });
  }

  function handleChangeField(fieldValue) {
    setValue(fieldValue);
    const updatedValue = convertDataType(fieldValue);
    handleSave({ ...record, [dataIndex]: updatedValue });
  }

  return (
    <Dropdown menu={{items}} trigger={["contextMenu"]}>
      <td {...restProps} onClick={handleClick} ref={tdRef}>
        {isDateField ? (
            <DateTimePicker dateValue={value} onChangeValue={saveDateField}/>
          ) : type === 'boolean' ? (
            <Dropdown trigger={['click']} menu={{items: booleanFieldList}}><div className="boolean-field">{String(value)}</div></Dropdown>
          ) : (
            isEditMode ? (
            <Input
              value={value}
              onChange={onInputChange}
              onBlur={save}
              onPressEnter={save}
              autoFocus
              className="field-input"
            />
            ) : (
              <div className="cell-value">{children}</div>
            )
          )
        }
      </td>
    </Dropdown>
  );
};


function InputTables(props) {
  const {tables, handleExportTable,planningPeriodName} = props;
  const dispatch = useDispatch();
  const {runId} = useParams();
  const inputTableMappings = tableMappings.input_tables
  const inputDataSource = useMemo(() =>
    Object.keys(tables).map((key) => ({ key })),
    [tables]
  );
  const filteredItems = useRef({});
  const [selectedInputRows, setSelectedInputRows] = useState([]);
  const [selectedTable, setSelectedTable] = useState([]);
  const [dataSource, setDataSource] = useState([]);
  const [importLoading, setImportLoading] = useState(false);
  const [exportLoading, setExportLoading] = useState(false);
  const [uploadLoader, setUploadLoader] = useState(false);
  const [resetFilter, setResetFilter] = useState(false);

  const handleSave = (row) => {
    const newData = [...dataSource];
    const currentData = [...selectedTable];
    const index = newData.findIndex((item) => row._id === item._id);
    const currentIndex = currentData.findIndex((item) => row._id === item._id);
    if (index > -1) {
      newData.splice(index, 1, { ...row });
      setDataSource(newData);
      const currentSelectedTable = selectedInputRows[0];
      tables[currentSelectedTable] = newData;
    }
    if(currentIndex > -1) {
      currentData.splice(currentIndex, 1, { ...row });
      setSelectedTable(currentData);
    }
  };

  useEffect(() => {
    if (inputDataSource.length > 0) {
      let defaultTable = "";

      if (selectedInputRows &&
        selectedInputRows.length === 1) {
        defaultTable = selectedInputRows[0];
      }
      else {
        defaultTable = inputDataSource[0].key;
      }

      setSelectedInputRows([defaultTable]);
      setSelectedTable(tables[defaultTable]);
      setDataSource(tables[defaultTable]);
  }
  }, [inputDataSource, tables]);

  const onRowSelectionChange = (selectedInputRows) => {
    setSelectedInputRows(selectedInputRows);
    const selectedRow = selectedInputRows[0];
    const table = tables[selectedRow];
    setSelectedTable(table);
    setDataSource(table);
    setResetFilter(true);
  };

  function handleDeleteRow(currentRowId) {
    const currentTableData = [...dataSource];
    const currentData = [...selectedTable];
    const filteredRows = currentTableData.filter(row => row._id !== currentRowId);
    const currentFilteredRows = currentData.filter(row => row._id !== currentRowId);
    if(currentFilteredRows.length === 0 && filteredRows.length > 0) {
      setSelectedTable(filteredRows);
      setResetFilter(true);
    }
    else setSelectedTable(currentFilteredRows);
    setDataSource(filteredRows);
    const currentSelectedTable = selectedInputRows[0];
    tables[currentSelectedTable] = filteredRows;
  }

  function createNewRow(tableLength,currentRow) {
    const newRecord = {...currentRow};
    newRecord['_id'] = String(tableLength + Math.random());
    newRecord['run_id'] = parseInt(runId);
    return newRecord;
  }

  function handleCloneRowAbove(currentRowId) {
    const currentTableData = [...dataSource];
    const currentData = [...selectedTable];
    const currentRowIndex = currentTableData.findIndex(row => row._id === currentRowId);
    const currentIndex = currentData.findIndex(row => row._id === currentRowId);
    const newRecord = createNewRow(currentTableData.length,currentTableData[currentRowIndex])
    currentTableData.splice(currentRowIndex,0,newRecord);
    currentData.splice(currentIndex,0,newRecord);
    setSelectedTable(currentData);
    setDataSource(currentTableData);
    const currentSelectedTable = selectedInputRows[0];
    tables[currentSelectedTable] = currentTableData;
  }

  function handleCloneRowBelow(currentRowId) {
    const currentTableData = [...dataSource];
    const currentData = [...selectedTable];
    const currentRowIndex = currentTableData.findIndex(row => row._id === currentRowId);
    const currentIndex = currentData.findIndex(row => row._id === currentRowId);
    const newRecord = createNewRow(currentTableData.length,currentTableData[currentRowIndex])
    currentTableData.splice(currentRowIndex+1,0,newRecord);
    currentData.splice(currentIndex+1,0,newRecord);
    setSelectedTable(currentData);
    setDataSource(currentTableData);
    const currentSelectedTable = selectedInputRows[0];
    tables[currentSelectedTable] = currentTableData;
  }

  function handleAddEmptyRow(currentRowId,position='') {
    const currentTableData = [...dataSource];
    const currentData = [...selectedTable];
    const currentRowIndex = currentTableData.findIndex(row => row._id === currentRowId);
    const currentIndex = currentData.findIndex(row => row._id === currentRowId);
    const currentSelectedTable = selectedInputRows[0];
    const row = inputTableMappings[currentSelectedTable]?.fields.reduce((acc,field) => {
      acc[field.name]= ''
      return acc; 
    }, {})
    const newRow = createNewRow(currentTableData.length,row)
    if (position === 'ABOVE') {
      currentTableData.splice(currentRowIndex, 0, newRow);
      currentData.splice(currentIndex, 0, newRow);
    } else if (position === 'BELOW') {
        currentTableData.splice(currentRowIndex + 1, 0, newRow);
        currentData.splice(currentIndex + 1, 0, newRow);
    } else {
        currentTableData.splice(0, 0, newRow);
        currentData.splice(0, 0, newRow);
    }
    setSelectedTable(currentData);
    setDataSource(currentTableData);
    tables[currentSelectedTable] = currentTableData;
  }

  const handleExport = async () => {
    setExportLoading(true);
    const selectedTableKey = selectedInputRows[0];
    const response = await dispatch(exportCollectionData(runId, selectedTableKey));
    if (response.status) {
      const blob = new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      downloadFile(blob, `${selectedTableKey}.xlsx`, 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet');
    } else {
      console.error('Export failed', response.error);
    }
    setExportLoading(false);
  };

  const handleImportClick = () => {
    const input = document.createElement('input');
    input.type = 'file';
    input.accept = '.xlsx';
    input.onchange = async (e) => {
      const file = e.target.files[0];
      if (file) {
        setImportLoading(true);
        const response = await dispatch(importRunData(runId, file));
        if (response.status) {
          message.success('Run imported successfully');
          if(handleExportTable) {
            await handleExportTable();
          }
          // Refresh data or perform any other necessary actions
        } else {
          message.error('Failed to import run');
        }
        setImportLoading(false);
      }
    };
    input.click();
  };

  const handleUploadData = () => {
    const input = document.createElement('input');
    input.type = 'file';
    input.accept = '.xlsx';
    input.onchange = async (e) => {
      const file = e.target.files[0];
      if (file) {
        setUploadLoader(true);
        const fileKey = selectedInputRows[0] === 'cycle_time' ? 'routing' : 'ctb_kinaxis_format';
        const parameter = planningPeriodName;
        const response = await dispatch(performETL(runId, file, fileKey, parameter));
        if (response.status) {
          message.success('Data uploaded successfully');
          if(handleExportTable) {
            await handleExportTable();
          }
        } else {
          message.error('Failed to upload data');
        }
        setUploadLoader(false);
      }
    };
    input.click();
  };

  const inputTableColumns = [
    {
      title: 'Tables',
      dataIndex: 'label',
      key: 'key',
    },
  ];

  const inputTableData = inputDataSource.map((item) => {
    const tableMap = inputTableMappings[item.key];
    return {
      key: item.key,
      label: tableMap ? tableMap.label : item.key
    };
  });

  const outputTableColumns = selectedInputRows[0] ? inputTableMappings[selectedInputRows[0]].fields
  .filter(field => field.visibility === "Show")
  .map(field => ({
      title: field.label || field.name,
      width: field.width,
      dataIndex: field.name,
      key: field.name,
      editable: true,
      isDateField: field.isDateField,
      type: typeof selectedTable?.[0]?.[field?.name] === 'boolean' ? 'boolean' : typeof selectedTable?.[0]?.[field?.name] === 'number' ? 'number' : 'string'
  })) : [];

  const mergedColumns = outputTableColumns.map((col) => {
    return {
      ...col,
      width: col.width,
      ellipsis: true,
      onCell: (record) => ({
        record,
        editable: col.editable,
        dataIndex: col.dataIndex,
        title: col.title,
        isDateField: col.isDateField,
        handleSave: handleSave,
        type: col.type,
        handleDeleteRow: handleDeleteRow,
        handleCloneRowAbove: handleCloneRowAbove,
        handleCloneRowBelow: handleCloneRowBelow,
        handleAddEmptyRow: handleAddEmptyRow
      }),
    };
  });

  function createFilterDataSource(data) {
    const selectedTableName = selectedInputRows[0];
    const currentTableFields = inputTableMappings[selectedTableName]?.fields;
    return currentTableFields?.filter(column=> column.visibility === 'Show')?.map((column) => {
        const columnKey = column.name;
        const uniqueValues = [...new Set(data.map(item => item[columnKey]))];
        return {
            key: column.name,
            label: column.label,
            values: uniqueValues
        };
    });
  }

  const filterDataSource = createFilterDataSource(tables[selectedInputRows[0]]);

  function handleFilterItems(currentFilter=null) {
    const currentData = tables[selectedInputRows[0]]
    return currentData.filter((item) => {
        const { current } = filteredItems;
        const currentFilterField = currentFilter;
        if(currentFilter && current[currentFilter]?.includes(item[currentFilterField]) ) return true;
    
        return  Object.keys(current).every((filterKey) => {
            if(currentFilter && currentFilter === filterKey) return true;
            const field = filterKey;
            const filterValues = current[filterKey];
            const itemValue = item[field];
    
            return (
                !filterValues || filterValues.length === 0 || filterValues.includes(itemValue)
            );
        });
    }); 
  }

  function getFilterItemsList(selectedRow) {
    const itemList = handleFilterItems(selectedRow)
    return  Array.from(new Set(itemList.map(item => item[selectedRow])))
            .map(entity => ({
                key: entity,
                label: entity,
                relatedKey: selectedRow
            }));
  }

  function applyFilter() {
    const filterData = handleFilterItems();
    setSelectedTable(filterData);
    setResetFilter(false);
  }

  return (
    <Flex vertical gap={10}>
      <Flex justify="end" align="center" gap={8}>
        { (selectedInputRows[0] === 'cycle_time' || selectedInputRows[0] === 'demand') &&
          <Button className="upload-btn" onClick={handleUploadData} loading={uploadLoader} disabled={uploadLoader}>
            <ImportOutlined />
            <span>Upload Data</span>
          </Button>
        }
        {filterDataSource && <FilterComponent data={filterDataSource} filteredItems={filteredItems} getFilterItemsList={getFilterItemsList} applyFilter={applyFilter} resetFilter={resetFilter}/>}
        <Button className="import-btn" onClick={handleImportClick} loading={importLoading} disabled={importLoading}>
          <ImportOutlined />
          <span>Import Table</span>
        </Button>
        <Button className="export-btn" onClick={handleExport} loading={exportLoading} disabled={exportLoading}>
          <ExportOutlined />
          <span>Export Table</span>
        </Button>
      </Flex>
      <Flex className="run-detail-container">
        <Table
          className="input-tables list-detail-table run-detail-table"
          columns={inputTableColumns}
          dataSource={inputTableData}
          pagination={false}
          rowSelection={{
            type: 'radio',
            onChange: onRowSelectionChange,
            selectedRowKeys: selectedInputRows,
            columnWidth: 48,
          }}
          onRow={(record) => ({
            onClick: () => {
              onRowSelectionChange([record.key]);
            },
          })}
          rowKey="key"
          scroll={{ y: '550px' }}
        />
        {(
          <div className="table-detail-container">
            <Table
              className="detail-table run-detail-table"
              components={
                dataSource.length > 0
                  ? {
                    body: {
                      cell: EditableCell,
                    },
                  }
                  : undefined
              }
              dataSource={selectedTable}
              columns={mergedColumns}
              pagination={false}
              rowKey="_id"
              scroll={{
                x: mergedColumns.reduce((acc, col) => acc + (col.width || 200), 0),
                y: 470,
              }}
              virtual
              tableLayout="auto"
              locale={{
                emptyText: (
                  <Flex justify="center" align="center" vertical>
                    <h3>No Data Available</h3>
                    <Button className="add-row-btn" type="primary" onClick={handleAddEmptyRow}>
                      <PlusOutlined />
                      <span>Add New Row</span>
                    </Button>
                  </Flex>
                ),
              }}
            />
          </div>
        )}
      </Flex>
    </Flex>
  );
}

export default InputTables;
