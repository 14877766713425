import React from 'react';
import { Modal, Form, Input, Upload } from 'antd';
import { UploadOutlined } from '@ant-design/icons';

const { Dragger } = Upload;

const RunModal = ({
    visible,
    title,
    loading,
    runName,
    setRunName,
    isClone,
    fileList,
    setFileList,
    handleUploadChange,
    handleOk,
    handleCancel
}) => (
    <Modal
        title={title}
        visible={visible}
        onOk={handleOk}
        confirmLoading={loading}
        onCancel={handleCancel}
    >
        <Form layout="vertical">
            <Form.Item
                label="Run Name"
                rules={[{ required: true, message: 'Please input the run name!' }]}
            >
                <Input 
                    placeholder="Enter run name" 
                    value={runName}
                    onChange={(e) => setRunName(e.target.value)}
                />
            </Form.Item>
            {!isClone && (
                <Form.Item>
                    <Dragger
                        fileList={fileList}
                        onChange={handleUploadChange}
                        beforeUpload={() => false} // Prevent automatic upload
                        onRemove={() => setFileList([])}
                    >
                        <p className="ant-upload-drag-icon">
                            <UploadOutlined />
                        </p>
                        <p className="ant-upload-text">Click or drag a file to this area to upload run data</p>
                        <p className="ant-upload-hint">Support for a single file upload.</p>
                    </Dragger>
                </Form.Item>
            )}
        </Form>
    </Modal>
);

export default RunModal;