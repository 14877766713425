import React, {useState,useEffect} from "react";
import { useDispatch,useSelector } from "react-redux";
import { Link,useParams,useNavigate } from 'react-router-dom';
import {Table,Card,Flex,Tooltip,message,Tabs,Modal,Button,Dropdown,Space, Input, Form, Menu, Upload, Spin } from 'antd';
import { CopyOutlined, FolderOpenOutlined, MoreOutlined, DeleteOutlined, PlusOutlined, ReloadOutlined, SettingOutlined, FullscreenOutlined, DownOutlined, ExportOutlined, UploadOutlined, LoadingOutlined } from "@ant-design/icons";
import '../../style.scss';
import './scheduler.scss';
import { handleRunClone } from "./SchedulerService";
import RunDetailGridView from "./rundetailtabs/RunDetailGridView";
import RunDetailTableView from "./rundetailtabs/RunDetailTableView";
import { createNewRun, deleteRun, getRunById, getSchedulerRuns, RUN_NOTIFICATION_COMPLETE, exportRunData, importRunData } from "../../redux/actions/SchedulerRunActions";
import { downloadFile } from "../../utils/fileDownloadUtil";
import DateComponent from "../common/DateComponent";
import RunModal from './RunModal';

function FindRuns() {
    const {siteCode} = useParams();
    const navigate = useNavigate();
    const [selectedRunId, setSelectedRunId] = useState([]); // holds selected run id
    const [selectedRun, setSelectedRun] = useState(null); // holds selected run detail
    const dispatch = useDispatch();
    const schedulerRuns = useSelector((state) => state.schedulerRun.runs);
    const updateRunPage = useSelector((state)=> state.schedulerRun.updateRun);
    const reloadRunId = useSelector((state) => state.schedulerRun.run_id);
    const [activeTab, setActiveTab] = useState('runDetails'); // holds active tab key
    const [tabItems,setTabItems] = useState([]); // holds tab items list
    const [showConfirmation, setShowConfirmation] = useState(false);
    const [exportLoading, setExportLoading] = useState(false);
    const [importLoading, setImportLoading] = useState(false);
    const [moreActionsVisible, setMoreActionsVisible] = useState(false);
    const [fileList, setFileList] = useState([]);
    const [form] = Form.useForm();
    const items = [
        {
          label: "Run Name",
          key: 'runName',
        },
        {
          label: "User",
          key: 'user',
        },
        {
          label: 'Status',
          key: 'status',
        },
    ];

    const [runModalVisible, setRunModalVisible] = useState(false);
    const [runModalTitle, setRunModalTitle] = useState('');
    const [runModalLoading, setRunModalLoading] = useState(false);
    const [runName, setRunName] = useState('');
    const [isClone, setIsClone] = useState(false);
    const [loading, setLoading] = useState(false); // Add loading state

    async function onDuplicateRun() {
        setRunModalTitle('Clone Run');
        setIsClone(true);
        setRunModalVisible(true);
    }

    async function onAddNewRun() {
        setRunModalTitle('Add New Run');
        setIsClone(false);
        setRunModalVisible(true);
    }

    const handleRunModalOk = async () => {
        if (!runName) {
            message.error('Run name is required');
            return;
        }
        setRunModalLoading(true);
        if (isClone) {
            const response = await handleRunClone(dispatch, selectedRun.run_id, runName);
            if (response.status) {
                message.success('Run cloned Successfully');
            } else {
                message.error('Something went wrong');
            }
        } else {
            const file = fileList.length > 0 ? fileList[0].originFileObj : null;
            const response = await dispatch(createNewRun(runName, file));
            if (response && response.data) {
                message.success(<><p><i>{runName}</i> has been created successfully.</p></>);
            } else {
                message.error("Oops!!! Something went wrong. Please try again later.");
            }
        }
        setRunModalLoading(false);
        setRunModalVisible(false);
        setRunName('');
        setFileList([]);
        fetchSchedulerRuns();
    };

    const handleRunModalCancel = () => {
        setRunModalVisible(false);
        setRunName('');
        setFileList([]);
    };

    function updateTabItems(currentRun) {
        const {run_highlights,run_insights,run_parameter_list,run_time_profile,run_warnings,...run_details} = currentRun;
        setTabItems([
            {
                label: 'Run Details',
                key: 'runDetails',
                children: <RunDetailGridView currentRunDetail={run_details}/>
            },
            {
                label: 'Run Parameters',
                key: 'runParameters',
                children: run_parameter_list ? <RunDetailGridView currentRunDetail={run_parameter_list} runDetailKey="run_parameter_list"/> : <Flex justify="center"><b>No Data</b></Flex>
            },
            {
                label: 'Run Highlights',
                key: 'runHighlights',
                children: run_highlights ? <RunDetailGridView currentRunDetail={run_highlights} runDetailKey="run_highlights"/> : <Flex justify="center"><b>No Data</b></Flex>
            },
            {
                label: 'Run Insights',
                key: 'runInsights',
                children: run_insights && Object.keys(run_insights).length > 0 ? <RunDetailTableView runDetails={run_insights} runDetailKey="run_insights"/> : <Flex justify="center"><b>No Data</b></Flex>
            },
            {
                label: 'Run Time Profile',
                key: 'runTimeProfile',
                children: run_time_profile ? <RunDetailGridView currentRunDetail={run_time_profile} runDetailKey="run_time_profile"/> : <Flex justify="center"><b>No Data</b></Flex>
            },
            {
                label: 'Run Warnings',
                key: 'runWarnings',
                children: run_warnings ? <RunDetailTableView runDetails={run_warnings} runDetailKey="run_warnings"/> : <Flex justify="center"><b>No Warnings</b></Flex>
            },
            {
                label: 'Run Error',
                key: 'runError',
                children: run_highlights && run_highlights.error ? <p>{run_highlights.error}</p> : <Flex justify="center"><b>No Data</b></Flex>
            },
        ])
    }

    async function fetchSchedulerRuns() {
        setLoading(true); // Set loading to true before API call
        const response = await dispatch(getSchedulerRuns());
        setLoading(false); // Set loading to false after API call
        if (response.status && response.data.length > 0) {
            const defaultRunSelected = response.data[0];
            setSelectedRunId([defaultRunSelected._id]);
            setSelectedRun(defaultRunSelected)
            updateTabItems(defaultRunSelected)
        }
    }

    async function reloadRunById(runId) {
        const response = await dispatch(getRunById(runId));
        if (response.status && response.data.length > 0) {
            const defaultRunSelected = response.data[0];
            setSelectedRunId([defaultRunSelected._id]);
            setSelectedRun(defaultRunSelected)
            updateTabItems(defaultRunSelected)
        }
    }

    useEffect(() => {
        fetchSchedulerRuns();
    },[dispatch])

    useEffect(() => {
        if (updateRunPage) {
            reloadRunById(reloadRunId);
            dispatch({type: RUN_NOTIFICATION_COMPLETE});
        }
    }, [updateRunPage, reloadRunId]);

    function onRowSelectionChange(selectedRunId) {
        setSelectedRunId(selectedRunId);
        const selectedId = selectedRunId[0];
        const run = schedulerRuns.find(item => item._id === selectedId);
        setSelectedRun(run);
        updateTabItems(run);
    };

    const columns = [
        {
            title: 'Run Name',
            key: 'run_name',
            render: (text, record) => record.run_name || `${record.run_id}`, 
            sorter: (a, b) => {
                const aValue = a.run_name || a.run_id;
                const bValue = b.run_name || b.run_id;
                return aValue.toString().localeCompare(bValue.toString());
            },
        },
        {
          title: 'Modified Time',
          dataIndex: 'modified_time',
          key: 'modified_time',
          render: (text) => <DateComponent dateValue={text}/>,
          sorter: (a, b) => new Date(a.modified_time) - new Date(b.modified_time),
        },
        {
          title: 'User',
          dataIndex: 'modified_user_id',
          key: 'modified_user_id',
          sorter: (a, b) => a.modified_user_id.toString().localeCompare(b.modified_user_id.toString()),
        },
        {
          title: 'Status',
          dataIndex: 'run_status',
          key: 'run_status',
          render: (text) => showRunStatus(text),
          sorter: (a, b) => a.run_status.localeCompare(b.run_status),
        },
        {
          title: 'Horizon Start Time',
          dataIndex: 'horizon_start_time',
          key: 'horizon_start_time',
          render: (text) => <DateComponent dateValue={text}/>,
          sorter: (a, b) => new Date(a.horizon_start_time) - new Date(b.horizon_start_time),
        },
        {
            title: 'Horizon End Time',
            dataIndex: 'horizon_end_time',
            key: 'horizon_end_time',
            render: (text) => <DateComponent dateValue={text}/>,
            sorter: (a, b) => new Date(a.horizon_end_time) - new Date(b.horizon_end_time),
          },
    ]

    function showRunStatus(status) {
        return (status.toLowerCase() === 'execution completed' ? <Flex align="center"><span className="status-icon success"></span>{status}</Flex> : status.toLowerCase() === 'created' ? <Flex align="center"><span className="status-icon created"></span>{status}</Flex> :  status.toLowerCase() === "error" ? <Flex align="center"><span className="status-icon error"></span>{status}</Flex> : <Flex align="center"><span className="status-icon progress"></span>{status}</Flex>);
    }

    async function onDeleteRun(runId) {
        const response = await dispatch(deleteRun([runId]))
        setShowConfirmation(false);
        if(response.status) {
            message.success('Run Deleted Successfully');
            fetchSchedulerRuns();
        }
        else {
            message.error('Something went wrong');
        }
    }

    async function onExportRun() {
        setExportLoading(true);
        const response = await dispatch(exportRunData(selectedRun.run_id));
        setExportLoading(false);
        if (response.status) {
            const blob = new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
            downloadFile(blob, `Export_run_details_${selectedRun.run_id}.xlsx`, 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet');
            message.success('Run exported successfully');
        } else {
            message.error('Failed to export run');
        }
    }

    async function onImportRun(file) {
        setImportLoading(true);
        const response = await dispatch(importRunData(selectedRun.run_id, file));
        if (response.status) {
            message.success('Run imported successfully');
            await fetchSchedulerRuns();
        } else {
            message.error('Failed to import run');
        }
        setImportLoading(false);
    }

    const handleImportClick = () => {
        const input = document.createElement('input');
        input.type = 'file';
        input.accept = '.xlsx';
        input.onchange = (e) => {
            const file = e.target.files[0];
            if (file) {
                onImportRun(file);
            }
        };
        input.click();
    };

    const handleUploadChange = ({ fileList }) => {
        setFileList(fileList.slice(-1)); // Only keep the latest file
    };

    const moreActionsMenu = (
        <Menu>
            <Menu.Item key="export" icon={<ExportOutlined />} onClick={onExportRun} disabled={exportLoading}>
                Export Run
            </Menu.Item>
            <Menu.Item key="import" icon={<CopyOutlined />} onClick={handleImportClick} disabled={importLoading}>
                Import Run
            </Menu.Item>
        </Menu>
    );

    return (
        loading ? <Spin size="large" rootClassName="main-loader" indicator={<LoadingOutlined spin />} /> :
        (<>
        <Spin spinning={exportLoading || importLoading} size='large' className="loader action-loader" indicator={<LoadingOutlined spin />}>
            <Card
                title="Find Runs"
                className="run-detail-card"
                extra={
                    <>
                    <Flex gap={16} align="center">
                        <Dropdown menu={{items}} trigger={['click']}>
                            <div className="filter-dropdown">
                                <Space className="filter-container">
                                    <div className="filter-label">Filter</div>
                                    <DownOutlined className="icon-down-caret" />
                                </Space>
                            </div>
                        </Dropdown>
                        <Flex gap={8} align="center">
                                <Button icon={<DeleteOutlined />} onClick={() => setShowConfirmation(true)}>Delete</Button>
                                <Button icon={<PlusOutlined />} onClick={onAddNewRun}>Add New</Button>
                                <Button type="primary">Compare</Button>
                        </Flex>
                        <Flex gap={12} align="center">
                            <Tooltip title="Refresh"><ReloadOutlined className="action-icon"/></Tooltip>
                            <Tooltip title="Settings"><SettingOutlined className="action-icon"/></Tooltip>
                            <Tooltip title="Maximize"><FullscreenOutlined className="action-icon"/></Tooltip>
                        </Flex>
                    </Flex>
                    </>
                }
            >
                    <Table
                        className="list-detail-table"
                        columns={columns} 
                        dataSource={schedulerRuns} 
                        pagination={{defaultPageSize: 5, showSizeChanger: true,pageSizeOptions: ['5','10','20'],}}
                        rowSelection={
                            {
                                type: 'radio',
                                onChange: onRowSelectionChange,
                                selectedRowKeys: selectedRunId,
                                columnWidth: 48,
                            }
                        }
                        onRow={(record) => ({
                            onClick: () => {
                                onRowSelectionChange([record._id]);
                            },
                            onDoubleClick: () => {
                                navigate(`/${siteCode}/scheduler/rundetails/${record.run_id}`);
                            },
                        })}
                        rowKey="_id"
                        scroll={{
                            y: 215,
                        }}
                    />
            </Card>
            {
                selectedRun && (
                    <Card 
                        title="Run Details" 
                        className="run-info run-detail-card"
                        extra={
                            <Flex gap={16}>
                                <Button icon={<CopyOutlined/>} onClick={onDuplicateRun}>Clone</Button>
                                <Link to={`/${siteCode}/scheduler/rundetails/${selectedRun.run_id}`}><Button icon={<FolderOpenOutlined/>} type="primary">Open</Button></Link>
                                <Dropdown overlay={moreActionsMenu} trigger={['click']} visible={moreActionsVisible} onVisibleChange={setMoreActionsVisible}>
                                    <Tooltip title="More Actions"><MoreOutlined className="action-icon" /></Tooltip>
                                </Dropdown>
                            </Flex>
                        }
                    >
                        {tabItems.length > 0 &&
                            <Tabs
                                defaultActiveKey="runDetails"
                                type="card"
                                size="small"
                                items={tabItems}
                                onChange={(key) => setActiveTab(key)}
                                className="run-detail-tabs"
                            />
                        }
                        <Modal
                            title="Delete Confirmation"
                            open={showConfirmation}
                            onOk={() => onDeleteRun(selectedRun.run_id)}
                            onCancel={() => setShowConfirmation(false)}
                            okText="Yes, Delete"
                            cancelText="Cancel"
                            okType="danger"
                        >
                            <p>Are you sure want to delete the run <b><i>{selectedRun.run_name}</i></b> ?</p>
                        </Modal>
                        
                    </Card>
                )
            }
            <RunModal
                visible={runModalVisible}
                title={runModalTitle}
                loading={runModalLoading}
                runName={runName}
                setRunName={setRunName}
                isClone={isClone}
                fileList={fileList}
                setFileList={setFileList}
                handleUploadChange={handleUploadChange}
                handleOk={handleRunModalOk}
                handleCancel={handleRunModalCancel}
            />
        </Spin>
        </>)
    )
}

export default FindRuns;