import { AreaChart, Area, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts';

function AreaChartComponent(props) {
    const {data, properties, showAxes=true} = props;

    return (
        <ResponsiveContainer width="100%" height="100%" className={!showAxes && `chart-container`}>
          <AreaChart
            data={data}
            margin={{
              top: 10,
              right: 30,
              left: 0,
              bottom: 40,
            }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis hide={!showAxes} dataKey={properties.Axes.x.column} />
            <YAxis hide={!showAxes} dataKey={properties.Axes.y.column}/>
            <Tooltip />
            <Area type="monotone" dataKey={properties.Axes.y.column} stroke={properties.Color} fill={properties.Color} />
          </AreaChart>
        </ResponsiveContainer>
      );
}

export default AreaChartComponent;