import React, { useState, useEffect } from "react";
import { Badge } from 'antd';
import { BellOutlined } from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';
import { getNotifications, markAsRead, clearNotifications, USER_NOTIFICATION_COMPLETE } from '../../../redux/actions/UserAction';
import NotificationDrawer from './NotificationDrawer';
import DateComponent from "../../common/DateComponent";
import { Link, useParams } from "react-router-dom";
import { useTranslation } from 'react-i18next';


function NotificationBell() {
    const { t, i18n } = useTranslation("notification");
    const {siteCode} = useParams();
    const newNotifications = useSelector(state => state.user.newNotifications); // Changed from newNotification to newNotifications
    const [drawerVisible, setDrawerVisible] = useState(false);
    const [notifications, setNotifications] = useState([]);
    const [unReadCount, setUnReadCount] = useState(0);
    const dispatch = useDispatch();

    const getRunTemplate = (notificationType, runName) => {
        switch (notificationType) {
            case 0:
                return t('runStarted', { runName });
            case 1:
                return t('runSolveStarted', { runName });
            case 2:
                return t('runFailed', { runName });
            case 3:
                return t('runCompleted', { runName });
            case 4:
                return t('runCompareStarted', { runName });
            case 5:
                return t('runCompareCompleted', { runName });
            case 6:
                return t('runCompareFailed', { runName });
            case 7:
                return t('runReadingInputs', { runName });
            case 8:
                return t('runBuildingModel', { runName });
            case 9:
                return t('runSolveCompleted', { runName });
            case 10:
                return t('executionCompleted', { runName });
            case 11:
                return t('invitedToTeam');
            default:
                return t('default', { runName });
        }
    };

    const getStatus = (notificationType) => {
        switch (notificationType) {
            case 0:
            case 1:
            case 4:
            case 7:
            case 8:
                return "info";
            case 2:
            case 6:
                return "danger";
            case 3:
            case 5:
            case 9:
            case 10:
                return "success";
            case 11:
                return "warning";
            default:
                return "info";
        }
    };

    const mapNotifications = (notifications) => {
        let unReadcount = 0;
        const data = notifications.map((notification) => {
            const content = JSON.parse(notification.content);

            if (!notification.isRead) {
                unReadcount += 1;
            }

            return {
                id: notification.id,
                title: notification.type === 1 ? t('titleRunUpdate') : t('titleNotification'),
                description: <span>{getRunTemplate(notification.notificationType, content.run_name)}</span>,
                status: getStatus(notification.notificationType),
                read: notification.isRead,
                link: notification.type === 1 ? <Link to={`${siteCode}/scheduler/rundetails/${content.run_id}`}>View Run</Link> : null,
                date: notification.createdAt, // Ensure correct date property is used
            };
        });

        return {data, unReadcount};
    }

    useEffect(() => {
        async function fetchNotifications() {
            const result = await dispatch(getNotifications());
            if (result.status) {
                const mappedNotifications = mapNotifications(result.data);
                setNotifications(mappedNotifications.data);
                setUnReadCount(mappedNotifications.unReadcount);
            }
        }
        fetchNotifications();
    }, [dispatch, i18n.language]);

    useEffect(() => {
        if(newNotifications.length > 0) {
            const newNotificationIds = newNotifications.map(notification => notification.id);
            setNotifications((prevNotifications) => {
                const newNotificationsData = newNotifications.map(notification => {
                    const content = JSON.parse(notification.content);
                    return {
                        id: notification.id,
                        title: notification.type === 1 ? t('titleRunUpdate') : t('titleNotification'),
                        description: <span>{getRunTemplate(notification.notificationType, content.run_name)}</span>,
                        status: getStatus(notification.notificationType),
                        read: notification.isRead,
                        link: notification.type === 1 ? <Link to={`${siteCode}/scheduler/rundetails/${content.run_id}`}>View Run</Link> : null,
                        date: notification.createdAt, // Ensure correct date property is used
                    };
                });
                return [...newNotificationsData, ...prevNotifications];
            });
            setUnReadCount((prevCount) => prevCount + newNotifications.length);
            dispatch({type: USER_NOTIFICATION_COMPLETE, payload: newNotificationIds}); // Pass the IDs of the processed notifications
        }
    }, [newNotifications]);

    async function toggleNotifications() {
        setDrawerVisible(!drawerVisible);
        if (!drawerVisible) {
            const unreadNotifications = notifications.filter(notification => !notification.read);
            if (unreadNotifications.length > 0) {
                const unreadIds = unreadNotifications.map(notification => notification.id);
                if(unreadIds.length === 0) {
                     return;
                 }
                const result = await dispatch(markAsRead(unreadIds));
                if (result.status) {
                    setNotifications((prevNotifications) => 
                        prevNotifications.map(notification => 
                            unreadIds.includes(notification.id) ? { ...notification, read: true } : notification
                        )
                    );
                    setUnReadCount(0);
                }
            }
        }
    }

    return (
        <>
            <Badge count={unReadCount} overflowCount={10} size="small" className="header-icon icon-top count-badge" onClick={toggleNotifications}>
                <BellOutlined className="icon-bell"/>
            </Badge>
            {drawerVisible && (
                <NotificationDrawer visible={drawerVisible} onClose={toggleNotifications} notifications={notifications} setUnReadCount={setUnReadCount} setNotifications={setNotifications} />
            )}
        </>
    );
}

export default NotificationBell;