import React, { useEffect, useState } from 'react';
import './login.scss';
import { Flex, Spin, Form, Input, Button, message as antMessage } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import apiClient from '../../ApiClient';
import { PhoneInput } from 'react-international-phone';
import './accept-invite.scss';

const AcceptInviteComponent = () => {
    // State management
    const [state, setState] = useState({
        isVerifying: true,
        isError: false,
        showPasswordForm: false,
        message: `Hang tight! We're validating your email right now. Please wait just a moment!`
    });

    const [phone, setPhone] = useState('');

    const INVITE_MESSAGES = {
        ALREADY_ACCEPTED: "User invite has already accepted.",
        EXPIRED: "User invite has expired."
    };

    // Form and URL handling
    const [form] = Form.useForm();
    const urlParams = new URLSearchParams(new URL(window.location.href).search);
    const token = urlParams.get('token');
    const email = urlParams.get('email');

    // Password submission handler
    const handlePasswordSubmission = async (values) => {
        try {
            const response = await apiClient.post('auth/accept-invite', {
                token,
                email,
                ...values,
            });

            if (response.data.status === 200) {
                antMessage.success('Password set successfully');
                window.location.href = '/login';
            } else {
                antMessage.error('Failed to set password. Please try again.');
            }
        } catch (error) {
            antMessage.error('Failed to set password. Please try again.');
        }
    };

    // Verification handlers
    const handleSuccessfulVerification = (forcePassword) => {
        const message = !forcePassword ? "Your email has been verified successfully." : "Set Your Account Password";
        setState(prev => ({
            ...prev,
            isError: false,
            message,
            showPasswordForm: forcePassword,
            isVerifying: false
        }));
    };

    const handleAlreadyAccepted = (isExpired) => {
        setState(prev => ({
            ...prev,
            showPasswordForm: false,
            isError: isExpired ? true : false,
            message: isExpired ? "This invitation has expired and is no longer valid. Please reach out to your administrator for assistance." 
            : "This invite has already been accepted.",
            isVerifying: false
        }));
    };

    const handleFailedVerification = () => {
        setState(prev => ({
            ...prev,
            isError: true,
            showPasswordForm: false,
            message: "Oops! Email verification failed. Please reach out to your administrator for assistance.",
            isVerifying: false
        }));
    };

    // Email verification effect
    useEffect(() => {
        const verifyInvite = async () => {
            try {
                const response = await apiClient.post('auth/verify-invite', { token, email });
                const { status, message: responseMessage, forcePassword: forcePassword } = response.data;

                if (status === 200) {
                    handleSuccessfulVerification(forcePassword);
                } else if ([INVITE_MESSAGES.ALREADY_ACCEPTED, INVITE_MESSAGES.EXPIRED].includes(responseMessage)) {
                    const isExpired = responseMessage === INVITE_MESSAGES.EXPIRED;
                    handleAlreadyAccepted(isExpired);
                } else {
                    handleFailedVerification();
                }
            } catch (error) {
                handleFailedVerification();
            }
        };

        verifyInvite();
    }, [INVITE_MESSAGES.ALREADY_ACCEPTED,  INVITE_MESSAGES.EXPIRED, email, token]);

    // UI Components
    const PasswordForm = () => (
        <Form
            form={form}
            name="passwordSetup"
            onFinish={handlePasswordSubmission}
            layout="vertical"
            style={{ width: '100%', maxWidth: '300px' }}
        >
            <Form.Item
                name="firstName"
                label="First Name"
                rules={[
                    { required: true, message: 'Please enter your firstname' }
                ]}
            >
                <Input />
            </Form.Item>
            <Form.Item
                name="lastName"
                label="Last Name"
                rules={[
                    { required: true, message: 'Please enter your lastname' }
                ]}
            >
                <Input />
            </Form.Item>
            <Form.Item
                name="phoneNumber"
                rules={[
                    { required: true, message: 'Please enter your phone number!' },
                ]}
                className='form-item'
            >
                <PhoneInput
                    defaultCountry="us"
                    value={phone}
                    className='phone-input'
                />
            </Form.Item>
            <Form.Item
                name="password"
                label="Password"
                rules={[
                    { required: true, message: 'Please input your password!' },
                    { min: 8, message: 'Password must be at least 8 characters' }
                ]}
                hasFeedback
            >
                <Input.Password />
            </Form.Item>

            <Form.Item
                name="confirmPassword"
                label="Confirm Password"
                dependencies={['password']}
                rules={[
                    { required: true, message: 'Please confirm your password!' },
                    ({ getFieldValue }) => ({
                        validator(_, value) {
                            if (!value || getFieldValue('password') === value) {
                                return Promise.resolve();
                            }
                            return Promise.reject('The passwords do not match!');
                        },
                    }),
                ]}
                hasFeedback
            >
                <Input.Password />
            </Form.Item>

            <Form.Item>
                <Button type="primary" htmlType="submit" style={{ width: '100%' }}>
                    Set Password
                </Button>
            </Form.Item>
        </Form>
    );

    const Message = () => (
        state.message ? 
            <p className='login-msg' style={{ textAlign: 'center' }}>
                {state.message}
            </p> : null
    );

    const Content = () => {
        if (state.isVerifying) {
            return <Spin indicator={<LoadingOutlined style={{ fontSize: 48 }} spin />} />;
        }

        if (state.isError) {
            return null;
        }

        if (state.showPasswordForm) {
            return <PasswordForm />;
        }

        return (
            <p className='login-msg' style={{ textAlign: 'center' }}>
                Please click here to <Link to={'/login'}>Login</Link>
            </p>
        );
    };

    return (
        <Flex justify="space-around" className='login-container'>
                <Flex className="form-container" vertical>
                    <Message />
                    <Content />
                </Flex>
        </Flex>
    );
};

export default AcceptInviteComponent;