import React, { useState, useEffect, useCallback, startTransition } from 'react';
import { Table, Card, Flex, Button, Typography, Tag, Modal, Form, Input, Select, Dropdown, message, Spin } from 'antd';
import { PlusOutlined, ReloadOutlined, SettingOutlined, DeleteOutlined, EditOutlined, LoadingOutlined } from '@ant-design/icons'; // Import DeleteOutlined and EditOutlined
import './manage-team.scss';
import { useTranslation } from 'react-i18next'; // Import useTranslation
import { formatDate } from '../../util';
import { useDispatch, useSelector } from 'react-redux';
import { createTeam, getTeams, getUserList, deleteTeam, updateTeam } from '../../redux/actions/UserAction'; // Import getAllUsers and updateTeam actions
import MemberDetails from './member-detail'; // Import MemberDetails component
import DateComponent from '../common/DateComponent';
const { Title } = Typography;

const ManageTeams = () => {
    const { t, i18n } = useTranslation('teams', { useSuspense: false }); // Initialize useTranslation with 'teams' namespace

    const [teams, setTeams] = useState([]);
    const [pageSize, setPageSize] = useState(5);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalCount, setTotalCount] = useState(0);
    const [open, setOpen] = useState(false);
    const [confirmLoading, setConfirmLoading] = useState(false);
    const [form] = Form.useForm();
    const [columns, setColumns] = useState([]);
    const [selectedTeam, setSelectedTeam] = useState(null);
    const [isRefreshing, setIsRefreshing] = useState(false);
    const currentSite = useSelector((state) => state.site.currentSite);
    const [fetchedCount, setFetchedCount] = useState(false);
    const dispatch = useDispatch();
    const [users, setUsers] = useState([]);
    const [isEditMode, setIsEditMode] = useState(false); // Add state to track if the modal is in edit mode

    const handleDeleteTeam = async () => {
        try {
            const response = await dispatch(deleteTeam(selectedTeam.id));
            if (response.status) {
                message.success(t('teamDeleted'));
                // Add any additional logic needed after successful deletion
                handleManualRefresh();
                setSelectedTeam(null);
            } else {
                message.error(t('deleteFailed'));
            }
        } catch (error) {
            console.error('Delete team failed:', error);
            message.error(t('deleteFailed'));
        }
    };

    const handleEditTeam = async () => {
        try {
            const formValues = await form.validateFields();
            const request = {
                id: selectedTeam.id,
                name: formValues.name,
                description: formValues.description,
            };
            const response = await dispatch(updateTeam(selectedTeam.id, request));
            if (response.status) {
                message.success(t('teamUpdated'));
                handleManualRefresh();
            } else {
                message.error(t('updateFailed'));
            }
        } catch (error) {
            console.error('Update team failed:', error);
            message.error(t('updateFailed'));
        } finally {
            setOpen(false);
            setConfirmLoading(false);
            form.resetFields();
        }
    };

    const handleAddingUser = (userCount, teamId) => {
        // setTeams(prevTeams => 
        //     prevTeams.map(team => 
        //         team.id === teamId ? { ...team, size: userCount } : team
        //     )
        // );
        handleManualRefresh();
    };

    function renderColumns() {
        const tableColumns = [
            {
                title: t('name'), // Use localized value
                key: 'name',
                render: (status) => (
                    <Flex vertical="true" className='team-display-section'>
                        <span className='team-name'>{status.name}</span>
                    </Flex>
                ),
            },
            {
                title: t('description'), // Use localized value
                key: 'description',
                dataIndex: 'description',
                ellipsis: true,
                // render: (description) => (
                //     <Flex vertical="true" className='team-display-section'>
                //         <span>{description}</span>
                //     </Flex>
                // ),
            },
            {
                title: t('size'), // Use localized value
                key: 'size',
                dataIndex: 'size',
                render: (size) => (
                    <Flex vertical="true" className='team-display-section'>
                        <span>{size}</span>
                    </Flex>
                ),
            },
            {
                title: t('dateAdded'), // Use localized value
                key: 'createdDt',
                dataIndex: 'createdDt',
                render: (createdDt) => (
                    <Flex gap="4px 0" wrap>
                        <span>{<DateComponent dateValue={createdDt} />}</span>
                    </Flex>
                ),
                sorter: (a, b) => new Date(a.created) - new Date(b.created),
            },
            {
                title: t('addedBy'), // Use localized value
                key: 'createdBy',
                dataIndex: 'createdBy',
                render: (createdBy) => (
                    <Flex vertical="true" className='team-display-section'>
                        <span>{createdBy}</span>
                    </Flex>
                ),
            },
            {
                title: t('modifiedDt'), // Use localized value
                key: 'modifiedDt',
                dataIndex: 'modifiedDt',
                render: (modifiedDt) => (
                    <Flex gap="4px 0" wrap>
                        <span>{<DateComponent dateValue={modifiedDt} />}</span>
                    </Flex>
                ),
                sorter: (a, b) => new Date(a.created) - new Date(b.created),
            },
            {
                title: t('modifiedBy'), // Use localized value
                key: 'modifiedBy',
                dataIndex: 'modifiedBy',
                render: (modifiedBy) => (
                    <Flex vertical="true" className='team-display-section'>
                        <span>{modifiedBy}</span>
                    </Flex>
                ),
            }
        ];
        setColumns(tableColumns);
    }

    const fetchTeamList = async () => {
        setIsRefreshing(true);
        const siteId = currentSite ? currentSite.id : null;
        if (!siteId) {
            setIsRefreshing(false);
            return;
        }

        const filterInfo = {
            offset: currentPage - 1,
            limit: pageSize,
            requiredTotal: !fetchedCount
        };

        try {
            const response = await dispatch(getTeams(filterInfo));
            if (response.data && response.data.records) {
                setTeams(response.data.records);
                if (!fetchedCount) {
                    setTotalCount(response.data.total);
                    setFetchedCount(true);
                }
            }
        } catch (error) {
            console.error('Failed to fetch user list:', error);
        } finally {
            setIsRefreshing(false);
        }
    };

    const fetchAllUsers = async () => {
        try {
            const response = await dispatch(getUserList({onlyActive: true}));
            if (response.data && response.data.userList) {
                setUsers(response.data.userList);
            }
        } catch (error) {
            console.error('Failed to fetch users:', error);
        }
    };

    useEffect(() => {
        renderColumns();
    }, [t, i18n.language]);

    useEffect(() => {
        if (currentSite) {  // Only fetch if sites data is available
            fetchTeamList();
        }
    }, [currentPage, currentSite]);

    useEffect(() => {
        startTransition(() => {
            fetchAllUsers();
        });
    }, []);

    function handlePaginationChange(page, size) {
        setPageSize(size);
        setCurrentPage(page);
    }

    function onRowSelectionChange(selectedRowKeys, selectedRows) {
        setSelectedTeam(teams.find(x => x.id === selectedRowKeys[0]));
    }

    function onRowClick(record) {
        setSelectedTeam(record);
    }

    const showModal = (isEdit = false) => {
        form.resetFields();
        setOpen(true);
        setIsEditMode(isEdit);
        if (isEdit && selectedTeam) {
            form.setFieldsValue({
                name: selectedTeam.name,
                description: selectedTeam.description,
            });
        }
    };

    const handleOk = async () => {
        if (isEditMode) {
            await handleEditTeam();
        } else {
            try {
                setConfirmLoading(true);
                const formValues = await form.validateFields();

                const request = {
                    name: formValues.name,
                    description: formValues.description,
                };

                let response = await dispatch(createTeam(request));
                if (response.status && (response.data && response.data.success)) {
                    message.success(`Invite has been sent to ${request.userEmail}`);
                    handleManualRefresh();
                }
                else {
                    const messageText = response.data && response.data.message
                        ? response.data.message : `Oops!!! User invite action failed. Please try after sometime.`;
                    message.error(messageText);
                }

            } catch (error) {
                console.error('Validation failed:', error);
            } finally {
                setOpen(false);
                setConfirmLoading(false);
                form.resetFields();
            }
        }
    };

    // Separate function for manual refresh
    const handleManualRefresh = useCallback(() => {
        setFetchedCount(false);
        setSelectedTeam(null);  
        startTransition(() => {
            fetchTeamList();
        });
    }, [fetchTeamList]);

    const handleCancel = () => {
        setOpen(false);
    };

    const TeamForm = ({ translation, onFinish, form }) => {
        return (
            <Form
                form={form}
                layout="vertical"
                onFinish={onFinish}
            >
                <Form.Item
                    name="name"
                    label={translation('form.name')} // Use localized value
                    rules={[{ required: true, message: translation('form.rules.nameRequired') }]} // Use localized rule
                >
                    <Input placeholder="Enter team name" />
                </Form.Item>

                <Form.Item
                    name="description"
                    label={translation('form.description')} // Use localized value
                    rules={[{ required: true, message: translation('form.rules.descriptionRequired') }]} // Use localized rule
                >
                    <Input.TextArea showCount placeholder="Enter description" maxLength={200} style={{ height: 120, resize: 'none' }} />
                </Form.Item>
            </Form>
        );
    };

    const spinLoader = <Spin rootClassName='main-loader' indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} />;


    return (
        <>
            <Flex gap={16} vertical>
                <Card className='manage-team-sections'>
                    <Flex justify='space-between' className='header-section' align='center'>
                        <Title className='header' level={5}>{t('allTeams')}</Title> {/* Use localized value */}
                        <Flex gap="small">
                            <Button>{t('filter')}</Button> {/* Use localized value */}
                            <Dropdown
                                menu={{
                                    items: [], // No action items
                                    disabled: isRefreshing
                                }}
                                placement="bottomLeft"
                                arrow
                                trigger={['click']}
                                disabled={!selectedTeam}
                            >
                                <div>
                                    <Button disabled={!selectedTeam}>{t('actions')}</Button> {/* Use localized value */}
                                </div>
                            </Dropdown>
                            <Button type="primary" icon={<PlusOutlined />} onClick={() => showModal(false)}>{t('addTeam')}</Button> {/* Use localized value */}
                            <Button
                                icon={<ReloadOutlined />}
                                onClick={handleManualRefresh} // No refresh logic
                            />
                            <Button
                                icon={<SettingOutlined />}
                            />
                        </Flex>
                    </Flex>
                    <Table
                        className='list-detail-table'
                        bordered={true}
                        columns={columns}
                        dataSource={teams}
                        loading={{rootClassName:"main-loader", size:"large", indicator: spinLoader, spinning: isRefreshing}}
                        locale={{ emptyText: t('noData') }} // Use localized value for empty data
                        pagination={{ defaultPageSize: 5, onChange: handlePaginationChange, total: totalCount, showSizeChanger: true, pageSizeOptions: ['5', '10', '20'], }}
                        rowKey="id"
                        onRow={(record) => ({
                            onClick: () => onRowClick(record),
                        })}
                        rowSelection={{
                            type: 'radio',
                            onChange: onRowSelectionChange,
                            selectedRowKeys: selectedTeam ? [selectedTeam.id] : [],
                            columnWidth: 48,
                        }}
                        scroll={{
                            y: 550,
                        }}
                    />
                </Card>

                {selectedTeam && (
                    <MemberDetails
                        selectedTeam={selectedTeam}
                        handleAddingUser={handleAddingUser}
                        handleEditTeam={() => showModal(true)} // Pass the method as a prop
                        handleDeleteTeam={handleDeleteTeam} // Pass the method as a prop
                        users={users}
                    />
                )}
            </Flex>

            <Modal
                title={isEditMode ? t('editTeam') : t('addTeam')} // Use localized value
                open={open}
                onOk={handleOk}
                confirmLoading={confirmLoading}
                onCancel={handleCancel}
            >
                <TeamForm
                    translation={t}
                    onFinish={handleOk}
                    form={form}
                />
            </Modal>
        </>
    );
};

export default ManageTeams;