import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Navigate } from 'react-router-dom';
import authToken from './AuthToken';
import { message, Spin } from 'antd';
import { getCurrentUser } from './redux/actions/UserAction';
import UnauthorizedComponent from './components/unauthorized/UnauthorizedComponent';
import { LoadingOutlined } from "@ant-design/icons";

function AuthGuard({ children }) {
  const isAuthenticated = authToken.getItem('access-token');
  const user = useSelector(state => state.user.user);
  const dispatch = useDispatch();

  useEffect(() => {
      if (isAuthenticated && !user) {
          dispatch(getCurrentUser());
      }
  }, [isAuthenticated, user, dispatch]);

  useEffect(() => {
    if (!isAuthenticated) {
      message.error("You have been signed out kindly login again");
    }
  }, [isAuthenticated]);

  if (!isAuthenticated) {
    return <Navigate to='/login' />;
  }
  else {
    return children;
  }
};

const PermissionGuard = ({ children, requiredPermission }) => {
    const permissions = useSelector(state => state.user.permissions);
    if(!permissions || permissions.length === 0) {
      return <Spin rootClassName='main-loader' indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} />;
    }

    const hasPermission = permissions.some(permission => 
      permission.feature === requiredPermission && permission.canView && permission.canEdit
  );

    return hasPermission ? children : <UnauthorizedComponent />;
};

export { AuthGuard, PermissionGuard };
