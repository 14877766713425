import React, { useEffect } from 'react';
import * as signalR from '@microsoft/signalr';
import { message, notification } from 'antd';
import authToken from "../../AuthToken";
import { RUN_NOTIFICATION_RECEIVED, COMPARE_RUN_NOTIFICATION_RECEIVED } from "../../redux/actions/SchedulerRunActions";
import { useDispatch } from 'react-redux';
import { apiURL } from '../../ApiClient';
import { USER_NOTIFICATION_RECEIVED } from '../../redux/actions/UserAction';

 
function NotificationComponent() {

    const [api, contextHolder] = notification.useNotification();
    const token = authToken.getItem('access-token');
    const dispatch = useDispatch();
    
    function handleNotification(notification) {
        if(typeof(notification) === "object") {
            if(notification.type === 1) {
                let content = (JSON.parse(notification.content))?.content;
                if(content.type === "ANALYZE_DIFFERENCES") {
                    dispatch({ type: COMPARE_RUN_NOTIFICATION_RECEIVED, payload: {status: content.message}})
                    message.info(`Compare Run Update: ${content.message}`);
                }
                else 
                {
                    dispatch({ type: RUN_NOTIFICATION_RECEIVED, payload: {run_id : content.run_id } });
                    message.info(`Run Update: ${content.message}`);
                }

                dispatch({ type: USER_NOTIFICATION_RECEIVED, payload: notification });
            }
        }
        else {
            message.info(`New message received!!!`);
        }
    }

    useEffect(() => {
        // Setup SignalR connection
        const connection = new signalR.HubConnectionBuilder()
        .withUrl(`${apiURL}/notification-hub`, { withCredentials: true ,headers: {Authorization:  `Bearer ${token}`}}) // Use your hub URL here
        .withAutomaticReconnect()
        .build();

        // Start the connection
        connection.start()
            .then(() => { console.log("Connected to SignalR Hub"); })
            .catch(err => console.error("SignalR Connection Error: ", err));

        // Listen for incoming messages from SignalR
        connection.on("ReceiveNotification", (message) => {
            // Trigger Ant Design notification
            handleNotification(message);
        });

        // Cleanup on component unmount
        return () => {
            connection.stop().then(() => console.log("SignalR connection closed"));
        };
    }, [token]);

    return (
        <>
         {contextHolder}
         </>
    );
};

export default NotificationComponent;
