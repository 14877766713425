
import { selectSite } from '../redux/actions/SiteActions';
import authToken from '../AuthToken';

export const updateAuthToken = async (siteId) => {
    let response = await selectSite(siteId);
    if (response.status) {
        await authToken.setAuthToken(response.data);
        return {status:true, user: response.user};
    } else {
        console.error('Error:', response.error);
        return false;
    }
};