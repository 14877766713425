import apiClient from "../../ApiClient";

export const SET_USER = 'SET_USER';
export const SET_PERMISSIONS = 'SET_PERMISSIONS';
export const USER_NOTIFICATION_RECEIVED = 'USER_NOTIFICATION_RECEIVED';
export const USER_NOTIFICATION_COMPLETE = 'USER_NOTIFICATION_COMPLETE';
export const USER_LOGOUT = 'USER_LOGOUT';
export const RESET = 'RESET';

export const getUserList = (request) => async (dispatch) => {
    try {
      const response = await apiClient.post(`/user/list`, request);
      return {status:true, data: response.data.data};
    } catch (error) {
      return {status:false, error: error.response};
    }
};

export const getCurrentUser = () => async (dispatch) => {
  try {
    const response = await apiClient.get(`/user/current`);
    dispatch({ type: SET_USER, payload: response.data });
    return {status:true, data: response.data};
  } catch (error) {
    return {status:false, error: error.response};
  }
};

export const deactivateUser = (request) => async (dispatch) => {
  try {
    const response = await apiClient.put(`/user/deactivate`, request);
    return {status:true, data: response.data};
  } catch (error) {
    return {status:false, error: error.response};
  }
};

export const activateUser = (request) => async (dispatch) => {
  try {
    const response = await apiClient.put(`/user/activate`, request);
    return { status:true, data: response.data };
  } catch (error) {
    return {status:false, error: error.response};
  }
};

export const getRoles = () => async (dispatch) => {
  try {
    const response = await apiClient.get(`/user/roles`);
    return {status:true, data: response.data};
  } catch (error) {
    return {status:false, error: error};
  }
};

export const inviteUser = (request) => async (dispatch) => {
  try {
    const response = await apiClient.post(`/user/invite-user`, request);
    return {status:true, data: response.data};
  } catch (error) {
    return {status:false, error: error};
  }
}

export const getTeams = (request) => async (dispatch) => {
  try {
    const response = await apiClient.post(`/team/list-teams`, request);
    return {status:true, data: response.data.data};
  } catch (error) {
    return {status:false, error: error};
  }
}

export const createTeam = (request) => async (dispatch) => {
  try {
    const response = await apiClient.post(`/team`, request);
    return {status:true, data: response.data};
  } catch (error) {
    return {status:false, error: error};
  }
}

export const getTeamRoles = () => async (dispatch) => {
  try {
    const response = await apiClient.get(`/team/roles`);
    return { status: true, data: response.data };
  } catch (error) {
    return { status: false, error: error.response };
  }
};

export const addMembersToTeam = (teamId, members) => async (dispatch) => {
  try {
    const response = await apiClient.post(`/team/add-members/${teamId}`, members);
    return { status: true, data: response.data };
  } catch (error) {
    return { status: false, error: error.response };
  }
};

export const getTeamMembers = (teamId) => async (dispatch) => {
  try {
    const response = await apiClient.post(`/team/team-members/${teamId}`);
    return { status: true, data: response.data };
  } catch (error) {
    return { status: false, error: error.response };
  }
};

export const deleteMemberFromTeam = (teamId, members) => async (dispatch) => {
  try {
    const response = await apiClient.post(`/team/team-members/remove`, { members, teamId });
    return { status: response.data.status === 200, data: response.data };
  } catch (error) {
    return { status: false, error: error.response };
  }
};

export const deleteTeam = (teamId) => async (dispatch) => {
  try {
    const response = await apiClient.delete(`/team/${teamId}`);
    return { status: true, data: response.data };
  } catch (error) {
    return { status: false, error: error.response };
  }
};

export const updateTeam = (teamId, request) => async (dispatch) => {
  try {
    const response = await apiClient.put(`/team`, request);
    return { status: true, data: response.data };
  } catch (error) {
    return { status: false, error: error.response };
  }
};

export const getUserProfile = () => async (dispatch) => {
  try {
    const response = await apiClient.get(`/user/profile`);
    return { status: true, data: response.data.user };
  } catch (error) {
    return { status: false, error: error.response };
  }
};

export const updateUserProfile = (profile) => async (dispatch) => {
  try {
    const response = await apiClient.put(`/user/update-user-profile`, profile);
    dispatch({ type: SET_USER, payload: response.data.user }); // Dispatch SET_USER action
    return { status: true, data: response.data };
  } catch (error) {
    return { status: false, error: error.response };
  }
};

export const changePassword = (request) => async (dispatch) => {
  try {
    const response = await apiClient.post(`/user/change-password`, request);
    return { status: response.data.success, data: response.data };
  } catch (error) {
    return { 
      status: false, 
      error: error.response?.data?.errors || error.response 
    };
  }
};

export const getProfileImage = () => async (dispatch) => {
  try {
    const response = await apiClient.get(`/user/profile-image`);
    return { status: true, data: response.data };
  } catch (error) {
    return { status: false, error: error.response };
  }
};

export const getNotifications = () => async (dispatch) => {
  try {
    const response = await apiClient.get(`/notification`);
    return { status: true, data: response.data };
  } catch (error) {
    return { status: false, error: error.response };
  }
};

export const markAsRead = (notificationIds) => async (dispatch) => {
  try {
    const response = await apiClient.post(`/notification/mark-as-read`, notificationIds);
    return { status: true, data: response.data };
  } catch (error) {
    return { status: false, error: error.response };
  }
};

export const clearNotifications = () => async (dispatch) => {
  try {
    const response = await apiClient.post(`/notification/clear`);
    return { status: true, data: response.data };
  } catch (error) {
    return { status: false, error: error.response };
  }
};

export const clearNotification = (notificationId) => async (dispatch) => {
  try {
    const response = await apiClient.delete(`/notification/clear/${notificationId}`);
    return { status: true, data: response.data };
  } catch (error) {
    return { status: false, error: error.response };
  }
};

