import { Button, Card, Result } from 'antd';
import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next'; // Import useTranslation

const UnauthorizedComponent = () => {
    const { t } = useTranslation('common'); // Initialize useTranslation with 'common' namespace

    return (
        <Card >
            <Result
                status="403"
                title={t('unauthorized.title')} // Use localized value
                subTitle={t('unauthorized.subTitle')} // Use localized value
                extra={<Button type="primary"><Link to="/">{t('unauthorized.backHome')}</Link></Button>} // Use localized value
            />
        </Card>
    );
};

export default UnauthorizedComponent;