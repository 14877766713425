import React, {useState} from 'react';
import './login.scss';
import { LockOutlined, UserOutlined } from '@ant-design/icons';
import { Button, Form, Input, Flex, Checkbox } from 'antd';
import apiClient from '../../ApiClient';
import { useNavigate } from 'react-router-dom';
import authToken from '../../AuthToken';
import { App } from 'antd';
import { useDispatch } from 'react-redux';

export default function LoginComponent() {
    const navigate = useNavigate();
    const [loader,setLoader] = useState(false);
    const { message } = App.useApp();
    const dispatch = useDispatch();

    async function onFinish(values) {
        try {
            setLoader(true);
            const response = await apiClient.post('/auth/login', values);
            if (response.data && response.data.status === 200) {
                const { email, firstName, lastName } = response.data.data;
                authToken.setItem("access-token",response.data.token);
                navigate("/");

            } else {
                message.error(response.data && response.data.message ? response.data.message : 'Sign up failed. Please try again.');
            }
        } catch (error) {
            message.error('Sign-in failed. Please try again.');
            console.error('Error:', error);
        } finally {
            setLoader(false);
        }
    };

    return (
        <div className='login-container'>
            <Flex className="form-container" vertical>
                <Form
                    size='large'
                    name="login"
                    className="login-form"
                    initialValues={{ RememberMe: false }}
                    onFinish={onFinish}
                >
                    <Form.Item
                        className='form-field'
                        name="email"
                        rules={[
                            {
                                type: 'email',
                                message: 'Please enter a valid Email!',
                            },
                            {
                                required: true,
                                message: 'Please enter your Email!',
                            },
                        ]}
                    >
                        <Input prefix={<UserOutlined />} placeholder="Username" />
                    </Form.Item>
                    <Form.Item
                        name="password"
                        className='password-field'
                        rules={[
                            { required: true, message: 'Please enter the Password!' },
                        ]}
                    >
                        <Input.Password prefix={<LockOutlined />} placeholder="Password" />
                    </Form.Item>
                    <Flex align='center' justify='space-between' className='forgot-link'>
                        <Form.Item name="RememberMe" valuePropName="checked" noStyle>
                            <Checkbox>Remember me</Checkbox>
                        </Form.Item>
                        <a href="/reset-password">Forgot password?</a>
                    </Flex>
                    <Button className='submit-btn' loading={loader} size='large' type="primary" htmlType="submit">Sign In</Button>
                </Form>
            </Flex>
        </div>
    );
}