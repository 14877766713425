import React, { useEffect } from "react";
import {Flex} from 'antd';
import Login1Image from '../../assets/login-1.jpg';
import Login2Image from '../../assets/login-2.jpg';
import './user-onboard.scss';
import { ReactComponent as HeaderIcon } from '../../assets/icons/AbeCollective.svg';
import { Outlet } from 'react-router-dom';
import { use } from "react";
import authToken from "../../AuthToken";

function UserOnboard() {
    const logo = Math.random() > 0.5 ? Login1Image : Login2Image;
    
    useEffect(()=> {
       authToken.removeAuthToken();
    }, []);

    return (
        <Flex className="auth-container">
            {logo && <Flex align="center" className="img-container"><img src={logo} alt="login" className="login-img"/></Flex>}
            <Flex className="right-panel" justify="center">
                <div className="detail-form-container">
                    <Flex className='header' align='center' justify="center">
                            <HeaderIcon className='header-icon' />
                            <h1>AbeCollective</h1>
                    </Flex>
                    <Outlet />
                </div>
            </Flex>
        </Flex>
    )
}

export default UserOnboard;