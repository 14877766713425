import { Avatar, Card, Divider, Flex, Form, Input, Row, Col, Select, Upload, message, Button, Spin } from 'antd'; // Import Spin component
import { startTransition, useEffect, useState } from 'react';
import './profile.scss';
import { UploadOutlined, DeleteOutlined, LoadingOutlined } from '@ant-design/icons';
import profileImage from '../../assets/profile.png'; // Import profile image
import ImgCrop from 'antd-img-crop';
import { useDispatch } from 'react-redux';
import { getUserProfile, updateUserProfile, getProfileImage } from '../../redux/actions/UserAction'; // Import getProfileImage action
import { useTranslation } from 'react-i18next'; // Import useTranslation hook
import { Link } from 'react-router-dom';

const ProfileComponent = () => {
    const [image, setImage] = useState(profileImage);
    const [initialImage, setInitialImage] = useState(profileImage); // Add initial image state
    const [profile, setProfile] = useState({
        firstName: '',
        lastName: '',
        phone: '',
        email: '',
        role: '',
        dateFormat: '',
        timeFormat: '',
        downloadFormat: '',
        language: '',
        notifications: '',
    });
    const [isFormChanged, setIsFormChanged] = useState(false); // Add state to track form changes
    const [displayName, setDisplayName] = useState({ firstName: '', lastName: '' }); // Add state for displayed name
    const [isLoading, setIsLoading] = useState(false); // Add state to track loading
    const [isSaving, setIsSaving] = useState(false); // Add state to track saving
    const [isImageLoading, setIsImageLoading] = useState(false); // Add state to track image loading

    const dispatch = useDispatch();
    const [form] = Form.useForm();
    const { t, i18n } = useTranslation("profile", { useSuspense: false }); // Initialize translation hook with i18n

    const [languageOptions, setLanguageOptions] = useState([]);
    const [dateFormatOptions, setDateFormatOptions] = useState([]);
    const [timeFormatOptions, setTimeFormatOptions] = useState([]);
    const [notificationOptions, setNotificationOptions] = useState([]);
    const [downloadFormatOptions, setDownloadFormatOptions] = useState([]);

    useEffect(() => {
        const fetchProfile = async () => {
            setIsLoading(true); // Set loading state to true
            const result = await dispatch(getUserProfile());
            if (result.status) {
                const profileData = {
                    ...result.data,
                    language: result.data.language || 'english',
                    dateFormat: result.data.dateFormat || 'MM/DD/YYYY',
                    timeFormat: result.data.timeFormat || '12-hour',
                    downloadFormat: result.data.downloadFormat || 'xlsx',
                    notifications: result.data.notifications ? 'on' : 'off' // Set label based on boolean value
                };
                setProfile(profileData);
                setDisplayName({ firstName: profileData.firstName, lastName: profileData.lastName }); // Set displayed name
                form.setFieldsValue(profileData); // Set form values
            }
            setIsLoading(false); // Set loading state to false
        };
        startTransition(() => {
            fetchProfile();
        }); // Fetch profile data
    }, [dispatch, form]);

    useEffect(() => {
        const fetchProfileImage = async () => {
            setIsImageLoading(true); // Set image loading state to true
            const imageResult = await dispatch(getProfileImage()); // Fetch profile image
            if (imageResult.status) {
                const responseImage = `data:image/png;base64,${imageResult.data.image}`;
               setImage(responseImage);
               setInitialImage(responseImage); // Set initial image
            }
            setIsImageLoading(false); // Set image loading state to false
        };
        startTransition(() => {
            fetchProfileImage();
        }); // Fetch profile image
    }, [dispatch]);

    useEffect(() => {
        const currentDate = new Date();
        const options = { day: '2-digit', month: '2-digit', year: 'numeric' };

        const formattedMMDDYYYY = currentDate.toLocaleDateString('en-US', options);
        const formattedDDMMYYYY = currentDate.toLocaleDateString('en-GB', options);

        const formattedDate = currentDate.toLocaleDateString('en-US', { day: '2-digit', month: 'short', year: '2-digit' });
        const formattedDayMonth = currentDate.toLocaleDateString('en-US', { day: '2-digit', month: 'short' });
        const formattedMonthYear = currentDate.toLocaleDateString('en-US', { month: 'short', year: '2-digit' });

        startTransition(() => {
            setLanguageOptions([
                { value: 'english', label: t('english') },
                { value: 'spanish', label: t('spanish') }
            ]);

            setDateFormatOptions([
                { value: 'MM/DD/YYYY', label: `${t('mmddyyyy')} (${formattedMMDDYYYY})` }, // Existing format with dynamic example
                { value: 'DD/MM/YYYY', label: `${t('ddmmyyyy')} (${formattedDDMMYYYY})` }, // Existing format with dynamic example
                { value: 'DD-MMM-YY', label: `${t('ddmmmyy')} (${formattedDate})` }, // New date format with dynamic example
                { value: 'DD-MMM', label: `${t('ddmmm')} (${formattedDayMonth})` }, // New date format with dynamic example
                { value: 'MMM-YY', label: `${t('mmmyy')} (${formattedMonthYear})` } // New date format with dynamic example
            ]);

            setTimeFormatOptions([
                { value: '12-hour', label: t('twelveHour') },
                { value: '24-hour', label: t('twentyFourHour') }
            ]);

            setNotificationOptions([
                { value: 'on', label: t('on') },
                { value: 'off', label: t('off') }
            ]);

            setDownloadFormatOptions([
                { value: 'xlsx', label: t('xlsx') },
                { value: 'pdf', label: t('pdf') }
            ]);

            form.resetFields();
        }); // Wrap translation updates with startTransition
    }, [t, i18n.language]); // Update options whenever the language changes

    const handleCrop = (file) => {
        const reader = new FileReader();
        reader.onload = () => {
            const base64String = reader.result;
            setImage(base64String);
        };
        reader.readAsDataURL(file);
        return false; // Prevent upload
    };

    const handleRemove = () => {
        setImage(null);
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setProfile({ ...profile, [name]: value });
    };

    const handleSelectChange = (name, value) => {
        setProfile({ ...profile, [name]: value });
    };

    const isImageChanged = image !== initialImage.replace(/^data:image\/[a-z]+;base64,/, ''); // Compare truncated base64 values

    const handleFormChange = () => {
        setIsFormChanged(form.isFieldsTouched()); // Update state when form values change
    };

    const handleSaveChanges = async () => {
        setIsSaving(true); // Set saving state to true
        const profileData = {
            ...profile,
            image: image.replace(/^data:image\/[a-z]+;base64,/, ''),
            notifications: profile.notifications === 'on' // Convert label to boolean value
        };
        const result = await dispatch(updateUserProfile(profileData));
        if (result.status) {
            message.success('Profile updated successfully'); // Display success message
            setDisplayName({ firstName: profile.firstName, lastName: profile.lastName }); // Update displayed name
        } else {
            message.error('Failed to update profile'); // Display error message
        }
        setIsSaving(false); // Set saving state to false
    };

    const handleChangePassword = () => {
        // Logic to handle password change, e.g., open a modal
    };

    const renderOptions = (options) => {
        return options.map(option => (
            <Select.Option key={option.value} value={option.value}>
                {option.label}
            </Select.Option>
        ));
    };

    const renderDropdowns = () => {
        const dropdowns = [
            {
                label: t('language'),
                name: 'language',
                options: languageOptions
            },
            {
                label: t('notification'),
                name: 'notifications',
                options: notificationOptions
            },
            {
                label: t('dateFormat'),
                name: 'dateFormat',
                options: dateFormatOptions
            },
            {
                label: t('timeFormat'),
                name: 'timeFormat',
                options: timeFormatOptions
            },
            {
                label: t('downloadFormat'),
                name: 'downloadFormat',
                options: downloadFormatOptions
            }
        ];

        return dropdowns.map(dropdown => (
            <Col span={12} key={dropdown.name}>
                <Form.Item label={dropdown.label} name={dropdown.name}>
                    <Select
                        placeholder={dropdown.label}
                        onChange={(value) => handleSelectChange(dropdown.name, value)}
                    >
                        {renderOptions(dropdown.options)}
                    </Select>
                </Form.Item>
            </Col>
        ));
    };

    return (
        isLoading ?
            <Spin spinning={isLoading}
                rootClassName='main-loader' size='large' indicator={<LoadingOutlined spin />} />
            : (
                <Card className='profile-card'>
                    <Flex vertical style={{ margin: 'auto' }}>
                        <>
                            <div className='profile-section'>
                                <h5 className='header-section'>{t('myProfile')}</h5>
                                <span className='header-note'>{t('viewEditProfile')}</span>
                                <Flex justify='space-between' align='center' className='profile-section'>
                                    <Flex justify='space-between' className='profile-content' gap={8}>
                                            <Avatar size={64} src={image ? `${image}` : profileImage} />
                                        <div className='profile-details'>
                                            <h5 className='profile_name'>{displayName.firstName} {displayName.lastName}</h5> {/* Use displayed name */}
                                            <span className='profile-role'>{t(profile.role)}</span>
                                        </div>
                                    </Flex>
                                    <Flex gap={6}>
                                        <ImgCrop rotate>
                                            <Upload
                                                accept="image/*"
                                                showUploadList={false}
                                                beforeUpload={handleCrop}
                                            >
                                                <Button icon={<UploadOutlined />}>{t('uploadPhoto')}</Button>
                                            </Upload>
                                        </ImgCrop>
                                        <Button icon={<DeleteOutlined />} onClick={handleRemove}>{t('removePhoto')}</Button>
                                    </Flex>
                                </Flex>
                            </div>
                            <Divider />
                            <div className='profile-section'>
                                <h5 className='header-section'>{t('personalInformation')}</h5>
                                <span className='header-note'>{t('manageInformation')}</span>
                                <Flex>
                                    <Form
                                        form={form} // Add form instance
                                        layout="vertical"
                                        className='personal-info-details'
                                        style={{ width: '100%' }}
                                        initialValues={profile} // Set initial values
                                        onValuesChange={handleFormChange} // Track form changes
                                    >
                                        <Row gutter={16}>
                                            <Col span={12}>
                                                <Form.Item label={t('firstName')} name="firstName" className='personal-info-details_item'>
                                                    <Input
                                                        placeholder={t('firstName')}
                                                        name="firstName"
                                                        onChange={handleInputChange}
                                                    />
                                                </Form.Item>
                                            </Col>
                                            <Col span={12}>
                                                <Form.Item label={t('lastName')} name="lastName">
                                                    <Input
                                                        placeholder={t('lastName')}
                                                        name="lastName"
                                                        onChange={handleInputChange}
                                                    />
                                                </Form.Item>
                                            </Col>
                                        </Row>
                                        <Row gutter={16}>
                                            <Col span={12}>
                                                <Form.Item label={t('email')} name="email">
                                                    <Input
                                                        placeholder={t('email')}
                                                        name="email"
                                                        onChange={handleInputChange}
                                                        disabled // Disable the field
                                                    />
                                                </Form.Item>
                                            </Col>
                                            <Col span={12}>
                                                <Form.Item label={t('phone')} name="phone">
                                                    <Input
                                                        placeholder={t('phone')}
                                                        name="phone"
                                                        onChange={handleInputChange}
                                                    />
                                                </Form.Item>
                                            </Col>
                                        </Row>
                                    </Form>
                                </Flex>
                            </div>
                            <Divider />
                            <div className='profile-section'>
                                <h5 className='header-section'>{t('account')}</h5>
                                <span className='header-note'>{t('updatePassword')}</span>
                                <Flex>
                                    <Form
                                        form={form} // Add form instance
                                        layout="vertical"
                                        className='personal-info-details'
                                        style={{ width: '100%' }}
                                        initialValues={profile} // Set initial values
                                        onValuesChange={handleFormChange} // Track form changes
                                    >
                                        <Row gutter={16}>
                                            {renderDropdowns()}
                                            <Col span={12}>
                                                <Form.Item label={t('password')} name="password" className='password-change-item'>
                                                    <Link to="/change-password">
                                                        <Button className='password-change-button'>{t('changePassword')}</Button>
                                                    </Link>
                                                </Form.Item>
                                            </Col>
                                        </Row>
                                    </Form>
                                </Flex>
                            </div>
                            <Flex justify='space-between' style={{ marginTop: '10px' }}>
                                <Button danger icon={<DeleteOutlined />}>{t('deleteAccount')}</Button>
                                <Flex gap={6}>
                                    <Button type="primary" disabled={!isFormChanged && !isImageChanged} onClick={handleSaveChanges} loading={isSaving}>{t('saveChanges')}</Button> {/* Use isSaving for loading */}
                                </Flex>
                            </Flex>
                        </>
                    </Flex>
                </Card>
            )
    );
};

export default ProfileComponent;