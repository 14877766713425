class AuthToken {
    setItem(key, value) {
        localStorage.setItem(key, value);
    }

    async setAuthToken(value) {
        localStorage.setItem('access-token', value);
    }

    removeAuthToken() {
        localStorage.removeItem('access-token');
    }

    getItem(key) {
        return localStorage.getItem(key);
    }

    removeItem(key) {
        localStorage.removeItem(key);
    }
}
const authToken = new AuthToken();

export default authToken;
