import React, { startTransition, useEffect, useState } from 'react';
import { Table, Card, Button, Flex, Modal, Form, Select, message, Spin } from 'antd';
import { PlusOutlined, DeleteOutlined, EditOutlined, LoadingOutlined } from '@ant-design/icons'; // Import EditOutlined
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { addMembersToTeam, getTeamRoles, getTeamMembers, deleteMemberFromTeam } from '../../redux/actions/UserAction';
import './manage-team.scss';

const { Option } = Select;

const MemberDetails = ({ selectedTeam, users, handleAddingUser, handleDeleteTeam, handleEditTeam }) => { // Add handleEditTeam prop
    const { t, i18n } = useTranslation('teams', { useSuspense: false });
    const [memberColumns, setMemberColumns] = useState([]);
    const [open, setOpen] = useState(false);
    const [confirmLoading, setConfirmLoading] = useState(false);
    const [memberDetailLoaded, setMemberDetailLoaded] = useState(false);
    const [roles, setRoles] = useState([]);
    const [teamUsers, setTeamUsers] = useState([]);
    const [selectedUsers, setSelectedUsers] = useState(users);
    const [form] = Form.useForm();
    const dispatch = useDispatch();

    const fetchRoles = async () => {
        const response = await dispatch(getTeamRoles());
        if (response.status) {
            setRoles(response.data);
        } else {
            message.error(t('form.rules.roleFetchError'));
        }
    };

    const fetchTeamMembers = async (reload) => {
        setMemberDetailLoaded(true);
        const response = await dispatch(getTeamMembers(selectedTeam.id));
        if (response.status) {
            setTeamUsers(response.data.data.records);
            removeAlreadySelectedUsers(response.data.data.records, reload);
        } else {
            message.error(t('form.rules.userFetchError'));
        }
        setMemberDetailLoaded(false);
    };

    const removeAlreadySelectedUsers = (memberList, reload) => {
        if(reload) {
            handleAddingUser(memberList.length, selectedTeam.id);
        }
       
        const userList = users.filter(selectedUser => 
            !memberList.some(user => user.userId === selectedUser.id)
        );
        setSelectedUsers(userList);
    };

    useEffect(() => {
        startTransition(() => {
            fetchRoles();
            fetchTeamMembers();
        });
    }, [selectedTeam.id]);

    const renderMemberColumns = () => {
        const columns = [
            {
                title: t('fullName'),
                dataIndex: 'fullName',
                key: 'fullName',
            },
            {
                title: t('email'),
                dataIndex: 'email',
                key: 'email',
            },
            {
                title: t('role'),
                dataIndex: 'role',
                key: 'role',
                render: (role) => t(role.replace(" ", "")),
            },
            {
                title: t('action'),
                key: 'action',
                render: (text, record) => (
                    <Button
                        icon={<DeleteOutlined />}
                        onClick={() => showDeleteConfirm(record)}
                    />
                ),
            }
        ];
        setMemberColumns(columns);
    };

    const showModal = () => {
        form.resetFields();
        setOpen(true);
    };

    const handleOk = async () => {
        try {
            setConfirmLoading(true);
            const formValues = await form.validateFields();
            const members = formValues.users.map(user => ({ user: user, role: formValues.role }));
            const response = await dispatch(addMembersToTeam(selectedTeam.id, members));
            if (response.status && response.data.success) {
                message.success(t('memberAdded'));
                setOpen(false);
                form.resetFields();
                fetchTeamMembers(true);
            } else {
                const messageText = response.data.message || t('addMemberFailed');
                message.error(messageText);
            }
        } catch (error) {
            console.error('Validation failed:', error);
        } finally {
            setConfirmLoading(false);
        }
    };

    const handleCancel = () => {
        setOpen(false);
    };

    const showDeleteConfirm = (record) => {
        Modal.confirm({
            title: t('deleteMember'),
            content: t('confirmDelete', { user: record.fullName, teamName: selectedTeam.name }),
            okText: t('ok'),
            cancelText: t('cancel'),
            onOk: () => handleDeleteUser(record.userId),
        });
    };

    const handleDeleteUser = async (userId) => {
        try {
            const response = await dispatch(deleteMemberFromTeam(selectedTeam.id, [userId]));
            if (response.status) {
                message.success(t('memberDeleted'));
                fetchTeamMembers();
            } else {
                message.error(t('deleteFailed'));
            }
        } catch (error) {
            console.error('Delete failed:', error);
            message.error(t('deleteFailed'));
        }
    };

    const showDeleteTeamConfirm = () => {
        Modal.confirm({
            title: t('deleteTeam'),
            content: t('confirmDeleteTeam', { teamName: selectedTeam.name }),
            okText: t('ok'),
            cancelText: t('cancel'),
            onOk: handleDeleteTeam,
        });
    };

    useEffect(() => {
        renderMemberColumns();
    }, [t, i18n.language, users]);

    const renderOptions = (users) => {
        return selectedUsers.map(user => ({
            label: user.fullName,
            value: user.id,
            key: user.id,
            desc: user.userName,
        }));
    };

    const spinLoader = <Spin rootClassName='main-loader' indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} />;


    return (
        <>
            <Card
                className='team-detail-sections'
                title={selectedTeam.name}
                extra={
                    <Flex gap="small">
                        <Button
                            icon={<EditOutlined />}
                            onClick={() => handleEditTeam(selectedTeam)}
                        >
                            {t('editTeam')}
                        </Button>
                        <Button
                            icon={<DeleteOutlined />}
                            onClick={showDeleteTeamConfirm}
                        >
                            {t('deleteTeam')}
                        </Button>
                        <Button
                            type="primary"
                            icon={<PlusOutlined />}
                            onClick={showModal}
                        >
                            {t('addMember')}
                        </Button>
                    </Flex>
                }
            >
                <Table
                    bordered={true}
                    columns={memberColumns}
                    dataSource={teamUsers}
                    rowKey="id"
                    pagination={false}
                    scroll={{ y: 55 * 5 }}
                    loading={{rootClassName:"main-loader", size:"large", indicator: spinLoader, spinning: memberDetailLoaded}}
                    locale={{ emptyText: t('noData') }}
                />
            </Card>

            <Modal
                title={t('addMember')}
                open={open}
                onOk={handleOk}
                confirmLoading={confirmLoading}
                onCancel={handleCancel}
            >
                <Form
                    form={form}
                    layout="vertical"
                    onFinish={handleOk}
                >
                    <Form.Item
                        name="users"
                        label={t('memberForm.user')}
                        rules={[{ required: true, message: t('form.rules.userRequired') }]}
                    >
                        <Select
                            showSearch
                            mode="multiple"
                            placeholder={t('memberForm.user')}
                            options={renderOptions(users)}
                            filterOption={(input, option) =>
                                (option?.label ?? '').toLowerCase().includes(input.toLowerCase()) ||
                                (option?.desc ?? '').toLowerCase().includes(input.toLowerCase())
                            }
                            optionRender={(option) => (
                                <Flex gap={2} className='user-display-section' vertical>
                                    <span className='user-fullname' role="img" aria-label={option.data.label}>
                                        {option.data.label}
                                    </span>
                                    <span className='user-email'>{option.data.desc}</span>
                                </Flex>
                            )}
                        />
                    </Form.Item>

                    <Form.Item
                        name="role"
                        label={t('form.role')}
                        rules={[{ required: true, message: t('form.rules.roleRequired') }]}
                    >
                        <Select
                            placeholder={t('memberForm.role')}
                        >
                            {roles.map(role => (
                                <Option key={role.id} value={role.id}>
                                    {t(role.name.replace(" ", ""))}
                                </Option>
                            ))}
                        </Select>
                    </Form.Item>
                </Form>
            </Modal>
        </>
    );
};

export default MemberDetails;