import React, { useState, useEffect, useRef } from 'react';
import 'dhtmlx-gantt/codebase/dhtmlxgantt.css';
import { Button, Dropdown, Flex, Checkbox } from 'antd';
import {HighlightOutlined,ZoomInOutlined,ZoomOutOutlined,EyeOutlined} from "@ant-design/icons";
import gantt from 'dhtmlx-gantt';
import './gantt-chart.scss';
import {highlightColors} from './GanttColors';
import FilterComponent from '../../common/FilterComponent';
import { useTranslation } from 'react-i18next'; // Import useTranslation
import { transformDataToGanttFormat } from './GanttFunctions';

function GanttChart(props) {
    const { t, i18n } = useTranslation('schedule', { useSuspense: false }); // Initialize useTranslation with 'schedule' namespace
    const {data} = props;
    const {input_tables,output_tables} = data;
    const outputTableData = output_tables.schedule_detail.sort((a, b) => new Date(a.task_start_time) - new Date(b.task_start_time));
    const ganttContainer = useRef(null);
    const [viewBy, setViewBy] = useState('route');
    const [highlightBy, setHighlightBy] = useState('product');
    const [currentZoomLevel, setCurrentZoomLevel] = useState(-1);
    const [viewByActive, setViewByActive] = useState(false);
    const [highlightByActive, setHighlightByActive] = useState(false);
    const [filteredData,setFilteredData] = useState(outputTableData);
    const filteredItems = useRef({});
    const [showLinks, setShowLinks] = useState(true);
    const [isZoomSet, setIsZoomSet] = useState(false);

    const viewByItems = [
        {
            label: t("route"),
            key: 'route',
            onClick: () => setViewBy('route')
        },
        {
            label: t("routeAndResource"),
            key: 'routeResource',
            onClick: () => setViewBy('routeResource')
          },
        {
          label: t("resourceAndTask"),
          key: 'resourceTask',
          onClick: () => setViewBy('resourceTask')
        },
        {
          label: t("resource"),
          key: 'resource',
          onClick: () => setViewBy('resource')
        },
        {
          label: t('task'),
          key: 'task',
          onClick: () => setViewBy('task')
        },
        {
            label: t('product'),
            key: 'product',
            onClick: () => setViewBy('product')
        },
        {
            label: t('demand'),
            key: 'demandId',
            onClick: () => setViewBy('demandId')
        },
    ];

    const highlightItems = [
        {
          label: t("none"),
          key: 'none',
          onClick: () => setHighlightBy('none')
        },
        {
            label: t("route"),
            key: 'route',
            onClick: () => setHighlightBy('route')
        },
        {
          label: t("resource"),
          key: 'resource',
          onClick: () => setHighlightBy('resource')
        },
        {
          label: t('task'),
          key: 'task',
          onClick: () => setHighlightBy('task')
        },
        {
            label: t('product'),
            key: 'product',
            onClick: () => setHighlightBy('product')
        },
        {
            label: t('demand'),
            key: 'demandId',
            onClick: () => setHighlightBy('demandId')
        },
        {
            label: t('demandQuantity'),
            key: 'demandQuantity',
            onClick: () => setHighlightBy('demandQuantity')
        },
    ];

    const filterFieldsMapping = {
        'resource': {key: 'resource_id', label: 'Resource'}, 
        'task': {key: 'task_id', label: 'Task'},
        'product': {key: 'product_id', label: 'Product'}, 
        'demand': {key: 'demand_id', label: 'Demand'}, 
        'customer': {key: 'customer_id', label: 'Customer'},
        'route': {key: 'route_id', label: 'Route'}
    };

    const filterDataSource = createFilterDataSource(outputTableData);

    function handleSelectViewBy(resourceId=null,taskId=null,jobId=null,productId=null,demandId=null,routeId=null) {
        const filterParams = {
            'route': {columns: [{name:'route_id',label:t("route"), width: 250}], viewId: `rt-${routeId}`, parentId: `route-${routeId}`},
            'routeResource' : {columns: [{name: "open", label: "", width: 14, template: gantt.templates.grid_open},{name:'resource_name',label:t('routeResource'), width: 250}], viewId: `rt-${routeId}/r-${resourceId}`, parentId: `route-resource-${routeId}`},
            'resourceTask' : {columns: [{name: "open", label: "", width: 14, template: gantt.templates.grid_open},{name:'resource_name',label:t('resourceTask'), width: 250}], viewId: `r-${resourceId}/t-${taskId}`, parentId: `resource-${resourceId}`},
            'resource': {columns: [{name:'resource_name',label:t('resource'), width: 150}], viewId:`r-${resourceId}`, parentId: `resource-${resourceId}`},
            'task': {columns: [{name:'task_name',label:t('task'), width: 150}], viewId: `t-${taskId}`, parentId: `task-${taskId}`},
            'product': {columns: [{name:'product_name',label:t('product'), width: 150}], viewId: `p-${productId}`, parentId: `product-${productId}`},
            'demandId': {columns: [{name:'demand_id',label:t('demand'), width: 150}], viewId: `d-${demandId}`, parentId: `demand-${demandId}`}
        }

        return filterParams[viewBy];
    }

    const highlightByMapping = {
        'route': 'route_id',
        'resource': 'resource_id',
        'task': 'task_id',
        'product': 'product_id',
        'demandId': 'demand_id',
        'demandQuantity': 'demand_quantity'
    };

    useEffect(() => {
        gantt.config.date_format = "%Y-%m-%d %H:%i";
        gantt.config.duration_unit = "minute";
        gantt.config.width = 2000;
        gantt.config.order_branch = true;
        gantt.config.fit_tasks = true;

        gantt.config.scales = [
            {unit: "hour", step: 1, format: "%H:%i"}
        ];

        const currentSelectedParam = handleSelectViewBy();
        gantt.config.columns = currentSelectedParam.columns;

        gantt.templates.task_class = function (start, end, task) {
            if(task.is_sub_task && task.$expanded_branch && task.$rendered_at !== task.$rendered_parent) {
                return 'hide-task';
            }
            const currentHighlightCategoryList = uniqueHighlightCategoryList();
            if(currentHighlightCategoryList.length > 0) {
                const currentHighlightByValue = highlightByMapping[highlightBy]
                const currentCategoryIndex = currentHighlightCategoryList.findIndex((item) => item === task[currentHighlightByValue])

                const colorIndex = currentCategoryIndex % highlightColors.length
                const className = `highlight-task-${colorIndex}`;
                return `color-dark ${className} level-${task.$level}`;
            }
            else {
                return `color-dark highlight-task-0`;
            }
        };

        gantt.templates.link_class = function(link){
            return "link-color";
        };

        gantt.templates.grid_row_class = function(start, end, task){
            if (task.$level === 1) {
                return "children-task";
            }
        };

        gantt.templates.grid_open = function(task) {
            if (task.$level === 0) {
                if(task.$open) {
                    return `
                    <svg xmlns="http://www.w3.org/2000/svg" width="10" height="10" viewBox="0 0 36 36" fill="none">
                        <path d="M32.946 7.71458H29.9326C29.7277 7.71458 29.5349 7.81503 29.4143 7.97976L17.9996 23.7137L6.58485 7.97976C6.46431 7.81503 6.27146 7.71458 6.06655 7.71458H3.05315C2.79199 7.71458 2.63931 8.01191 2.79199 8.22485L16.959 27.7557C17.4732 28.4628 18.5259 28.4628 19.0362 27.7557L33.2032 8.22485C33.3599 8.01191 33.2072 7.71458 32.946 7.71458Z" fill="black" fill-opacity="0.85"/>
                    </svg>`
                }
                else {
                    return `
                    <svg xmlns="http://www.w3.org/2000/svg" width="10" height="10" viewBox="0 0 36 36" fill="none">
                        <path d="M33.2077 27.7757L19.0407 8.24494C18.5265 7.5378 17.4738 7.5378 16.9635 8.24494L2.79252 27.7757C2.75765 27.8238 2.73676 27.8805 2.73217 27.9397C2.72757 27.9989 2.73946 28.0582 2.7665 28.111C2.79354 28.1639 2.83469 28.2082 2.88538 28.2391C2.93607 28.27 2.99433 28.2862 3.05368 28.286H6.06708C6.27199 28.286 6.46484 28.1856 6.58538 28.0208L18.0001 12.2869L29.4148 28.0208C29.5354 28.1856 29.7282 28.286 29.9331 28.286H32.9465C33.2077 28.286 33.3604 27.9887 33.2077 27.7757Z" fill="black" fill-opacity="0.85"/>
                    </svg>`
                }
            }
            return "";
        };

        const zoomConfig = {
            levels: [
                {
                    name: "10min",
                    scale_height: 30,
                    scales: [
                        { unit: "hour", step: 1, format: "%d %M", sticky: true },
                        {unit: "minute", step: 10, format:'%H:%i'},
                    ]
                },
                {
                    name: "15min",
                    scale_height: 30,
                    scales: [
                        { unit: "hour", step: 2, format: "%d %M", sticky: true },
                        {unit: "minute", step: 15, format:'%H:%i'},
                    ]
                },
                {
                    name: "30min",
                    scale_height: 30,
                    scales: [
                        { unit: "hour", step: 4, format: "%d %M", sticky: true },
                        {unit: "minute", step: 30, format:'%H:%i'},
                    ]
                },
                {
                    name: "hour",
                    scale_height: 30,
                    scales: [
                        { unit: "hour", step: 8, format: "%d %M"},
                        {unit: "hour", step: 1, format:'%H:%i'}
                    ]
                },
                {
                    name: "3 days",
                    scale_height: 30,
                    scales: [
                        {
                            unit: "day", 
                            step: 3, 
                            format: function (date) {
                                var dateToStr = gantt.date.date_to_str("%d %M");
                                var endDate = gantt.date.add(date, 2, "day");
                                return dateToStr(date) + " - " + dateToStr(endDate);
                            }
                        },
                        { unit: "day", step: 1, format: "%j %D" }
                    ]
                },
                {
                    name: "week",
                    scale_height: 30,
                    scales: [
                        {
                            unit: "week", 
                            step: 1, 
                            format: function (date) {
                                var dateToStr = gantt.date.date_to_str("%d %M");
                                var weekStart = gantt.date.week_start(date);
                                var weekEnd = gantt.date.add(weekStart, 6, "day");
                                var weekNum = gantt.date.date_to_str("%W")(weekStart);
                                return "week " + weekNum + ", " + dateToStr(weekStart) + " - " + dateToStr(weekEnd);
                            }
                        },
                    ]
                },
                {
                    name: "month",
                    scale_height: 30,
                    scales: [
                        { unit: "month", format: "%F, %Y" }
                    ]
                },
                {
                    name: "quarter",
                    scale_height: 30,
                    scales: [
                        {
                            unit: "quarter", step: 1, format: function (date) {
                                var dateToStr = gantt.date.date_to_str("%M");
                                var endDate = gantt.date.add(gantt.date.add(date, 3, "month"), -1, "day");
                                return dateToStr(date) + " - " + dateToStr(endDate);
                            }
                        },
                        { unit: "year", step: 1, format: "%Y" }
                    ]
                },
                {
                    name: "year",
                    scale_height: 30,
                    scales: [
                        { unit: "year", step: 1, format: "%Y" }
                    ]
                }
            ]
        };

        gantt.ext.zoom.init(zoomConfig);
        currentZoomLevel !== -1 && gantt.ext.zoom.setLevel(currentZoomLevel);
        gantt.config.drag_move = false;
        gantt.config.drag_progress = false;
        gantt.config.drag_resize = false;
        gantt.config.drag_links = false;
        gantt.config.readonly = true;

        if(!gantt._eventsAttached){
            gantt._eventsAttached = true;
        
            gantt.attachEvent("onTaskClick", function(id, e) {
                e.preventDefault();
                const task = gantt.getTask(id);
                if (task.$level === 0) {
                    if (task.$open) {
                        gantt.close(id);
                    } else {
                        gantt.open(id);
                    }
                }
                return true;
            });

            gantt.attachEvent("onTaskLoading", function (task) {
                if (task.hide){
                    task.hide_bar = true
                }
                return true
            });
        }

        gantt.plugins({
            tooltip: true
        })

        gantt.init(ganttContainer.current);
        const tasks = transformDataToGanttFormat(input_tables,filteredData,viewBy,highlightBy,showLinks,handleSelectViewBy);
        gantt.parse(tasks);

        if (!isZoomSet) {
            const taskList = gantt.getTaskByTime();
            if (taskList.length > 0) {
                const minStart = new Date(taskList[0].start_date);
                minStart.setHours(0, 0, 0, 0);
                const maxEnd = new Date(taskList[taskList.length - 1].end_date);
                maxEnd.setHours(23, 59, 59, 999);
                gantt.config.start_date = minStart;
                gantt.config.end_date = maxEnd;
                const msPerDay = 24 * 60 * 60 * 1000;
                const ganttSpan = Math.ceil((maxEnd - minStart) / msPerDay);
                    applyZoomLevel(ganttSpan);
                    setIsZoomSet(true);
            }
        }

        gantt.ext.tooltips.tooltipFor({
            selector: ".gantt_task_content",
            html: function (event, domElement) {
                    return ganttTooltip(event, domElement);
                }
        });
    
        return () => {
          gantt.clearAll();
        };
    }, [data,isZoomSet,viewBy,highlightBy,filteredData,showLinks,t,i18n.language]);

    function ganttTooltip(event, domElement) {
        const id = event.target.parentNode.attributes['task_id'].nodeValue;
        const task = gantt.getTask(id);
        const {task_name,resource_name,demand_id,job_id,product_name,route_id,customer_id,site_id,start_date,end_date,duration,demand_quantity} = task;
        const taskDetails = {task_name,resource_name,demand_id,job_id,product_name,route_id,customer_id,site_id,start_date,end_date,duration,demand_quantity}
        const labels = {'start_date': 'Start', 'end_date': 'End', 'duration': 'Duration', 'customer_id': 'Customer', 'product_name': 'Product', 'site_id': 'Site', 'route_id': 'Route', 'resource_name': 'Resource', 'task_name': 'Task', 'job_id': 'Job', 'demand_id': 'Demand', 'demand_quantity': 'Demand Quantity'};

        function renderDuration(duration) {
            const totalHours = Math.floor(duration / 60);
            const totalMinutes = duration % 60;

            return totalHours > 0 ? `${totalHours} hr ${totalMinutes} min` : `${duration} min`;
        }

        const rows = `
            <div style="display: grid; grid-template-columns: repeat(2, 1fr); row-gap: 6px; column-gap: 20px;">
                ${Object.entries(labels)
                    .map(([key, name]) => {
                        const value = taskDetails[key];
                        return `
                            <div style="font-size: 0.875rem; line-height: 22px; display: flex; column-gap: 10px; align-items: center;">
                                <strong>${name}:</strong>
                                <span>${key === 'start_date' || key === 'end_date' ? value.toLocaleString() : key === 'duration' ? renderDuration(value) : value}</span>
                            </div>`;
                    })
                    .join('')}
            </div>`;
        return  rows;
    }

    function zoomOut() {
        gantt.ext.zoom.zoomOut();
        setCurrentZoomLevel(gantt.ext.zoom.getCurrentLevel())
    }

    function zoomIn() {
        gantt.ext.zoom.zoomIn();
        setCurrentZoomLevel(gantt.ext.zoom.getCurrentLevel())
    }

    function applyZoomLevel(ganttSpan) {
        if (ganttSpan > 0 && ganttSpan <= 1) {
            setCurrentZoomLevel(3);
            gantt.ext.zoom.setLevel(3);
        } else if (ganttSpan > 1 && ganttSpan <= 3) {
            setCurrentZoomLevel(4);
            gantt.ext.zoom.setLevel(4);
        } else if (ganttSpan > 3 && ganttSpan <= 7) {
            setCurrentZoomLevel(5);
            gantt.ext.zoom.setLevel(5);
        } else if (ganttSpan > 7) {
            setCurrentZoomLevel(6);
            gantt.ext.zoom.setLevel(6);
        } else {
            setCurrentZoomLevel(5);
            gantt.ext.zoom.setLevel(5);
        }
    }

    function uniqueHighlightCategoryList() {
        if(highlightBy === 'none') return [];
        const currentHighlightByValue = highlightByMapping[highlightBy];
        return [...new Set(outputTableData.map(item => item[currentHighlightByValue]))];
    }

    function handleFilterItems(currentFilter=null) {
        return outputTableData.filter((item) => {
            const { current } = filteredItems;

            const currentFilterField = filterFieldsMapping[currentFilter]?.key;
            if(currentFilter && current[currentFilter]?.includes(item[currentFilterField]) ) return true;
        
            return Object.keys(current).every((filterKey) => {
                if(currentFilter && currentFilter === filterKey) return true;
                const field = filterFieldsMapping[filterKey].key;
                const filterValues = current[filterKey];
                const itemValue = item[field];
        
                return (
                    !filterValues || filterValues.length === 0 || filterValues.includes(itemValue)
                );
            });
        });
    }

    function getFilterItemsList(selectedRow) {
        const selectedFilter = filterFieldsMapping[selectedRow].key
        const itemList = handleFilterItems(selectedRow)

        return  Array.from(new Set(itemList.map(item => item[selectedFilter])))
                .map(entity => ({
                    key: entity,
                    label: entity,
                    relatedKey: selectedRow
                }));
    }

    function createFilterDataSource(data) {
        return Object.keys(filterFieldsMapping).map((column) => {
            const columnKey = filterFieldsMapping[column].key;
            const uniqueValues = [...new Set(data.map(item => item[columnKey]))];
            return {
                key: column,
                label: filterFieldsMapping[column].label,
                values: uniqueValues
            };
        });
    }

    function applyFilter() {
        const filterData = handleFilterItems();
        setFilteredData(filterData);
    }
    
    function toggleArrows(e) {
        setShowLinks(e.target.checked);
    }

    return (
        <div>
            <Flex className='actions-container' gap={13} align='center' justify='end'>
                <Checkbox checked={showLinks} onChange={toggleArrows}>Show Arrows</Checkbox>
                <Flex className="zoom-btn-container" gap={13} align='center'>
                    <FilterComponent data={filterDataSource} filteredItems={filteredItems} getFilterItemsList={getFilterItemsList} applyFilter={applyFilter}/>
                    <Dropdown menu={{items: highlightItems}} trigger={['click']} onOpenChange={(open) => setHighlightByActive(open)}>
                        <Button className={`highlightby-btn ${highlightByActive && 'btn-active'}`} icon={<HighlightOutlined />}>{highlightItems.find(item => item.key === highlightBy)?.label}</Button>
                    </Dropdown>
                    <Dropdown menu={{items: viewByItems}} trigger={['click']} onOpenChange={(open) => setViewByActive(open)}>
                        <Button className={`viewby-btn ${viewByActive && 'btn-active'}`} icon={<EyeOutlined />}>{viewByItems.find(item => item.key === viewBy)?.label}</Button>
                    </Dropdown>
                    <Flex gap={8} align='center'>
                        <Button className='zoom-btn' onClick={zoomIn} icon={<ZoomInOutlined />}/>
                        <Button className='zoom-btn' onClick={zoomOut} icon={<ZoomOutOutlined />}/>
                    </Flex>
                </Flex>
            </Flex>
            <div
                ref={ganttContainer}
                style={{ width: '100%', height: '619px' }}
            ></div>
        </div>
    );
}

export default GanttChart;