import React, { useState, useEffect } from "react";
import './dashboard.scss';
import { getSettingsForRun, saveSettingsForRun } from "../../redux/actions/SchedulerRunActions";
import { Button, Flex, Dropdown, Space, message } from 'antd';
import { DownOutlined, UndoOutlined } from "@ant-design/icons";
import { DashboardWidgetMenus, UpdateMenus, getNextPosition } from "./DashboardSettings";
import { v4 as uuidv4 } from 'uuid';
import GridLayout from "../gridlayout/GridLayout";

function DashboardLayout(props) {
  const { enableSave, dashboardSettings, data, currentRunId } = props;
  const [loading, setLoading] = useState(false);
  //const initialLayouts = dashboardSettings.map(item => item.gridsterSetting);
  const [layouts, setLayouts] = useState([]);
  const [settings, setSettings] = useState([]);
  const [menuItems, setMenuItems] = useState(DashboardWidgetMenus);

  function handleLayoutChange(newLayout) {
    setLayouts(newLayout);
    mapLayout(newLayout);
    let menu = UpdateMenus(settings);
    setMenuItems(menu)
  }

  function mapLayout(newLayout) {
    const newSettings = settings.map(item => {
      let layout = newLayout.find(x => x.i === item.i);
      if (layout) {
        Object.assign(item, layout);
      }
      return item;
    });
    console.log(newSettings);
    setSettings(newSettings);
  }

  useEffect(() => {
    async function getSettings(dashboardSettings) {
      setLoading(true);
      const response = await getSettingsForRun(Number.parseInt(currentRunId));
      let data = [];
      if (response.data) {
        data = response.data.settings;
      }
      else {
        data = cloneSettings(dashboardSettings);
      }
      setLayouts(data);
      setSettings(data);
      setLoading(false);
      let menu = UpdateMenus(data);
      setMenuItems(menu);
    }
    getSettings(dashboardSettings);
  }, [currentRunId, dashboardSettings])

  async function handleSave() {
    async function saveSettings() {
      setLoading(true);
      await saveSettingsForRun(Number.parseInt(currentRunId), settings);
      message.success("Settings saved successfully");
      setLoading(false);
    }
    saveSettings();
  }

  function cloneSettings(dashboardSettings) {
   return JSON.parse(JSON.stringify(dashboardSettings));
  }

  function handleReset() {
    setLayouts(cloneSettings(dashboardSettings));
    setSettings(cloneSettings(dashboardSettings));
    message.success("Settings reset to default");
  }

  function handleMenuClick(event) {
    const dataSettings = event.item.props.settings;
    const type = event.item.props.dashboardType;
    const secondaryWidgetProp = event.item.props.secondaryWidget;
    let widget = {};
    if(type === "card") {
      const getNextPositionXY = getNextPosition(layouts, 4);
      widget = {
        type: "card",
        settings: dataSettings,
        // Add a new item. It must have a unique key!
        i: uuidv4(),
        x: getNextPositionXY.x,
        y: getNextPositionXY.y, // puts it at the bottom
        w: 4,
        h: 1,
        minH: 1,
        minW: 3,
        secondaryWidget: secondaryWidgetProp
      };
    }
    else {
      const getNextPositionXY = getNextPosition(layouts, 8);
      widget = {
        type: "chart",
        settings: dataSettings,
        // Add a new item. It must have a unique key!
        i: uuidv4(),
        x: getNextPositionXY.x,
        y: getNextPositionXY.y, // puts it at the bottom
        w: dataSettings.chartType === "pie" ? 5 : 8,
        h: dataSettings.chartType === "pie" ? 3 : 2,
        minH:  2,
        minW: 4
      };
    }
    setSettings([...settings, widget]);
    setLayouts([...settings, widget]);
  }

  return (
    <>
      {enableSave && (
        <Flex gap="small" wrap justify="end" align="center">
          <Dropdown menu={{
            items: menuItems,
            onClick: handleMenuClick,
          }} overlayClassName="widget-menu" openClassName="temp" placement="bottomLeft" arrow autoAdjustOverflow>
            <a onClick={(e) => e.preventDefault()}>
              <Space>
                Add
                <DownOutlined />
              </Space>
            </a>
          </Dropdown>
          <Button onClick={handleReset} icon={<UndoOutlined />}>Reset to default</Button>
          <Button type="primary" onClick={handleSave} loading={loading}>Save Settings</Button>
        </Flex>
      )}
      <GridLayout setLayouts={setLayouts} setSettings={setSettings} layouts={layouts} handleLayoutChange={handleLayoutChange} settings={settings} data={data} currentRunId={currentRunId}/>
    </>
  );
}

export default DashboardLayout;