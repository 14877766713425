const formatTime = (date, timeFormat) => {
    const options = {
        hour: '2-digit',
        minute: '2-digit',
        hour12: timeFormat === '12-hour'
    };
    return date.toLocaleTimeString([], options);
};

export function formatDate(dateValue, dateFormat = 'DD-MMM', timeFormat = '12-hour') {
    if(dateValue && !dateValue.includes('Z')) {
        dateValue += 'Z';
    }
    
    const date = new Date(dateValue);
    const today = new Date();

    today.setHours(0, 0, 0, 0);
    const yesterday = new Date(today);
    yesterday.setDate(yesterday.getDate() - 1);

    const currentYear = today.getFullYear();

    if(isNaN(date)) return 'null';

    const formatDate = (date, format, locale='en-US') => {
        const options = {
            'MM/DD/YYYY': { month: '2-digit', day: '2-digit', year: 'numeric' },
            'DD/MM/YYYY': { day: '2-digit', month: '2-digit', year: 'numeric', locale: 'en-GB' },
            'DD-MMM-YY': { day: '2-digit', month: 'short', year: '2-digit' },
            'DD-MMM': { day: '2-digit', month: 'short' },
            'MMM-YY': { month: 'short', year: '2-digit' }
        };
        return date.toLocaleDateString(options[format].locale || locale , options[format]);
    };

    if (date.toDateString() === today.toDateString()) {
        return `Today, ${formatTime(date, timeFormat)}`;
    } else if (date.toDateString() === yesterday.toDateString()) {
        return `Yesterday, ${formatTime(date, timeFormat)}`;    
    } else if (date.getFullYear() === currentYear) {
        return formatDate(date, dateFormat) + ', ' + formatTime(date, timeFormat);
    } else {
        return formatDate(date, dateFormat) + ', ' + formatTime(date, timeFormat);
    }
}

export function convertDateToUtc(dateValue) {
    const date = new Date(dateValue);
    const utcDate = date.toISOString().replace('Z','');
    return utcDate;
}

export function convertDateToLocalTime(dateValue) {
    dateValue += 'Z';
    const date = new Date(dateValue);
    return date.toLocaleDateString([], {
        day: '2-digit',
        month: 'short',
        year: '2-digit'
    }) + ', ' + formatTime(date, '12-hour');
}