import React, {useState,useEffect} from "react";
import { useDispatch,useSelector } from "react-redux";
import { getSchedulerRuns, getRunData, compareRun, getComparisionDetail, RUN_COMPARISON_COMPLETED } from "../../redux/actions/SchedulerRunActions";
import {Flex, Select, Divider, Tree, Table, Spin, Button, message, Tooltip} from 'antd';
import { DownOutlined, LoadingOutlined } from "@ant-design/icons";
import './compare-run.scss';
import tableMappings from "./scheduler_table_settings.json";
import compareRunSettings from './compare_run_settings.json';
import GridLayout from "../gridlayout/GridLayout";
import { BarChart, Bar, XAxis, YAxis, Tooltip as RechartsTooltip, ResponsiveContainer, CartesianGrid } from "recharts";
import DateComponent from "../common/DateComponent";

function CompareRun() {
    const [runsList, setRunsList] = useState([]);
    const [selectedBaseRun, setSelectedBaseRun] = useState(null);
    const [selectedCompareRun, setSelectedCompareRun] = useState(null);
    const [runNameList, setRunNameList] = useState(null);
    const [selectedBaseRunData, setSelectedBaseRunData] = useState(null);
    const [selectedCompareRunData, setSelectedCompareRunData] = useState(null);
    const [baseRunFormattedData,setBaseRunFormattedData] = useState(null);
    const [compareRunFormattedData,setCompareRunFormattedData] = useState(null);
    const [baseRunLoader, setBaseRunLoader] = useState(false);
    const [compareRunLoader, setCompareRunLoader] = useState(false);
    const [runListLoader, setRunListLoader] = useState(true);
    const [baseRunExpandedItems, setBaseRunExpandedItems] = useState([]);
    const [compareRunExpandedItems, setCompareRunExpandedItems] = useState([]);
    const [layouts, setLayouts] = useState([]);
    const [settings, setSettings] = useState([]);
    const dispatch = useDispatch();
    const compareRunStatus = useSelector((state) => state.schedulerRun.compareStatus); 
    const [comparisionDetail, setComparisionDetail] = useState(null);
    const detailsObj = {
        'input_tables': {label: 'Input Tables', key: 'input'}, 
        'run_parameter_list': {label: 'Parameters', key: 'parameter'}, 
        'output_tables': {label: 'Output Tables', key: 'output'}
    }
    const comparisionCategoryClasses = {
        'empty': ['identical but empty'],
        'identical': ['identical'],
        'unidentical': ['different'],
        'missing': ['no data', 'missing in 1', 'missing in 2']
    }
    const [analyzeDiffLoader, setAnalyzeDiffLoader] = useState(false);

    async function fetchSchedulerRuns() {
        setRunListLoader(true);
        const response = await dispatch(getSchedulerRuns());
        if (response.status && response.data.length > 0) {
            setRunsList(response.data);
            const runLabels = response.data.map((run,index) => ({"key": index,"label": run.run_name, "value": run.run_id}));
            setRunNameList(runLabels);
            setRunListLoader(false);
        }
    }

    async function fetchComparisionData() {
        const response = await getComparisionDetail(selectedBaseRun,selectedCompareRun);
        if(response.status) {
            setComparisionDetail(response.data)
            setAnalyzeDiffLoader(false);
        }
    }

    useEffect(() => {
        fetchSchedulerRuns();
    },[dispatch])

    useEffect(() => {
        setLayouts(compareRunSettings);
        setSettings(compareRunSettings);
    },[])

    useEffect(() => {
        if(compareRunStatus?.includes('Run comparison completed')) {
            fetchComparisionData();
            dispatch({type: RUN_COMPARISON_COMPLETED});
        }
    },[compareRunStatus])

    async function getRunDetail(runId) {
        const response = await dispatch(getRunData(runId));
        return response;
    }

    function handleExpandItems(expandedItems) {
        setBaseRunExpandedItems(expandedItems);
        setCompareRunExpandedItems(expandedItems);
    }

    function getCellType(field,summary) {
        const currentSummaryObj = JSON.parse(summary.replace(/'/g, '"'))
        const currentStatus = currentSummaryObj[field]

        let cellClassName = "";
        cellClassName = Object.keys(comparisionCategoryClasses).find(key => comparisionCategoryClasses[key]?.includes(currentStatus));

        return {
            className: cellClassName || '',
        };
    }

    function formatValue(value) {
        if (value && typeof value === 'number') return Math.round(value);
        return value;
    }

    function renderCell(text,field,currentCompareRecord) {
        const cellValue = field.isDateField ? (<DateComponent dateValue={text}/>) : (<div>{text}</div>);
        if (!currentCompareRecord) return cellValue;

        const valueDifference = JSON.parse(currentCompareRecord?.difference_value_dict?.replace(/'/g, '"').replace(/None/g, 'null'))[field.name];
        const percentageDifference = formatValue(JSON.parse(currentCompareRecord?.percentage_difference_value_dict?.replace(/'/g, '"').replace(/None/g, 'null'))[field.name]);

        const title = (
            <Flex vertical>
                <div>Absolute Difference : {String(valueDifference)}</div>
                <div>Percentage Difference: {String(percentageDifference)}</div>
            </Flex>
        )

        return (
            <Tooltip title={title}>
                {cellValue}
            </Tooltip>
        )
    }

    function renderHistogram(data,xlabel,ylabel) {
        return (
            <ResponsiveContainer width="100%" height={200}>
                <BarChart data={data}>
                <CartesianGrid strokeDasharray="3 3" strokeOpacity={0.1} />
                    <XAxis dataKey={xlabel} tick={{ fontSize: 12, fill: 'white' }}/>
                    <YAxis dataKey={ylabel} tick={{ fontSize: 12, fill: 'white' }}/>
                    <RechartsTooltip />
                    <Bar dataKey={ylabel} fill="#8884d8" barSize={10} />
                </BarChart>
            </ResponsiveContainer>
        )
    }

    function renderColumnHeader(field,currentColumnStat) {
        const currentColumnStatObj = JSON.parse(currentColumnStat);
        const histogramData = Object.keys(currentColumnStatObj).map((stat) => ({'label': stat, 'value': currentColumnStatObj[stat]}));
        return (
            <Tooltip
                title={renderHistogram(histogramData,'label','value')}
                trigger="click"
                overlayStyle={{ width: 300 }}
            >
                <div>{field.label || field.name}</div>
            </Tooltip>
        )
    }

    function generateTableView(currentTable, tableType, currentRun) {
        return Object.keys(currentTable).map((key, index) => {
            const value = currentTable[key];
            if (value && Array.isArray(value)) {
                const currentTableMappings = tableMappings[tableType];
                const compareDetails = comparisionDetail?.run_comparison_detail.filter(detail => detail.table_type === detailsObj[tableType].key && detail.table_name === key);
                const columnStats = comparisionDetail?.run_table_stats.filter(detail => detail.table_type === detailsObj[tableType].key && detail.table_name === key && detail.run_id === currentRun);
                const columns = value.length > 0 ? currentTableMappings[key].fields
                .filter(field => field.visibility === "Show")
                .map(field => ({
                    title: () => {
                        const currentColumnStat = columnStats?.find(item => item.column_name === field.name)?.value_histogram;
                        if(!currentColumnStat) return (<div>{field.label || field.name}</div>);
                        return renderColumnHeader(field,currentColumnStat);
                    },
                    dataIndex: field.name,
                    key: field.name,
                    onCell: (record) => {
                        const summary = compareDetails?.find(item => item.id1_index_column_value === record[item.table_index_column])?.difference_summary_dict;
                        if (!summary) return {};
                        return getCellType(field.name,summary)
                    },
                    render: (text,record) => {
                        const currentCompareRecord = compareDetails?.find(item => item.id1_index_column_value === record[item.table_index_column])
                        return renderCell(text,field,currentCompareRecord)
                    }
                })) : [];

                const currentTableTitle = renderNodeTitle(tableType,1,currentTableMappings[key].label,key)

                return {
                    title: currentTableTitle,
                    key: key, 
                    level: 1,
                    children: [
                        {
                            title: (
                                <Table
                                    dataSource={value}
                                    columns={columns}
                                    pagination={false}
                                    size="small"
                                    rowKey="_id"
                                    scroll={{
                                        x: 2000,
                                        y: 450,
                                    }}
                                    virtual
                                    className="data-table"
                                />
                            ),
                            key: `${tableType}/${key}`,
                            level: 2,
                            className:"data-tree"
                        },
                    ],
                };
            }
            else if(value && value.constructor === Object) {
                const currentMappingItem = 'run_details';
                const nestedFieldMap = tableMappings[currentMappingItem].find(item => item.name === tableType)?.properties.find(nestedItem => nestedItem.name === key);
                const nestedChildren = Object.keys(value).map((nestedKey) => {
                    const nestedField = nestedFieldMap?.properties.find(item => item.name === nestedKey);
                    const nodeTitle = nestedField?.isDateField ? (<Flex gap={6}><span>{nestedField?.label}</span>: <DateComponent dateValue={value[nestedKey]}/></Flex>) : (<Flex gap={6}><span>{nestedField?.label}</span>:<span>{value[nestedKey]}</span></Flex>);

                    return {
                        title: nodeTitle,
                        key: `${tableType}/${key}/${nestedKey}`,
                    };
                });

                return {
                    title: nestedFieldMap?.label || key,
                    key: `${tableType}/${key}`,
                    children: nestedChildren,
                };

            }
            else {
                const currentMappingItem = 'run_details';
                const fieldMappingList = tableMappings[currentMappingItem].find(item => item.name === tableType)?.properties;
                const field = fieldMappingList.find(item => item.name === key);
                const nodeTitle = field?.isDateField ? (<Flex gap={6}><span>{field?.label}</span>: <DateComponent dateValue={value}/></Flex>) : (<Flex gap={6}><span>{field?.label}</span>:<span>{value}</span></Flex>);

                return {
                    title: nodeTitle,
                    key: `${tableType}/${key}`,
                }
            }

        });
    };

    function generateTableData(data,detailsObj,currentRun) {
        const tableData = Object.keys(detailsObj).map((tableType, index) => {
            const children = generateTableView(data[tableType], tableType, currentRun);
            const currentNodeTitle = renderNodeTitle(tableType,0,detailsObj[tableType].label)
            return {
              title: currentNodeTitle,
              level: 0,
              key: tableType,
              children,
            };
        });

        return tableData;        
    }

    function renderNodeTitle(tableType,level,currentTitle,tableName=null) {
        if(comparisionDetail) {
            if(level === 0) {
                const overallCompareDetail = comparisionDetail?.run_comparison_overall[0]?.overall_summary_string[detailsObj[tableType].key];
                return <Flex><div>{currentTitle}</div><i className='compare-detail overall-detail'>{overallCompareDetail}</i></Flex>
            }
            else if(level === 1) {
                const tableCompareDetail = comparisionDetail?.run_comparison_summary?.find(detail => detail.table_name !== 'run' && detail.table_name === tableName)?.comparison_summary;
                const categoryClassName = Object.keys(comparisionCategoryClasses).find(key => comparisionCategoryClasses[key].some(value => tableCompareDetail?.toLowerCase().includes(value.toLowerCase())));
                return <Flex><div>{currentTitle}</div><i className={`compare-detail ${categoryClassName}`}>{tableCompareDetail}</i></Flex>
            }
            else {
                return currentTitle;
            }
        }
        else return currentTitle;
    }

    useEffect(() => {
        if(selectedBaseRunData) setBaseRunFormattedData(generateTableData(selectedBaseRunData,detailsObj,selectedBaseRun))
        if(selectedCompareRunData) setCompareRunFormattedData(generateTableData(selectedCompareRunData,detailsObj,selectedCompareRun))
    },[selectedBaseRunData,selectedCompareRunData,comparisionDetail])

    async function handleSelectBaseRun(value) {
        setSelectedBaseRun(value);
        setComparisionDetail(null);
        setBaseRunLoader(true);
        const response = await getRunDetail(value);
        if(response.status) {
            setSelectedBaseRunData(response.data)
            setBaseRunLoader(false);
        }
    }

    async function handleSelectCompareRun(value) {
        setSelectedCompareRun(value);
        setComparisionDetail(null);
        setCompareRunLoader(true);
        const response = await getRunDetail(value);
        if(response.status) {
            setSelectedCompareRunData(response.data)
            setCompareRunLoader(false);
        }
    }

    function handleLayoutChange(newLayout) {
        setLayouts(newLayout);
        const newSettings = settings.map(item => {
            let layout = newLayout.find(x => x.i === item.i);
            if (layout) {
              Object.assign(item, layout);
            }
            return item;
        });
        setSettings(newSettings);
    }

    async function handleFindDiff() {
        setAnalyzeDiffLoader(true);
        setBaseRunExpandedItems([]);
        setCompareRunExpandedItems([]);
        setComparisionDetail(null);
        const response = await compareRun(selectedBaseRun,selectedCompareRun);
        if(response.status) {
            message.success("Run submitted and started");
        }
        else {
            message.error('Something went wrong');
        }
    }

    return (
        runsList.length > 0 ?
        (
            <Flex className="diff-view scrollable-container" align="center" vertical>
                <Button loading={analyzeDiffLoader} className="find-diff-btn" type="primary" onClick={handleFindDiff}>Analyze Differences</Button>
                <Flex className="compare-run-container">
                    <Flex className="run-container" vertical>
                        <Flex>
                            <Select
                                placeholder="Select a run"
                                value={selectedBaseRun}
                                onChange={handleSelectBaseRun}
                                options={runNameList}
                                className="run-select"
                            />
                            {selectedBaseRunData && (<div className="timeline-card detail-margin" size="small"><DateComponent dateValue={selectedBaseRunData.horizon_start_time}/> - <DateComponent dateValue={selectedBaseRunData.horizon_end_time}/></div>)}
                        </Flex>
                        {selectedBaseRunData ? (
                            <>
                                <GridLayout setLayouts={setLayouts} setSettings={setSettings} layouts={layouts} handleLayoutChange={handleLayoutChange} settings={settings} data={selectedBaseRunData} currentRunId={selectedBaseRun}/>
                                {baseRunFormattedData && (
                                    <Tree
                                        showLine
                                        switcherIcon={<DownOutlined />}
                                        treeData={baseRunFormattedData}
                                        onExpand={handleExpandItems}
                                        expandedKeys={baseRunExpandedItems}
                                        selectable={false}
                                        expandAction="click"
                                    />
                                )}
                            </>
                        ): (<Spin spinning={baseRunLoader} size='large' className="loader" indicator={<LoadingOutlined spin />}/>)}
                    </Flex>
                    <Divider type="vertical" className="run-divider"/>
                    <Flex className="run-container" vertical>
                        <Flex className="run-select-container">
                            <Select
                                placeholder="Select a run"
                                value={selectedCompareRun}
                                onChange={handleSelectCompareRun}
                                options={runNameList}
                                className="run-select detail-margin"
                            />
                            {selectedCompareRunData && (<div className="timeline-card" size="small"><DateComponent dateValue={selectedCompareRunData.horizon_start_time} /> - <DateComponent dateValue={selectedCompareRunData.horizon_end_time} /></div>)}
                        </Flex>
                        {selectedCompareRunData ? (
                            <>
                                <GridLayout setLayouts={setLayouts} setSettings={setSettings} layouts={layouts} handleLayoutChange={handleLayoutChange} settings={settings} data={selectedCompareRunData} currentRunId={selectedCompareRun}/>
                                {compareRunFormattedData && (
                                    <Tree
                                    showLine
                                    switcherIcon={<DownOutlined />}
                                    treeData={compareRunFormattedData}
                                    onExpand={handleExpandItems}
                                    expandedKeys={compareRunExpandedItems}
                                    selectable={false}
                                    />
                                )}
                            </>
                        ) : (<Spin spinning={compareRunLoader} size='large' className="loader" indicator={<LoadingOutlined spin />}/>)}
                    </Flex>
                </Flex>
            </Flex>
        ) : (<Spin spinning={runListLoader} size='large' className="loader" indicator={<LoadingOutlined spin />}/>)
    )
}

export default CompareRun;